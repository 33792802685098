import {
  createColumnDate,
  createColumnText,
  createImageColumn,
  createColumnButtonArr,
  widthOptions,
} from '@/components/table/function/createColumn';
import { Popconfirm } from 'antd';
import { ButtonTheme, ButtonToolTip } from '../../../../components';
import { createColumnTag } from '../../../../components/table/function/createColumn';
import { ORDER_STATUS } from '../../../../resources/status';

export const columns = (onEdit = () => {}, handleDelete = () => {}) => {
  return [
    createColumnTag('สถานะ', { key: 'orderStatus', text: 'orderStatusName' }, ORDER_STATUS, {
      width: '150px',
    }),
    createColumnText('ลำดับ', 'rowNumber', widthOptions(100)),
    createColumnText('ช่องทาง', 'orderFrom', widthOptions(150)),
    createColumnText('เลขที่คำสั่งซื้อ', 'invNo', widthOptions(170)),
    createColumnText('สินค้า', 'promotionName', widthOptions(150)),
    createColumnText('เบอร์ติดต่อ', 'telephone', widthOptions(150)),
    createColumnDate('วันที่ซื้อ', 'orderDate', widthOptions(150)),
    createImageColumn('สลิปโอนเงิน', 'invImageUrl', widthOptions(150)),
    createColumnButtonArr('จัดการ', 'rowNo', {
      others: {
        fixed: 'right',
        width: 180,
      },
      buttonArr: [
        {
          useFor: 'EDIT3',
          render: (text, record) => (
            <ButtonToolTip toolTip="แก้ไข" placement="topLeft">
              <ButtonTheme useFor="EDIT3" onClick={() => onEdit(record)} />
            </ButtonToolTip>
          ),
        },
        {
          useFor: 'DELETE2',
          render: (text, record) => (
            <ButtonToolTip toolTip="ลบ" placement="topLeft">
              <Popconfirm
                title="คุณต้องการที่จะลบคำสั่งซื้อใช่หรือไม่?"
                onConfirm={() => handleDelete(record)}
                okText="ใช่"
                cancelText="ไม่"
              >
                <ButtonTheme useFor="DELETE2" />
              </Popconfirm>
            </ButtonToolTip>
          ),
        },
      ],
    }),
  ];
};
