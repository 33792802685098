import { Row, Col, Layout } from 'antd';
import styled, { createGlobalStyle } from 'styled-components';
import { color } from '../resources';
const { Header, Sider } = Layout;

const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: 'Sarabun';
        src: url("../assets/fonts/Sarabun-Thin.ttf") format("truetype");
        font-weight: 100;
        font-style: normal;
        font-display: swap;
    }
    @font-face {
        font-family: 'Sarabun';
        src: url("../assets/fonts/Sarabun-ThinItalic.ttf") format("truetype");
        font-weight: 100;
        font-style: italic;
        font-display: swap;
    }
    @font-face {
        font-family: 'Sarabun';
        src: url("../assets/fonts/Sarabun-ExtraLight.ttf") format("truetype");
        font-weight: 200;
        font-style: normal;
        font-display: swap;
    }
    @font-face {
        font-family: 'Sarabun';
        src: url("../assets/fonts/Sarabun-ExtraLightItalic.ttf") format("truetype");
        font-weight: 200;
        font-style: italic;
        font-display: swap;
    }
    @font-face {
        font-family: 'Sarabun';
        src: url("../assets/fonts/Sarabun-Light.ttf") format("truetype");
        font-weight: 300;
        font-style: normal;
        font-display: swap;
    }
    @font-face {
        font-family: 'Sarabun';
        src: url("../assets/fonts/Sarabun-LightItalic.ttf") format("truetype");
        font-weight: 300;
        font-style: italic;
        font-display: swap;
    }
    @font-face {
        font-family: 'Sarabun';
        src: url("../assets/fonts/Sarabun-Regular.ttf") format("truetype");
        font-weight: 400;
        font-style: normal;
        font-display: swap;
    }
    @font-face {
        font-family: 'Sarabun';
        src: url("../assets/fonts/Sarabun-Italic.ttf") format("truetype");
        font-weight: 400;
        font-style: italic;
        font-display: swap;
    }
    @font-face {
        font-family: 'Sarabun';
        src: url("../assets/fonts/Sarabun-Medium.ttf") format("truetype");
        font-weight: 500;
        font-style: normal;
        font-display: swap;
    }
    @font-face {
        font-family: 'Sarabun';
        src: url("../assets/fonts/Sarabun-MediumItalic.ttf") format("truetype");
        font-weight: 500;
        font-style: italic;
        font-display: swap;
    }
    @font-face {
        font-family: 'Sarabun';
        src: url("../assets/fonts/Sarabun-SemiBold.ttf") format("truetype");
        font-weight: 600;
        font-style: normal;
        font-display: swap;
    }
    @font-face {
        font-family: 'Sarabun';
        src: url("../assets/fonts/Sarabun-SemiBoldItalic.ttf") format("truetype");
        font-weight: 600;
        font-style: italic;
        font-display: swap;
    }
    @font-face {
        font-family: 'Sarabun';
        src: url("../assets/fonts/Sarabun-Bold.ttf") format("truetype");
        font-weight: 700;
        font-style: normal;
        font-display: swap;
    }
    @font-face {
        font-family: 'Sarabun';
        src: url("../assets/fonts/Sarabun-BoldItalic.ttf") format("truetype");
        font-weight: 700;
        font-style: italic;
        font-display: swap;
    }
    @font-face {
        font-family: 'Sarabun';
        src: url("../assets/fonts/Sarabun-ExtraBold.ttf") format("truetype");
        font-weight: 800;
        font-style: normal;
        font-display: swap;
    }
    @font-face {
        font-family: 'Sarabun';
        src: url("../assets/fonts/Sarabun-ExtraBoldItalic.ttf") format("truetype");
        font-weight: 800;
        font-style: italic;
        font-display: swap;
    }
    html, body {
        height: 100%;
        width: 100%;
         padding: 0;
        margin: 0;
         background: #FAFAFA;
        font-family: SarabunLight, 'sans-serif' !important;
         color: #444;
         -webkit-font-smoothing: antialiased;
         -moz-osx-font-smoothing: grayscale;
    }
`;

export const RowComponent = styled(Row)`
  display: flex;
  align-items: center;
`;

export const Required = styled.div`
  color: ${color.red};
  position: relative;
  top: 3px;
  left: 3px;
`;

export const ContainerButton = styled(Col)`
  text-align-last: ${(props) =>
    props.left ? 'start' : props.center ? 'center' : props.right ? 'end' : ''};
  width: 100%;
`;

export const HeaderBarStyle = styled(Header)`
  padding: 0 !important;
  position: sticky;
  top: 0;
  z-index: 100;
  display: flex;
`;

export const SideBarStyle = styled(Sider)`
  overflow: auto;
  height: 100vh;
  position: fixed !important;
  left: 0;
  top: 0;
  bottom: 0;
`;

export const IconContainerDiv = styled.div`
  height: 100%;
  margin-top: 3px;
  margin-right: 1.5px;
`;

export const StatusColumnContainer = styled.div`
  color: ${(props) => props.color};
  display: flex;
  justify-content: ${(props) => props.content || `center`};
`;

export default GlobalStyle;
