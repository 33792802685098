import {
  createColumnText,
  createColumnButtonArr,
  widthOptions,
  createColumnNumber,
} from '@/components/table/function/createColumn';
import { ButtonTheme, ButtonToolTip } from '../../../../components';
import { Popconfirm } from 'antd';
import { ROUTES_PATH } from '../../../../resources/routes-name';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export const columns = ({ onSubmitDeleteProduct }) => {
  const history = useHistory();
  return [
    createColumnNumber('ลำดับที่', 'seqNo', widthOptions(150)),
    createColumnText('Code G', 'itemCode', widthOptions(120)),
    createColumnText('สี QR', 'categoryName', widthOptions(120)),
    createColumnText('แบรนด์', 'mobileBrandNameEn', widthOptions(150)),
    createColumnText('รุ่นมือถือ', 'mobileModelNameEn', widthOptions(150)),
    createColumnText('ประเภทสินค้า (Major Category)', 'productTypeCode', widthOptions(200)),
    createColumnText('ชื่อสินค้า (Product Name)', 'productName', widthOptions(160)),
    createColumnText('Salebot Description', 'salebotDescriptionTh', widthOptions(160)),
    createColumnNumber('วันรับประกัน', 'warrantyPeriod', widthOptions(150)),

    createColumnButtonArr('จัดการ', 'rowNo', {
      others: {
        fixed: 'right',
        width: 180,
      },
      buttonArr: [
        {
          useFor: 'EDIT3',
          render: (text, record) => (
            <ButtonToolTip toolTip="แก้ไข" placement="topLeft">
              <ButtonTheme
                useFor="EDIT3"
                onClick={() => {
                  history.push({
                    pathname: ROUTES_PATH.ROUTE_EDIT_MANAGEMENT.PATH,
                    state: { mapProductId: record.mapProductId },
                  });
                }}
              />
            </ButtonToolTip>
          ),
        },
        {
          useFor: 'DELETE2',
          render: (text, record) => (
            <ButtonToolTip toolTip="ลบ" placement="topLeft">
              <Popconfirm
                title="คุณต้องการที่จะลบสินค้าใช่หรือไม่?"
                onConfirm={() => onSubmitDeleteProduct(record)}
                okText="ใช่"
                cancelText="ไม่"
              >
                <ButtonTheme useFor="DELETE2" />
              </Popconfirm>
            </ButtonToolTip>
          ),
        },
      ],
    }),
  ];
};
