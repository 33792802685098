import { useState } from 'preact/hooks';
import { GET, GET_MODEL_BRAND } from '@/services';
import { useMutation } from '@tanstack/react-query';
import { openAlert } from '../../../../components/alert/useAlert';

export const useGetModel = ({ handleSubmit }) => {
  const [data, setData] = useState([]);

  const { isPending, mutate: onSearch } = useMutation({
    mutationFn: async (values) => {
      try {
        const response = await GET(GET_MODEL_BRAND(values.search));
        return response.result?.items || [];
      } catch (error) {
        throw { ...error, disabledThrow: true };
      }
    },
    onSuccess: (response) => {
      setData(response);
    },
    onError: () => {
      openAlert({
        model: 'message',
        type: 'error',
        message: 'กรุณาเลือกข้อมูลให้ถูกต้อง',
      });
    },
  });

  const onFetch = () =>
    handleSubmit((values) => {
      return onSearch(values);
    })();

  return { dataSource: data, setData, onSearch, loading: isPending, onFetch };
};
