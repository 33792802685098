export const FORM_SEARCH = ({ mobileBrandList }) => {
  return [
    {
      name: 'search',
      label: 'แบรนด์มือถือ',
      type: 'DROPDOWN',
      properties: {
        options: mobileBrandList || [],
      },
      rules: {
        required: true,
      },
      span: 8,
    },
  ];
};

export const FORM_INSERT_MODEL = ({ mobileBrandList }) => {
  const span = 24;
  return [
    {
      name: 'mobileBrandId',
      label: 'แบรนด์มือถือ',
      type: 'DROPDOWN',
      properties: {
        options: mobileBrandList || [],
      },
      rules: {
        required: true,
      },
      placeholder: 'กรุณาเลือกแบรนด์มือถือ',
      span,
    },
    {
      name: 'mobileModelCode',
      label: 'รหัสรุ่นมือถือ',
      type: 'INPUT',
      placeholder: 'กรุณากรอกรหัสรุ่นมือถือ',
      rules: {
        required: true,
      },
      span,
    },
    {
      name: 'mobileModelNameEn',
      label: 'ชื่อรุ่นมือถือ',
      type: 'INPUT',
      placeholder: 'กรุณากรอกชื่อรุ่นมือถือ',
      rules: {
        required: true,
      },
      span,
    },
    {
      name: 'mobileModelImage',
      label: 'รูปภาพ',
      type: 'UPLOAD',
      listType: 'picture-card',
      span,
      maxCount: 1,
    },
  ];
};

export const FORM_EDIT_MODEL = ({ mobileBrandList, statusOptions }) => {
  const span = 24;
  return [
    {
      name: 'mobileBrandId',
      label: 'แบรนด์',
      type: 'DROPDOWN',
      properties: {
        options: mobileBrandList || [],
      },
      disabled: true,
      span,
    },
    {
      name: 'mobileModelCode',
      label: 'รหัสรุ่นมือถือ',
      type: 'INPUT',
      disabled: true,
      span,
    },
    {
      name: 'mobileModelNameEn',
      label: 'ชื่อรุ่นมือถือ',
      type: 'INPUT',
      disabled: true,
      span,
    },
    {
      name: 'mobileModelStatus',
      label: 'สถานะ',
      type: 'DROPDOWN',
      properties: {
        options: statusOptions || [],
      },
      span,
    },
    {
      name: 'remark',
      label: 'หมายเหตุ (ระบุได้ไม่เกิน 250 ตัวอักษร)',
      type: 'INPUT_AREA',
      placeholder: 'กรุณากรอกหมายเหตุ',
      rules: {
        maxLength: 250,
      },
      span,
    },
  ];
};

export const useFormState = (initialValues = {}) => {
  const defaultValues = {
    mobileBrandId: initialValues.mobileBrandId || '',
    mobileModelCode: initialValues.mobileModelCode || '',
    mobileModelNameTh: initialValues.mobileModelNameTh || '',
    mobileModelNameEn: initialValues.mobileModelNameEn || '',
    mobileModelImageUrl: initialValues.mobileModelImageUrl
      ? [
          {
            uid: '-1',
            name: 'image.png',
            status: 'done',
            url: initialValues.mobileModelImageUrl,
          },
        ]
      : [],
  };

  return {
    defaultValues,
  };
};

export const searchDefaultValues = {
  search: '',
};
