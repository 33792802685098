export const InsertManagement = (value) => {
  return {
    categoryId: value.categoryId,
    mobileBrandId: value.mobileBrandId,
    mobileModelId: value.mobileModelId,
    productId: value.productId,
    itemCode: value.itemCode,
    warrantyPeriod: parseInt(value.warrantyPeriod, 10) || 0,
  };
};

export const InsertManagementData = (value) => {
  return {
    mapProductId: value.mapProductId,
    channelBuyId: value.channelBuyId,
    specialWarrantyPeriod: parseInt(value.specialWarrantyPeriod, 10) || 0,
  };
};

export const UpdateManagementData = (value) => {
  return {
    mapProductSpecialWarrantyId: value.mapProductSpecialWarrantyId,
    mapProductId: value.mapProductId,
    channelBuyId: value.channelBuyId,
    specialWarrantyPeriod: parseInt(value.specialWarrantyPeriod, 10) || 0,
  };
};

export const UpdateManagement = (value) => {
  return {
    productImage: value.productImage ? value.productImageUrl.originFileObj : null,
    mapProductId: value.mapProductId,
    categoryId: value.categoryId,
    mobileBrandId: value.mobileBrandId,
    mobileModelId: value.mobileModelId,
    itemCode: value.itemCode,
    productGroupCode: value.productGroupCode,
    productTypeCode: value.productTypeCode,
    productName: value.productName,
    salebotDescription: value.salebotDescription,
    salebotDescriptionTh: value.salebotDescriptionTh,
    salebotColorTh: value.salebotColorTh,
    warrantyPeriod: parseInt(value.warrantyPeriod, 10) || 0,
    specialWarrantyStatus: value.specialWarrantyStatus,
    isSpecialWarrantyStatus: value.specialWarrantyStatus === 'ACTIVE' ? true : false,
    mapProductStatus: value.mapProductStatus,
    salebotColor: value.salebotColor,
    seqNo: parseInt(value.seqNo, 10) || 0,
  };
};
