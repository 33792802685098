import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { Col, Row, Segmented } from "antd"
/**
 * https://recharts.org/ --> graph
 */
export const SummaryToDoGraph = ({ config }) => {
  return (
    config &&
    <Row gutter={[8, 8]} style={{ marginTop: 20 }}>
      <Col xs={{ span: 23, offset: 1, }} sm={{ span: 23, offset: 1, }} lg={{ span: 23, offset: 1, }}>
        <Segmented block options={['จำนวนงานที่ทำเสร็จแบ่งตามเดือน', 'จำนวนงานทุกสถานะแบ่งตามเดือน']} />
        <div style={{ marginTop: 10, width: '100%', height: 300 }}>
        <ResponsiveContainer>
        <AreaChart {...config}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Area type="monotone" dataKey="uv" stroke="#8884d8" fill="#8884d8" />
        </AreaChart>
      </ResponsiveContainer>
        </div>
      </Col>
    </Row>
  )
}