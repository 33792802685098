import { SideBarStyle, VersionTag } from '../../style';
import { ROUTES_MENU } from '../../../../resources/routes-name';
import { useSelectMenu } from '../../hook/useSelectMenu';
import { Divider, Menu, Tag } from 'antd';
import { ScrollMenu } from './style';
import { color, VERSION } from '../../../../resources';
import { useSelector } from 'react-redux';
import { useCallback } from 'preact/hooks';

const SiderLayout = ({ collapsed, getTheme, sidebarMode }) => {
  const { defaultSelectedKeys, defaultOpenKeys, onHandleClick } = useSelectMenu();
  const groupMenuList = useSelector((state) => state.authen.menu) || [];

  const recursiveFilterMenu = useCallback(
    (items) => {
      return items
        .filter((e) => {
          return e.children || groupMenuList.includes(e.code);
        })
        .map((e) => {
          if (!e.children) return e;
          return { ...e, children: recursiveFilterMenu(e.children) };
        })
        .filter((e) => {
          return e.onClick || e.component || e.children.length > 0;
        });
    },
    [groupMenuList],
  );

  const getMenuItem = useCallback(() => {
    if (!groupMenuList) return [];
    return recursiveFilterMenu(ROUTES_MENU);
  }, [groupMenuList, ROUTES_MENU]);

  return (
    <SideBarStyle
      trigger={null}
      width={280}
      collapsible
      collapsed={collapsed}
      style={{
        ...getTheme.sidebar[sidebarMode],
        background: color.blackTheme,
      }}
    >
      <div className="logo">
        <img src={`../../../assets/images/logo2.jpg`} alt="Avatar" class="avatar" />
      </div>
      <VersionTag collapse={collapsed}>
        <Tag style={{ margin: 0, color: '#000' }} color="white">
          version: {VERSION}
        </Tag>
      </VersionTag>
      <Divider />
      {defaultOpenKeys && defaultSelectedKeys && (
        <ScrollMenu>
          <Menu
            mode="inline"
            defaultOpenKeys={defaultOpenKeys}
            defaultSelectedKeys={defaultSelectedKeys}
            style={{
              ...getTheme.sidebar[sidebarMode],
              background: color.blackTheme,
              color: color.white,
            }}
            items={getMenuItem()}
            onClick={onHandleClick}
          />
        </ScrollMenu>
      )}
    </SideBarStyle>
  );
};

export default SiderLayout;
