import React, { useEffect } from 'react';
import { ButtonTheme, ModalTheme, RenderForm } from '../../../../components';
import { useForm } from 'react-hook-form';
import { ContainerButton } from '../../../../styles/global-style';
import { FORM_EDIT_BRAND, useFormState } from '../forms';
import { useEditBrand } from '../hooks/useEditMobile';
import { UpdateBrand } from '../../main/AllApi';
import { allOptions } from '../../../../functions/optionAll';

export const EditBrand = ({ open, onToggle, onFetch, initialValues }) => {
  const { defaultValues } = useFormState(initialValues);
  const { control, setValue, getValues, handleSubmit } = useForm({ defaultValues });
  const { statusOptions } = allOptions();
  const { mutate: onEditBrand } = useEditBrand({
    onSuccessCallBack: onFetch,
  });

  useEffect(() => {
    if (!initialValues) {
      return;
    }
    Object.keys(initialValues).forEach((key) => {
      const temp =
        key === 'mobileBrandImageUrl' && initialValues[key]
          ? [
              {
                uid: '-1',
                name: 'image.png',
                status: 'done',
                url: initialValues[key],
              },
            ]
          : initialValues[key];

      setValue(key, temp);
    });
  }, [initialValues, setValue]);

  const onSubmit = (values) => {
    const formData = UpdateBrand(values);

    onEditBrand(formData);
    onToggle({ defaultValues: '' });
  };

  return (
    <ModalTheme title="แก้ไขแบรนด์มือถือ" open={open} onToggle={onToggle}>
      <RenderForm
        control={control}
        setValue={setValue}
        getValues={getValues}
        forms={FORM_EDIT_BRAND({ statusOptions })}
      />
      <ContainerButton right>
        <ButtonTheme useFor="SUBMIT" onClick={handleSubmit(onSubmit)} />
        <ButtonTheme useFor="CANCEL" onClick={onToggle} />
      </ContainerButton>
    </ModalTheme>
  );
};
