import { useMutation } from '@tanstack/react-query';
import { POST, POST_CLAIMS_INSERT } from '../../../../services';
import { openAlert } from '../../../../components/alert/useAlert';

export const onUploadFile = ({ onSuccessCallBack }) =>
  useMutation({
    mutationFn: async ({
      claimImage,
      warrantyId,
      customerCode,
      detail1,
      detail2,
      breakdownId,
      addressLine1,
      addressLine2,
      subDistrictId,
      zipCode,
      other,
      isCreateNewAddress,
    }) => {
      const formData = new FormData();
      if (claimImage && claimImage.originFileObj) {
        formData.append('claimImage', claimImage.originFileObj);
      }
      formData.append('warrantyId', warrantyId);
      formData.append('customerCode', customerCode);
      formData.append('detail1', detail1);
      formData.append('detail2', detail2);
      formData.append('breakdownId', breakdownId);
      formData.append('addressLine1', addressLine1);
      formData.append('addressLine2', addressLine2);
      formData.append('subDistrictId', subDistrictId);
      formData.append('zipCode', zipCode);
      formData.append('other', other);
      formData.append('isCreateNewAddress', isCreateNewAddress);
      const response = await POST(POST_CLAIMS_INSERT(), formData);
      if (onSuccessCallBack) onSuccessCallBack(response);
      return response;
    },
    onSuccess: (result) => {
      openAlert({ model: 'message', type: 'success', message: result.message });
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    },
  });
