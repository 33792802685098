import '../styles/header.css';
import imgLogin from '../../../../assets/images/imgLogin.jpg';
import { FORM_CHANGE_PASSWORD } from '../forms/forms';
import { RenderForm } from '../../../../components/forms';
import { ContainerButton } from '../../../../styles/global-style';
import { ButtonTheme } from '../../../../components/buttons';
import { Icon, color } from '../../../../resources';
import { TextLarge, TextLogoLogin } from '../../../../components/text';

const Header = () => {
  return (
    <div className="full-width-center">
      <div className="flex-center">
        <TextLogoLogin text="Change Password" bold />
        <div style={{ placeSelf: 'center' }}>
          <Icon.wavingHand style={{ fontSize: '24px', color: color.yellow }} />
        </div>
      </div>
      <TextLarge text="ABM - Warranty system" />
    </div>
  );
};

export const ChangePasswordForm = ({ control, setValue, getValues, onSubmit, handleSubmit }) => {
  return (
    <div style={{ flex: 1, padding: '2%' }}>
      <Header />

      <RenderForm
        control={control}
        forms={FORM_CHANGE_PASSWORD(getValues)}
        setValue={setValue}
        getValues={getValues}
      />

      <ContainerButton center>
        <ButtonTheme useFor="SUBMIT" onClick={handleSubmit(onSubmit)} />
      </ContainerButton>
    </div>
  );
};

export const ImageLogin = () => {
  return (
    <div className="img-login-box">
      <img className="img-login" alt="example" src={imgLogin} />
    </div>
  );
};

export const LoginCard = (props) => {
  return (
    <div style={{ width: '100%' }}>
      <div className="flex-login-box">
        <div style={{ margin: 'auto' }}>
          <div className="login-box">{props.children}</div>
        </div>
      </div>
    </div>
  );
};
