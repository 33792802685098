import React from 'react';
import { columns, exportHeaders } from './columns/index';
import { StyledContainer } from './styles';
import { FORM_SEARCH, searchDefaultValues } from './forms';
import { TextLarge, CardTheme, ButtonTheme } from '../../../components/index';
import { color, Icon } from '../../../resources';
import ButtonExcelEmbedded from '../../../components/report-excel/ButtonExcelEmbedded';
import { ContainerButton } from '../../../styles/global-style';
import { useEffect, useState } from 'preact/hooks';
import { ROUTES_PATH } from '../../../resources/routes-name';
import { navigateTo } from '../../../utils/navigation';
import { useClaimList } from './hooks/useClaimList';
import { useForm } from 'react-hook-form';
import { ResponsivePageLayout } from '../../../components/page-layout/ResponsivePageLayout';
import { useModal } from '../../../components/modal/hooks/useModal';
import { ModalMessage } from './modal/onModalMessage';
import { ProcessStatus } from './modal/ProcessStatus';
import { Modal, Spin } from 'antd';
import { Gap } from '../../../components/space';
import { useApproveClaim } from './hooks/useApproveClaim';
import { Remark } from './modal/Remark';
import { useAntDModalContext } from '@/components/modal/context/useAntdModalContext';
import { useGetPDF } from './pdfPage/hooks/useGetPDF';
import { CustomImage } from '@/functions/Image';
import { SpaceBetweenShowInfo } from '../../manage-data/user-info/styles';
import { RowSearchLayout } from '../../../components/page-layout/views/RowSearchLayout';
import { InfoClaimProcessStatus } from './views/InfoClaimProcessStatus';
import { InfoClaimStatus } from './views/InfoClaimStatus';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useGetClaimFull } from './hooks/useGetClaimFull';
import { ChangeTrackNumber } from './modal/ChangeTrackNumber';

export const ClaimProduct = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [modalData, setModalData] = useState({});
  const [searchType, setSearchType] = useState(null);
  const { open: openMessage, onToggle: onToggleMessage } = useModal();
  const { open: openProcessStatus, onToggle: onToggleProcessStatus } = useModal();
  const { open: openRemark, onToggle: onToggleRemark } = useModal();
  const { open: openChangeTrackNumber, onToggle: onToggleChangeTrackNumber } = useModal();
  const location = useLocation();
  const [isInitialFetch, setIsInitialFetch] = useState(true);
  const formSearchObj = useForm({ defaultValues: searchDefaultValues || location.state });
  const searchParamState = formSearchObj.watch();
  const { handleSubmit } = formSearchObj;
  const [modal, modalContext] = Modal.useModal();
  const { openConfirmModal } = useAntDModalContext();

  const {
    onFetchFull,
    dataSource: dataFull,
    loading: isPendingFull,
  } = useGetClaimFull({
    handleSubmit: formSearchObj.handleSubmit,
  });

  const {
    data: dataSource,
    setData: setDataSource,
    loading: isLoading,
    setData: setEmployee,
    onFetch,
  } = useClaimList({ setSelectedRowKeys, searchParamState });

  const { mutate: onSubmitApproveClaim, isPending: isPendingApproveClaim } = useApproveClaim({
    modal,
    selectedRowKeys,
    setEmployee,
    onSuccessCallback: () => {
      searchType === 'search' ? onFetchFull() : onFetch();
    },
  });

  const { mutate: fetchClaimPDF, isPending: isPendingFetchPDF } = useGetPDF();

  const handleSubmitAccident = () => {
    handleSubmit(() => onSubmitApproveClaim())();
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const onModalMessage = (record) => {
    setModalData(record);
    onToggleMessage({ record });
  };

  const onModalProcessStatus = (record) => {
    setModalData(record);
    onToggleProcessStatus({ defaultValues: record });
  };

  const onModalRemark = (record) => {
    setModalData(record);
    onToggleRemark({ record });
  };

  const onModalChangeTrackNumber = (record) => {
    setModalData(record);
    onToggleChangeTrackNumber({ record });
  };

  const gotoPdfClaim = () => {
    if (selectedRowKeys.length > 0) {
      fetchClaimPDF(selectedRowKeys, {
        onSuccess: (pdfData) => {
          navigateTo({
            pathname: ROUTES_PATH.ROUTE_PDF_CLAIM.PATH,
            state: { pdfData: pdfData.result.items },
          });
        },
      });
    }
  };

  const gotoClaimCreate = () => {
    navigateTo({ pathname: ROUTES_PATH.ROUTE_CREATE_CLAIM.PATH });
  };

  useEffect(() => {
    if (location.state && isInitialFetch) {
      formSearchObj.handleSubmit(onFetch)();
      setIsInitialFetch(false);
    } else {
      onFetch(searchDefaultValues);
    }
  }, [location.state, isInitialFetch]);

  const onSearchByField = (values) => {
    if (values.search) {
      setSearchType('search');
      onFetchFull(values);
    } else if (values.startDate) {
      setSearchType('date');
      onFetch(values);
    }
  };

  const transformDataForExcel = (data) => {
    return data?.map((item) => ({
      claimCode: item.claimCode,
      fullName: item.fullName,
      lineName: item.lineName,
      telephone: item.telephone,
      claimDate: item.claimDate,
      mobileBrandName: item.mobileBrandName,
      mobileModelName: item.mobileModelName,
      itemCode: item.itemCode,
      productName: item.productName,
      breakdownName: item.breakdownName,
      remainingDays: item.remainingDays,
      claimStatusName: item.claimStatusName,
      processStatusName: item.processStatusName,
      trackingNumber: item.trackingNumber,
      shippingDate: item.shippingDate,
      addressFullText: item.addressFullText,
    }));
  };

  const formSearch = FORM_SEARCH({ onEnter: formSearchObj.handleSubmit(onSearchByField) });

  return (
    <StyledContainer>
      <TextLarge text="การเคลมสินค้า" />
      <CardTheme>
        <ContainerButton right>
          <ButtonTheme useFor="WARRANTY" title="ยื่นเรื่องเคลม" onClick={() => gotoClaimCreate()} />
          {isPendingApproveClaim ? (
            <Spin spinning={isPendingApproveClaim}></Spin>
          ) : (
            <ButtonTheme
              useFor="REGISTER"
              title="อนุมัติเรื่องเคลม"
              customBgColor={color.line}
              onClick={() => openConfirmModal({ onOk: handleSubmitAccident })}
              disabled={selectedRowKeys.length === 0}
              style={{ marginBottom: '30px' }}
            />
          )}
          {isPendingFetchPDF ? (
            <Spin spinning={isPendingFetchPDF}></Spin>
          ) : (
            <ButtonTheme
              useFor="REGISTER"
              title="ใบส่งสินค้า"
              customBgColor={color.search2}
              onClick={() => gotoPdfClaim()}
              disabled={selectedRowKeys.length === 0}
            />
          )}
        </ContainerButton>
        <RowSearchLayout
          props={{
            title: 'ค้นหาการเคลมสินค้า',
            formSearch,
            useFormParam: formSearchObj,
            icon: <Icon.todo />,
            onSearch: formSearchObj.handleSubmit(onSearchByField),
            onClearCustom: () => {
              formSearchObj.reset(searchDefaultValues);
              setDataSource([]);
              setSelectedRowKeys([]);
              setEmployee([]);
            },
          }}
        >
          <div style={{ display: 'flex', width: '100%' }}>
            <div style={{ flex: 1, paddingRight: '8px' }}>
              <InfoClaimStatus />
            </div>
            <div style={{ flex: 1, paddingLeft: '8px' }}>
              <InfoClaimProcessStatus />
            </div>
          </div>
        </RowSearchLayout>
        <ResponsivePageLayout
          tableLayout={{
            columns: columns({
              onModalMessage,
              onModalProcessStatus,
              onModalRemark,
              onModalChangeTrackNumber,
            }),
            dataSource: searchType === 'search' ? dataFull : dataSource,
            hasPagination: true,
            loading: isLoading || isPendingFull,
            rowKey: 'claimId',
            rowSelection: {
              selectedRowKeys,
              onChange: onSelectChange,
            },
            expandable: {
              expandedRowRender: (record) => (
                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <CustomImage
                    width={80}
                    preview
                    src={record.claimImageUrl}
                    style={{ borderRadius: 8 }}
                  />
                  <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '20px' }}>
                    <div style={{ marginBottom: '8px' }}>
                      <strong>ชื่อสินค้า: </strong>
                      {record.productName}
                    </div>
                    <div style={{ marginBottom: '8px' }}>
                      <strong>รุ่นมือถือ: </strong>
                      {record.mobileModelName}
                    </div>
                    <div>
                      <strong>อาการเสีย: </strong>
                      {record.breakdownName}
                    </div>
                  </div>
                </div>
              ),
              rowExpandable: (record) => record.name !== 'Not Expandable',
            },
            extraContent: (
              <SpaceBetweenShowInfo>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {selectedRowKeys.length > 0
                    ? `เลือก ${selectedRowKeys.length} รายการ`
                    : 'ยังไม่ได้เลือกรายการ'}
                </div>
                <ButtonExcelEmbedded
                  title="Export"
                  header={exportHeaders}
                  data={transformDataForExcel(searchType === 'search' ? dataFull : dataSource)}
                  filename="รายงานการเคลมสินค้า"
                />
              </SpaceBetweenShowInfo>
            ),
          }}
        />
      </CardTheme>
      {openMessage && (
        <ModalMessage open={openMessage} onToggle={onToggleMessage} initialValues={modalData} />
      )}
      {openProcessStatus && (
        <ProcessStatus
          open={openProcessStatus}
          onToggle={onToggleProcessStatus}
          initialValues={modalData}
          onFetch={onFetch}
        />
      )}
      {openRemark && (
        <Remark open={openRemark} onToggle={onToggleRemark} initialValues={modalData} />
      )}
      {openChangeTrackNumber && (
        <ChangeTrackNumber
          open={openChangeTrackNumber}
          onToggle={onToggleChangeTrackNumber}
          initialValues={modalData}
          onFetch={onFetch}
        />
      )}
      {modalContext}
      <Gap />
    </StyledContainer>
  );
};
