export const FORM_SEARCH = () => {
  return [
    {
      name: 'search',
      type: 'INPUT',
      label: 'ค้นหารายการ (กรอกข้อมูลแล้วกด Enter เพื่อค้นหา)',
      placeholder: '',
      span: 13,
      helper:
        'Tips : สามารถค้นหาด้วย รหัสสี QR เช่น AA , รหัสแบรนด์,  รหัสรุ่นมือถือ ,Code G ,กลุ่มสินค้า (ItemG3) ,ประเภทสินค้า (Major Category)',
    },
  ];
};

export const FORM_INSERT_PRODUCT = ({ productTypeList }) => {
  const span = 24;
  return [
    {
      name: 'productCode',
      label: 'รหัสสินค้า',
      type: 'INPUT',
      placeholder: 'กรุณากรอกรหัสสินค้า',
      rules: {
        required: true,
      },
      span,
    },
    {
      name: 'productType',
      label: 'ประเภทสินค้า',
      type: 'DROPDOWN',
      properties: {
        options: productTypeList || [],
      },
      placeholder: 'กรุณากรอกประเภทสินค้า',
      rules: {
        required: true,
      },
      span,
    },
    {
      name: 'productNameTh',
      label: 'กลุ่มสินค้า',
      type: 'INPUT',
      placeholder: 'กรุณากรอกกลุ่มสินค้า',
      rules: {
        required: true,
      },
      span,
    },
    {
      name: 'productNameEn',
      label: 'ชื่อสินค้า',
      type: 'INPUT',
      placeholder: 'กรุณากรอกชื่อสินค้า',
      rules: {
        required: true,
      },
      span,
    },
    {
      name: 'productImage',
      label: 'รูปภาพ',
      type: 'UPLOAD',
      listType: 'picture-card',
      maxCount: 1,
      rules: {
        required: true,
      },
      span,
    },
  ];
};

export const FORM_EDIT_PRODUCT = ({ productTypeList, productGroupCodeList }) => {
  const span = 24;
  return [
    {
      name: 'categoryName',
      label: 'สี QR',
      type: 'INPUT',
      span,
    },
    {
      name: 'productId',
      label: 'รหัสสินค้า',
      type: 'HIDDEN',
    },

    {
      name: 'mobileBrandNameEn',
      label: 'แบรนด์',
      type: 'INPUT',
      span,
    },
    {
      name: 'mobileModelNameEn',
      label: 'รุ่นมือถือ',
      type: 'INPUT',
      span,
    },
    {
      name: 'productTypeCode',
      label: 'ประเภทสินค้า',
      type: 'DROPDOWN',
      properties: {
        options: productTypeList || [],
      },
      span,
    },
    {
      name: 'productGroupCode',
      label: 'สี QR',
      type: 'DROPDOWN',
      properties: {
        options: productGroupCodeList || [],
      },
      span,
    },
    {
      name: 'productName',
      label: 'ชื่อสินค้า',
      type: 'INPUT',
      span,
    },
    {
      name: 'salebotDescriptionTh',
      label: 'Salebot Description',
      type: 'INPUT',
      span,
    },
    {
      name: 'itemCode',
      label: 'Code G',
      type: 'INPUT',
      span,
    },
    {
      name: 'warrantyPeriod',
      label: 'วันรับประกัน',
      type: 'INPUT',
      span,
    },
    {
      name: 'productImageUrl',
      label: 'รูปภาพ',
      type: 'UPLOAD',
      listType: 'picture-card',
      span,
    },
  ];
};

export const useFormState = (initialValues = {}) => {
  const defaultValues = {
    mobileBrandId: initialValues.mobileBrandId || '',
    mobileModelCode: initialValues.mobileModelCode || '',
    mobileModelNameTh: initialValues.mobileModelNameTh || '',
    mobileModelNameEn: initialValues.mobileModelNameEn || '',
    productImageUrl: initialValues.productImageUrl
      ? [
          {
            uid: '-1',
            name: 'image.png',
            status: 'done',
            url: initialValues.productImageUrl,
          },
        ]
      : [],
  };

  return {
    defaultValues,
  };
};

export const searchDefaultValues = {
  search: '',
};
