import React, { useEffect } from 'react';
import { ButtonTheme, ModalTheme, RenderForm } from '../../../../components';
import { useForm } from 'react-hook-form';
import { ContainerButton } from '../../../../styles/global-style';
import { FORM_EDIT_CATEGORY } from '../forms';
import { useEditCategoryList } from '../hooks/useEditCategoryList';

export const EditCategory = ({ open, onToggle, onFetch, initialValues }) => {
  const { control, setValue, getValues, handleSubmit } = useForm({ defaultValues: '' });
  const { mutate: onEditInsertCategory } = useEditCategoryList({
    onSuccessCallBack: onFetch,
  });

  useEffect(() => {
    if (initialValues) {
      Object.entries(initialValues).forEach(([key, value]) => {
        setValue(key, value);
      });
    }
  }, [initialValues, setValue]);

  const onSubmit = (value) => {
    onEditInsertCategory(value);

    onToggle({ defaultValues: '' });
  };

  return (
    <ModalTheme title="แก้ไขสี QR" open={open} onToggle={onToggle}>
      <RenderForm
        control={control}
        setValue={setValue}
        getValues={getValues}
        forms={FORM_EDIT_CATEGORY()}
      />
      <ContainerButton right>
        <ButtonTheme useFor="SUBMIT" onClick={handleSubmit(onSubmit)} />
        <ButtonTheme useFor="CANCEL" onClick={onToggle} />
      </ContainerButton>
    </ModalTheme>
  );
};
