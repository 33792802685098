import React from 'react';
import { CardTheme, TextLarge, RenderForm, ButtonTheme } from '../../../components';
import { StyledContainer } from './styles';
import { useForm } from 'react-hook-form';
import { Row, Col, Spin } from 'antd';
import { FORM_CLAIM_DATA, FORM_CLAIM_CREATE } from './forms';
import { useCallback, useState } from 'preact/hooks';
import { ContainerButton } from '../../../styles/global-style';
import { onUploadFile } from './hooks/onUploadFile';
import { handleSubmitForm } from './func/submitHandler';
import { useOptions } from './hooks/useOptions';
import { useTeleQuery } from './hooks/useTeleQuery';
import { useZipcode } from './hooks/useZipcodeQuery';

export const ClaimCreate = () => {
  const { control, setValue, getValues, handleSubmit, watch, reset } = useForm({
    defaultValues: { warranties: [] },
  });
  const [, setIsSubmitting] = useState(false);
  const { isPending: isPendingUpload, mutate: mutateUpload } = onUploadFile({
    onSuccessCallBack: (response) => {
      setIsSubmitting(false);
    },
  });

  const { mutate: onCallTele } = useTeleQuery({ reset, getValues });
  const { mutate: onCallZipCode } = useZipcode({ reset, getValues });

  const { fullName } = watch();

  const {
    productOptions,
    addressOptions,
    breakdownOptions,
    provinceOptions,
    districtOptions,
    subDistrictOptions,
    addressDataOptions,
  } = useOptions({
    watch,
  });

  const onSubmit = (values) => {
    const valuesWithCustomerCode = {
      ...values,
      provinceId:
        typeof values.provinceId === 'object' ? values.provinceId.value : values.provinceId,
      districtId:
        typeof values.districtId === 'object' ? values.districtId.value : values.districtId,
      subDistrictId:
        typeof values.subDistrictId === 'object'
          ? values.subDistrictId.value
          : values.subDistrictId,
    };
    handleSubmitForm(valuesWithCustomerCode, setIsSubmitting, mutateUpload);
  };

  const handleOnEnter = useCallback(
    async (value, item) => {
      switch (item?.name) {
        case 'telephone':
          reset({ telephone: getValues('telephone') });
          onCallTele(value);
          break;
        case 'zipCode':
          onCallZipCode(value);
          break;
        default:
          break;
      }
    },
    [getValues],
  );

  const onChange = (value, item) => {
    const addressNew = addressDataOptions?.find((address) => address.customerAddressId === value);

    if (addressNew) {
      setValue('zipCode', addressNew.zipcode);
      setValue('provinceId', { value: addressNew.provinceId, label: addressNew.provinceName });
      setValue('districtId', { value: addressNew.districtId, label: addressNew.districtName });
      setValue('subDistrictId', {
        value: addressNew.subDistrictId,
        label: addressNew.subDistrictName,
      });

      setValue('provinceLabel', addressNew.provinceName);
      setValue('districtLabel', addressNew.districtName);
      setValue('subDistrictLabel', addressNew.subDistrictName);
    }

    switch (item.name) {
      case 'zipCode':
        setValue('provinceId', '');
        setValue('districtId', '');
        setValue('subDistrictId', '');
        break;
      case 'provinceId':
        setValue('districtId', '');
        setValue('subDistrictId', '');
        break;
      case 'districtId':
        setValue('subDistrictId', '');
        break;
      case 'warrantyId':
        setValue('breakdownId', '');
        break;
      default:
        break;
    }
  };

  return (
    <StyledContainer>
      <TextLarge text="ยื่นเรื่องเคลม" />
      <Row gutter={[24, 24]}>
        <Col span={14}>
          <CardTheme>
            <TextLarge text="ข้อมูลส่วนตัว" />
            <RenderForm
              control={control}
              setValue={setValue}
              getValues={getValues}
              onChange={onChange}
              forms={FORM_CLAIM_CREATE({
                onEnter: handleOnEnter,
                provinceOptions,
                districtOptions,
                subDistrictOptions,
                addressOptions,
                showOldAddress: !!fullName,
                watch,
              })}
            />
            <ContainerButton center>
              {isPendingUpload ? (
                <Spin spinning={isPendingUpload}></Spin>
              ) : (
                <ButtonTheme useFor="SUBMIT" title="บันทึก" onClick={handleSubmit(onSubmit)} />
              )}
            </ContainerButton>
          </CardTheme>
        </Col>
        <Col span={10}>
          <CardTheme>
            <TextLarge text="ข้อมูลการแจ้งเคลม" />
            <RenderForm
              control={control}
              setValue={setValue}
              onChange={onChange}
              getValues={getValues}
              forms={FORM_CLAIM_DATA({ productOptions, breakdownOptions })}
            />
          </CardTheme>
        </Col>
      </Row>
    </StyledContainer>
  );
};
