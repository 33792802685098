import WrapperComponent from '../components/hoc/Wrapper-component';
import { ClaimDetail } from './cliam/claim-detail/ClaimDetail';
import { ClaimEditDetail } from './cliam/claim-edit-detail/ClaimEditDetail';
import { ClaimProduct } from './cliam/claim-product/ClaimProduct';
import { ScanDeliver } from './scan-deliver/ScanDeliver';
import { RegistrationProduct } from './registration/registration-product/RegistrationProduct';
import { EditWarrantyProduct } from './registration/edit-warranty/EditWarrantyProduct';
import { ClaimCreate } from './cliam/claim-create/ClaimCreate';
import { ClaimHistory } from './cliam/claim-history/ClaimHistory';
import { UserInfo } from './manage-data/user-info/UserInfo';
import { Privileges } from './manage-data/privileges/Privileges';
import { FocusShop } from './manage-data/focus-shop/FocusShop';
import { OrderList } from './manage-data/order-list/OrderList';
import { ReportUseCoupon } from './report/report-use-coupon/ReportUseCoupon';
import { ReportMember } from './report/report-member/ReportMember';
import { ReportCoupon } from './report/report-coupon/ReportCoupon';
import { PdfClaim } from './cliam/claim-product/pdfPage/PdfClaim';
import { ReportFrequency } from './report/report-frequency/ReportFrequency';
import { ManagePermission } from './permission/ManagePermission';
import { CategoryList } from './product-warranty/category-list/CategoryList';
import { MobileBrandList } from './product-warranty/brand-list/MobileBrandList';
import { ModelList } from './product-warranty/model-list/ModelList';
import { ProductList } from './product-warranty/product-list/ProductList';
import { GroupProduct } from './product-warranty/map-product/GroupProduct';
import { Management } from './product-warranty/management/Management';
import { EditManagement } from './product-warranty/edit-management/EditManagement';
import { MainPage } from './mainPage/MainPage';
import { Registration } from './registration/registration-list/Registration';
import { RegistrationView } from './registration/registration-view/RegistrationView';

export default {
  ClaimProduct: WrapperComponent('ClaimProduct')(ClaimProduct),
  ClaimEditDetail: WrapperComponent('ClaimEditDetail')(ClaimEditDetail),
  ClaimDetail: WrapperComponent('ClaimDetail')(ClaimDetail),
  ScanDeliver: WrapperComponent('ScanDeliver')(ScanDeliver),
  RegistrationProduct: WrapperComponent('RegistrationProduct')(RegistrationProduct),
  EditWarrantyProduct: WrapperComponent('EditWarrantyProduct')(EditWarrantyProduct),
  ClaimCreate: WrapperComponent('ClaimCreate')(ClaimCreate),
  ClaimHistory: WrapperComponent('ClaimHistory')(ClaimHistory),
  UserInfo: WrapperComponent('UserInfo')(UserInfo),
  Privileges: WrapperComponent('Privileges')(Privileges),
  FocusShop: WrapperComponent('FocusShop')(FocusShop),
  OrderList: WrapperComponent('OrderList')(OrderList),
  ReportUseCoupon: WrapperComponent('ReportUseCoupon')(ReportUseCoupon),
  ReportMember: WrapperComponent('ReportMember')(ReportMember),
  ReportCoupon: WrapperComponent('ReportCoupon')(ReportCoupon),
  PdfClaim: WrapperComponent('PdfClaim')(PdfClaim),
  ReportFrequency: WrapperComponent('ReportFrequency')(ReportFrequency),
  ManagePermission: WrapperComponent('ManagePermission')(ManagePermission),
  CategoryList: WrapperComponent('CategoryList')(CategoryList),
  MobileBrandList: WrapperComponent('MobileBrandList')(MobileBrandList),
  ModelList: WrapperComponent('ModelList')(ModelList),
  ProductList: WrapperComponent('ProductList')(ProductList),
  GroupProduct: WrapperComponent('GroupProduct')(GroupProduct),
  Management: WrapperComponent('Management')(Management),
  EditManagement: WrapperComponent('EditManagement')(EditManagement),
  MainPage: WrapperComponent('MainPage')(MainPage),
  Registration: WrapperComponent('Registration')(Registration),
  RegistrationView: WrapperComponent('RegistrationView')(RegistrationView),
};
