import { useMutation } from '@tanstack/react-query';
import { POST, POST_CLAIM_REJECT } from '../../../../services';

export const useDeleteClaim = ({ modal }) =>
  useMutation({
    mutationFn: async ({ claimId, rejectReason }) => {
      const response = await POST(POST_CLAIM_REJECT(), {
        claimId,
        rejectReason,
      });
      return response;
    },
    onSuccess: (response) => {
      modal.success({ title: response.message, okText: 'ตกลง' });
    },
  });
