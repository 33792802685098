import React from 'react';
import { Dropdown, Menu, Switch, Tag, Button } from 'antd';
import { color, Icon } from '../../../../resources';
import { TextSmall } from '../../../text';
import { HeaderBarStyle, TopBarUser, TopBarDropDown } from '../../style';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../../../../redux/authen/action';

const HeaderLayout = ({ getTheme, collapsed, sidebarMode, toggleCollapse }) => {
  const userProfile = useSelector((state) => state.authen.users);
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout({}));
  };

  return (
    <HeaderBarStyle
      style={{
        ...getTheme.sidebar[sidebarMode],
        background: 'linear-gradient(to bottom, #24231D, #212121)',
      }}
    >
      {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
        className: 'trigger',
        style: { margin: '0 20px', color: color.white },
        onClick: toggleCollapse,
      })}
      <TopBarUser>
        <TextSmall
          className="item-right-topbar"
          style={{ color: color.white }}
          text={
            <>
              {userProfile.fullName} [{userProfile.userCode}]
            </>
          }
        />
      </TopBarUser>
      <TopBarDropDown>
        <Dropdown
          placement="bottomRight"
          overlay={
            <Menu
              items={[
                {
                  key: '1-logout',
                  label: <a onClick={handleLogout}>ออกจากระบบ</a>,
                },
              ]}
            />
          }
          trigger={['click']}
        >
          <Button
            type="ghost"
            icon={<Icon.settings style={{ fontSize: '20px', color: color.white }} />}
          />
        </Dropdown>
      </TopBarDropDown>
    </HeaderBarStyle>
  );
};

export default HeaderLayout;
