import { Switch, Route } from 'react-router-dom';
import { ContentStyle } from '../../style';
import NotfoundScene from '../../../../screens/not-found';
import { ROUTES_PATH } from '../../../../resources/routes-name';
import { useSelector } from 'react-redux';
const ContentLayout = ({ getTheme, sidebarMode }) => {
  const groupMenuList = useSelector((state) => state.authen.menu) || [];

  return (
    <ContentStyle style={{ ...getTheme.sidebar[sidebarMode] }}>
      <Switch>
        {Object.values(ROUTES_PATH)
          .filter((e) => groupMenuList.includes(e.CODE))
          .map((route) => {
            return <Route exact key={route.KEY} path={route.PATH} component={route.COMPONENT} />;
          })}
        <Route path="*" key="NOT_FOUND" component={NotfoundScene} />
      </Switch>
    </ContentStyle>
  );
};

export default ContentLayout;
