import { useForm } from 'react-hook-form';
import { ButtonTheme, ModalTheme, RenderForm } from '../../../../components';
import { ContainerButton } from '../../../../styles/global-style';
import { FORM_ADD_MANAGEMENT_SPECIAL } from '../forms';
import { channelBuyQuery } from '../../../../services/react-query/optionMasterQuery';
import { useInsertManageData } from '../hooks/useInsertManageData';

export const AddManagement = ({ open, onToggle, mapProductId }) => {
  const { control, setValue, getValues, handleSubmit } = useForm();

  const { data: channelBuyList } = channelBuyQuery();

  const { mutate: InsertData } = useInsertManageData();

  const onSubmit = (value) => {
    const formData = {
      ...value,
      mapProductId,
    };
    InsertData(formData);
    onToggle({ defaultValues: '' });

    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  return (
    <ModalTheme title="เพิ่มช่องทางรับประกัน" open={open} onToggle={onToggle}>
      <RenderForm
        control={control}
        forms={FORM_ADD_MANAGEMENT_SPECIAL({ channelBuyList })}
        setValue={setValue}
        getValues={getValues}
      />
      <ContainerButton right>
        <ButtonTheme useFor="SUBMIT" onClick={handleSubmit(onSubmit)} />
        <ButtonTheme useFor="CANCEL" onClick={onToggle} />
      </ContainerButton>
    </ModalTheme>
  );
};
