import React from 'react';
import { ButtonTheme, ModalTheme, RenderForm } from '../../../../components';
import { useForm } from 'react-hook-form';
import { ContainerButton } from '../../../../styles/global-style';
import { useInsertCategoryList } from '../hooks/useInsertCategoryList';
import { FORM_INSERT_CATEGORY } from '../forms';

export const AddCategory = ({ open, onToggle, onFetch }) => {
  const { control, setValue, getValues, handleSubmit } = useForm({ defaultValues: '' });

  const { mutate: onSubmitInsertCategory } = useInsertCategoryList({
    onSuccessCallBack: onFetch,
  });

  const onSubmit = (value) => {
    onSubmitInsertCategory(value);
    onToggle({ defaultValues: '' });
  };

  return (
    <ModalTheme title="เพิ่มสี QR" open={open} onToggle={onToggle}>
      <RenderForm
        control={control}
        setValue={setValue}
        getValues={getValues}
        forms={FORM_INSERT_CATEGORY()}
      />
      <ContainerButton right>
        <ButtonTheme useFor="SUBMIT" onClick={handleSubmit(onSubmit)} />
        <ButtonTheme useFor="CANCEL" onClick={onToggle} />
      </ContainerButton>
    </ModalTheme>
  );
};
