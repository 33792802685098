export const FORM_CHANGE_PASSWORD = (getValues) => {
  const span = 24;
  return [
    {
      name: 'newPassword',
      label: 'รหัสผ่านใหม่',
      type: 'INPUT_PASSWORD',
      placeholder: 'กรอกรหัสผ่านใหม่',
      span,
      rules: {
        required: true,
      },
    },
    {
      name: 'confirmNewPassword',
      label: 'ยืนยันรหัสผ่านใหม่',
      type: 'INPUT_PASSWORD',
      placeholder: 'ยืนยันรหัสผ่านใหม่',
      span,
      rules: {
        required: true,
        validate: (value) => value === getValues('newPassword') || 'รหัสผ่านไม่ตรงกัน',
      },
    },
  ];
};
