import { useForm } from 'react-hook-form';
import { ButtonTheme, ModalTheme, RenderForm } from '../../../../components';
import { ContainerButton } from '../../../../styles/global-style';
import { FORM_ADD_MANAGEMENT } from '../forms';
import { useEffect } from 'preact/hooks';
import { useManagementData } from '../hooks/onGetOptions';
import { useInsertManagement } from '../hooks/useInsertManagement';

export const AddManagement = ({ open, onToggle }) => {
  const { control, setValue, getValues, watch, handleSubmit } = useForm();

  const { mutate: InsertManagement } = useInsertManagement();

  const selectedBrandId = watch('mobileBrandId');
  const selectedCategoryId = watch('categoryId');

  const { categoryList, productList, brandList, modelList, handleCategoryChange } =
    useManagementData(selectedBrandId, setValue);

  useEffect(() => {
    if (selectedCategoryId) {
      handleCategoryChange(selectedCategoryId);
    }
  }, [selectedCategoryId, handleCategoryChange]);

  useEffect(() => {
    if (!selectedBrandId) {
      return;
    }
    setValue('mobileModelId', '');
  }, [selectedBrandId, setValue]);

  const onSubmit = (value) => {
    InsertManagement(value);
    onToggle({ defaultValues: '' });
  };

  return (
    <ModalTheme title="เพิ่มกลุ่มสินค้า" open={open} onToggle={onToggle}>
      <RenderForm
        control={control}
        forms={FORM_ADD_MANAGEMENT({ categoryList, brandList, productList, modelList })}
        setValue={setValue}
        getValues={getValues}
      />
      <ContainerButton right>
        <ButtonTheme useFor="SUBMIT" onClick={handleSubmit(onSubmit)} />
        <ButtonTheme useFor="CANCEL" onClick={onToggle} />
      </ContainerButton>
    </ModalTheme>
  );
};
