import React from 'react';
import { StyledContainer } from '../scan-deliver/styles';
import { CardTheme, TextLarge } from '@/components';
import { ResponsivePageLayout } from '@/components/page-layout/ResponsivePageLayout';
import { FORM_SEARCH } from './forms';
import { ContainerButton } from '@/styles/global-style';
import { ButtonTheme } from '@/components';
import { useAntDModalContext } from '@/components/modal/context/useAntdModalContext';
import { useManagePermission } from './hooks/useManagePermission';
import { columns } from './columns';
import { color } from '../../resources';
import { Spin } from 'antd';

export const ManagePermission = () => {
  const { openConfirmModal } = useAntDModalContext();
  const {
    formSearchObj,
    userList,
    dataSource,
    selectedRowKeys,
    onSelectChange,
    handleSubmitAccident,
    onFetch,
    isPendingUpdateUser,
    isPending,
  } = useManagePermission();

  return (
    <StyledContainer>
      <TextLarge text="ปรับสิทธิ์เมนู" />
      <CardTheme>
        <ResponsivePageLayout
          searchLayout={{
            title: 'ค้นหาข้อมูล',
            formSearch: FORM_SEARCH({ userList }),
            useFormParam: formSearchObj,
            onSearch: onFetch,
            ignoreDateEmpty: true,
          }}
          tableLayout={{
            columns: columns(),
            dataSource,
            loading: isPending,
            hasPagination: true,
            rowKey: 'id',
            rowSelection: {
              selectedRowKeys,
              onChange: onSelectChange,
            },
            extraContent: (
              <ContainerButton right>
                <Spin spinning={isPendingUpdateUser}>
                  <ButtonTheme
                    useFor="REGISTER"
                    title="บันทึก"
                    customBgColor={color.orangeTheme}
                    onClick={() => openConfirmModal({ onOk: handleSubmitAccident })}
                    disabled={selectedRowKeys.length === 0 || isPendingUpdateUser}
                    style={{ marginBottom: '30px' }}
                  />
                </Spin>
              </ContainerButton>
            ),
          }}
        />
      </CardTheme>
    </StyledContainer>
  );
};
