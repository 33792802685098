import { GET, GET_CLAIMS_CUSTOMER, GET_CLAIMS_ZIPCODE } from '../services';
import { handleBreakdownOptions } from './breakdownOptionsHandle';
import { serviceOptions } from './serviceOption';

export const onEnter = async (value, item, setValue) => {
  const { name } = item;
  if (!value) return;

  try {
    switch (name) {
      case 'telephone': {
        const apiUrl = GET_CLAIMS_CUSTOMER(value);
        const data = await GET(apiUrl);
        if (data.success) {
          const fullName = data.result.customer.fullName;
          const customerCode = data.result.customer.customerCode;

          setValue('fullName', fullName);
          setValue('customerCode', customerCode);

          const warranties = data.result.warranties || [];
          const productOptions = serviceOptions('CLAIM_PRODUCT', warranties);

          const address = data.result.addresses || [];
          const addressDataOptions = address;

          const selectedWarrantyId = productOptions.length ? productOptions[0].value : null;
          setValue('warrantyId', selectedWarrantyId);

          const breakdownOptions = handleBreakdownOptions(warranties, selectedWarrantyId);
          setValue('breakdownId', breakdownOptions.length ? breakdownOptions[0].value : null);

          const addressOptions = serviceOptions('ADDRESS', address);
          setValue('customerAddressId', addressOptions.length ? addressOptions[0].value : null);

          return {
            productOptions,
            breakdownOptions,
            addressOptions,
            addressDataOptions,
            customerCode,
          };
        }
        break;
      }

      case 'zipCode': {
        const apiUrl = GET_CLAIMS_ZIPCODE(value);
        const data = await GET(apiUrl);

        if (data.success) {
          const province = data.result.province;
          const district = data.result.district;
          const subDistrict = data.result.subDistrict;

          const provinceOptions = serviceOptions('PROVINCE', province);
          const districtOptions = serviceOptions('DISTRICT', district);
          const subDistrictOptions = serviceOptions('SUBDISTRICT', subDistrict);

          setValue('provinceId', province.provinceId);
          setValue('districtId', district.districtId);
          setValue('subDistrictId', subDistrict.subDistrictId);

          return { province, provinceOptions, districtOptions, subDistrictOptions };
        }
        break;
      }
      default:
        break;
    }
  } catch (error) {}
};
