import React from 'react';
import { QRCode } from 'antd';

const InfoRow = ({ label, value }) => (
  <span style={{ marginBottom: '6px' }}>
    <strong>{label}</strong> {value || 'ไม่มีข้อมูล'}
  </span>
);

const BillContent = ({ data }) => (
  <div className="container-bill" style={{ width: '100%', height: '100%' }}>
    <header style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <img
        src="../../../assets/images/logo_ablemen.5a905249.png"
        style={{ width: '3.5cm', height: '1.75cm' }}
        alt="Logo"
      />
      <div
        style={{ marginLeft: '20px', fontSize: '0.35cm', display: 'flex', flexDirection: 'column' }}
      >
        <span>บริษัท ดีพลัสอินเตอร์เทรด จำกัด</span>
        <span>123/20-22 ถ.นนทรี แขวงช่องนนทรี</span>
        <span>เขตยานนาวา กทม 10120 โทร: 02-294484-8</span>
      </div>
      <QRCode value={data?.claimCode || ''} size={100} style={{ marginLeft: '20px' }} />
    </header>

    <section
      style={{
        marginTop: '15px',
        display: 'flex',
        flexDirection: 'column',
        fontSize: '0.35cm',
      }}
    >
      <InfoRow label="ชื่อที่อยู่ผู้รับ:" value={data?.fullName} />
      <InfoRow label="จัดส่งคุณ:" value={data?.fullName} />
      <InfoRow label="ที่อยู่:" value={data?.addressFullText} />
      <InfoRow label="เบอร์โทรศัพท์:" value={data?.telephone} />
      <InfoRow label="เลขเอกสารการเคลม:" value={data?.claimCode} />
      <InfoRow
        label="สินค้า:"
        value={`${data?.productName || ''} ${data?.mobileBrandName || ''} ${
          data?.mobileModelName || ''
        }`}
      />
    </section>
  </div>
);

export default BillContent;
