import React from 'react';
import { ModalTheme } from '../../../../components/modal/index.jsx';
import { FORM_PROCESS_STATUS } from '../forms/index.js';
import { useForm } from 'react-hook-form';
import { useCallback } from 'preact/hooks';
import { ButtonTheme, RenderForm, TextLarge, TextSmall } from '../../../../components/index.js';
import { ContainerButton } from '../../../../styles/global-style.js';
import { processStatusQuery } from '../../../../services/react-query/optionMasterQuery.js';
import { useProcessStatus } from '../hooks/useProcessStatus.js';

export const ProcessStatus = ({ open, onToggle, initialValues, onFetch }) => {
  const { control, setValue, getValues, handleSubmit } = useForm({
    defaultValues: {
      processStatus: initialValues.processStatusName,
    },
  });

  const { mutate: onSubmitProcessStatus, isLoading: isSubmitting } = useProcessStatus({
    onSuccessCallBack: onFetch,
  });
  const { data: processStatusList, isFetching: _ } = processStatusQuery();

  const onSubmitSuccess = useCallback(
    (formData) => {
      const selectedStatus = processStatusList.find(
        (status) => status.value === formData.processStatus,
      );

      if (selectedStatus) {
        onSubmitProcessStatus({
          claimId: initialValues.claimId,
          processStatus: selectedStatus.value,
        });
      }
      onToggle({ defaultValues: '' });
    },
    [initialValues.claimId, processStatusList, onSubmitProcessStatus, onToggle],
  );

  return (
    <ModalTheme title=" " open={open} onToggle={onToggle} initialValues={initialValues}>
      <div style={{ textAlign: 'center', width: '100%' }}>
        <TextLarge text="แก้ไขสถานะภายใน" />
      </div>
      <div style={{ textAlign: 'center', width: '100%' }}>
        <TextSmall text={initialValues.claimCode + ' ' + ' ' + initialValues.fullName} />
      </div>
      <RenderForm
        control={control}
        forms={FORM_PROCESS_STATUS({ processStatusList })}
        setValue={setValue}
        getValues={getValues}
      />
      <ContainerButton right>
        <ButtonTheme
          useFor="SUBMIT"
          onClick={handleSubmit(onSubmitSuccess)}
          isLoading={isSubmitting}
        />
        <ButtonTheme useFor="CANCEL" onClick={onToggle} />
      </ContainerButton>
    </ModalTheme>
  );
};
