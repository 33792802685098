export const serviceOptions = (key, options = []) => {
  switch (key) {
    case 'PRODUCT':
      const defaultOption = options?.map((item) => ({
        label: item.productFullName,
        value: item.mapProductId,
      }));
      return defaultOption;

    case 'PRODUCT_OPTION':
      return options?.map((el) => ({
        label: el.productFullName,
        value: el.mapProductId,
      }));

    case 'CHANNEL_BUY':
      return options?.map((el) => ({ label: el.channelBuyNameTh, value: el.channelBuyId }));

    case 'WARRANTY_TYPES':
      return options?.map((el) => ({ label: el.warrantyTypeNameTh, value: el.warrantyTypeId }));
    case 'CUSTOMER_GROUP':
      return options.map((el) => ({ label: el.customerGroupCode, value: el.customerGroupId }));

    case 'PROVINCE':
      const provinceOptions = options?.map((item) => ({
        label: item.provinceNameTh,
        value: item.provinceId,
      }));
      return provinceOptions;

    case 'DISTRICT':
      const districtOptions = options?.map((item) => ({
        label: item.districtNameTh,
        value: item.districtId,
      }));
      return districtOptions;

    case 'SUBDISTRICT':
      const subDistrictOptions = options?.map((item) => ({
        label: item.subDistrictNameTh,
        value: item.subDistrictId,
      }));
      return subDistrictOptions;

    case 'CLAIM_PRODUCT':
      return options?.map((item) => ({
        label: `${item.mobileModelName} || ${item.productName}`,
        value: item.warrantyId,
      }));

    case 'BREAKDOWN':
      const breakdownOptions = options?.map((item) => ({
        label: item?.breakdownNameTh,
        value: item?.breakdownId,
      }));
      return breakdownOptions;

    case 'ADDRESS':
      const addressOptions = options?.map((address) => ({
        label: address.addressFullText,
        value: address.customerAddressId,
      }));
      return addressOptions;

    case 'PROCESS_STATUS':
      return options?.map((el) => ({ label: el.optionStatusNameTh, value: el.optionStatusCode }));
    case 'MOBILE_BRAND':
      return options?.map((brand) => ({
        label: brand.mobileBrandNameTh,
        value: brand.mobileBrandId,
        mobileModels: brand.mobileModels,
      }));
    case 'MOBILE_MODEL':
      return options?.map((model) => ({
        label: model.mobileModelNameTh,
        value: model.mobileModelId,
        products: model.products,
      }));
    case 'STORE_TYPE':
      return options?.map((el) => ({ label: el.storeTypeNameTh, value: el.storeTypeId }));
    case 'PROMOTIONS_TYPE':
      return options?.map((el) => ({ label: el.promotionName, value: el.promotionId }));

    case 'CHENNEL_MEMBER':
      return options?.map((el) => ({ label: el.channelName, value: el.channelCode }));

    case 'ORDER_STATUS':
      return options?.map((el) => ({ label: el.statusName, value: el.statusCode }));

    case 'USER':
      return options?.map((item) => ({
        label: `${item.userCode}: ${item.fullName}`,
        value: item.userId,
      }));
    case 'MOBILE_BRAND':
      return options?.map((el) => ({ label: el.mobileBrandNameTh, value: el.mobileBrandId }));

    case 'PRODUCT_TYPE':
      return options?.map((el) => ({ label: el.productTypeNameTh, value: el.productTypeCode }));

    case 'SHIPPING_SELF':
      return options?.map((el) => ({ label: el.shippingName, value: el.shippingId }));

    case 'CATEGORY_LIST':
      return options?.map((el) => ({
        label: `${el.categoryCode} :: ${el.categoryName}`,
        value: el.categoryId,
        defaultWarrantyPeriod: el.defaultWarrantyPeriod,
      }));

    case 'PRODUCT_LIST':
      return options?.map((el) => ({
        label: `${el.productCode} :: ${el.productNameTh}`,
        value: el.productId,
      }));

    case 'BRAND_LIST':
      return options?.map((el) => ({
        label: `${el.mobileBrandCode} :: ${el.mobileBrandNameTh}`,
        value: el.mobileBrandId,
      }));

    case 'MODEL_LIST':
      return options?.map((el) => ({
        label: `${el.mobileModelCode} :: ${el.mobileModelNameTh}`,
        value: el.mobileModelId,
      }));

    case 'MOBILE_BRAND_CODE':
      return options?.map((brand) => ({
        label: brand.mobileBrandNameTh,
        value: brand.mobileBrandCode,
      }));

    case 'CUSTOMER_GROUP_USER':
      return options?.map((el) => ({ label: el.customerGroupCode, value: el.customerGroupCode }));

    case 'CATEGORY_CODE':
      return options?.map((el) => ({
        label: el.categoryName,
        value: el.categoryCode,
      }));

    case 'PRODUCT_CODE':
      return options?.map((el) => ({
        label: el.productGroupNameEn,
        value: el.productGroupCode,
      }));
    case 'CATEGORY_OPTIONS':
      return options?.map((el) => ({
        label: `${el.categoryCode} :: ${el.categoryName}`,
        value: el.categoryId,
        mobilebrands: el.mobilebrands,
      }));
    default:
      return options;
  }
};
