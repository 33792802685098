export const useOnChangeValue = (setValue) => {
  return (val, item) => {
    switch (item.name) {
      case 'mobileBrandId':
        setValue('mobileModelId', '');
        setValue('mapProductId', '');
        break;
      case 'mobileModelId':
        setValue('mapProductId', '');
        break;
      default:
        break;
    }
  };
};
