import {
  createColumnNumber,
  createColumnText,
  widthOptions,
  createColumnButtonArr,
  formatNumber,
} from '@/components/table/function/createColumn';
import { Popconfirm } from 'antd';
import { ButtonTheme, ButtonToolTip } from '../../../../components';

export const columns = ({ onEdit, onSubmitDeleteCategory }) => {
  return [
    createColumnText('รหัสสี QR', 'categoryCode', widthOptions(150)),
    createColumnText('ชื่อสี QR', 'categoryName', widthOptions(150)),
    createColumnText('หมายเหตุ', 'remark', widthOptions(130)),
    createColumnNumber(
      'ระยะเวลารับประกัน',
      'defaultWarrantyPeriod',
      widthOptions(150, {
        render: (value) => formatNumber(value),
      }),
    ),
    createColumnButtonArr('จัดการ', 'rowNo', {
      others: {
        fixed: 'right',
        width: 180,
      },
      buttonArr: [
        {
          useFor: 'EDIT3',
          render: (text, record) => (
            <ButtonToolTip toolTip="แก้ไข" placement="topLeft">
              <ButtonTheme useFor="EDIT3" onClick={() => onEdit(record)} />
            </ButtonToolTip>
          ),
        },
        {
          useFor: 'DELETE2',
          render: (text, record) => (
            <ButtonToolTip toolTip="ลบ" placement="topLeft">
              <Popconfirm
                title="คุณต้องการที่จะลบสี QR ใช่หรือไม่?"
                onConfirm={() => onSubmitDeleteCategory(record)}
                okText="ใช่"
                cancelText="ไม่"
              >
                <ButtonTheme useFor="DELETE2" />
              </Popconfirm>
            </ButtonToolTip>
          ),
        },
      ],
    }),
  ];
};
