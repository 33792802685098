import React from 'react';
import { CardTheme } from '../../card/index.jsx';
import { useForm } from 'react-hook-form';
import { RenderForm } from '../../forms/index.jsx';
import { ButtonTheme } from '../../buttons/index.jsx';
import { App } from 'antd';
import { validateBetweenDate } from '../../../functions/fn.js';
import { ContainerButton } from '../../../styles/global-style.js';
import { TextSmall } from '../../text/index.jsx';
import { Row, Col } from 'antd';
import { useResponsive } from '../../../hooks/useResponsive.js';

export const RowSearchLayout = ({
  props,
  children,
  colProp = { inputSpan: { span: 18 }, buttonSpan: { span: 6 } },
}) => {
  const { isMobile } = useResponsive();
  const {
    title,
    formSearch,
    onSearch,
    icon,
    useFormParam,
    onClearCustom,
    ignoreDateEmpty = false,
  } = props;
  const { modal } = App.useApp();
  const useFormObj = useFormParam || useForm();
  const { handleSubmit, errors, setValue, getValues, control, reset } = useFormObj;
  const onClear = () => {
    reset();
  };

  const onSubmit = (data) => {
    let dateStart = '';
    let dateEnd = '';
    formSearch.forEach((val) => {
      if (val.type === 'DATE_PICKER') {
        if (val.label.includes('เริ่ม')) {
          dateStart = getValues(val.name);
        }
        if (val.label.includes('สิ้นสุด') || val.label.includes('ถึง')) {
          dateEnd = getValues(val.name);
        }
      }
    });

    if (dateStart && dateEnd) {
      let validDate = validateBetweenDate(dateStart, dateEnd);
      if (validDate) {
        modal.warning({
          title: validDate,
        });
        return;
      }
      onSearch(data);
    } else if (!ignoreDateEmpty && ((dateStart && !dateEnd) || (!dateStart && dateEnd))) {
      modal.warning({
        title: 'กรุณากรอกวันที่เริ่มต้นและวันที่สิ้นสุด',
      });
    } else {
      onSearch(data);
    }
  };

  return (
    <CardTheme
      title={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {icon}
          &nbsp;
          <TextSmall text={title} />
        </div>
      }
      content={
        <Row align={'middle'}>
          <Col {...(isMobile ? { span: 24 } : colProp.inputSpan)}>
            <RenderForm
              control={control}
              setValue={setValue}
              getValues={getValues}
              errors={errors}
              forms={formSearch}
            />
          </Col>
          <Col {...(isMobile ? { span: 24 } : colProp.buttonSpan)}>
            <ContainerButton center>
              <ButtonTheme useFor="SEARCH" onClick={handleSubmit(onSubmit)} id={'search-btn'} />
              <ButtonTheme
                useFor="CLEAR"
                onClick={onClearCustom ? () => onClearCustom(setValue, formSearch) : onClear}
                id={'clear-btn'}
              />
            </ContainerButton>
          </Col>
          {children}
        </Row>
      }
    />
  );
};
