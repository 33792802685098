import React from 'react';
import { CheckCircleOutlined } from '@ant-design/icons';
import { Timeline } from 'antd';

export const TimItem = ({ text, color }) => (
  <Timeline.Item
    dot={
      <CheckCircleOutlined
        style={{
          fontSize: '20px',
          color,
        }}
      />
    }
  >
    {text}
  </Timeline.Item>
);
