import { useMutation } from '@tanstack/react-query';
import { POST } from '../../../../services';
import { openAlert } from '../../../../components/alert/useAlert';
import { InsertManagementData } from '../../management/main';

const POST_INSERT_MANAGEMENT_DATE =
  '/warranty/abm/api/v2/admin/options/map-products/special-warranties/insert';

export const useInsertManageData = () => {
  return useMutation({
    mutationFn: async (values) => {
      const payload = InsertManagementData(values);
      const response = await POST(POST_INSERT_MANAGEMENT_DATE, payload);

      return response.result;
    },
    onSuccess: () => {
      openAlert({
        model: 'message',
        type: 'success',
        message: 'เพิ่ม Management สำเร็จ',
      });
      setTimeout(() => {
        window.location.reload();
      }, 500);
    },
  });
};
