import { brandQuery, modelQuery } from '../../../../services/react-query/optionMasterQuery';

export const useManagementData = (selectedBrandId) => {
  const { data: brandList } = brandQuery();
  const { data: modelList } = selectedBrandId ? modelQuery(selectedBrandId) : { data: null };

  return {
    brandList,
    modelList,
  };
};
