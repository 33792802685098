import { useMutation } from '@tanstack/react-query';
import { POST, POST_CLAIM_UPDATE } from '../../../../services';
import { openAlert } from '../../../../components/alert/useAlert';
import { ROUTES_PATH } from '../../../../resources/routes-name';

export const onUploadFile = ({ onSuccessCallBack }) =>
  useMutation({
    mutationFn: async ({
      claimImageUrl,
      claimId,
      customerCode,
      detail1,
      detail2,
      breakdownId,
      addressLine1,
      addressLine2,
      subDistrictId,
      remark,
      zipCode,
      other,
    }) => {
      const formData = new FormData();
      formData.append('claimImage', claimImageUrl.originFileObj);
      formData.append('claimId', claimId);
      formData.append('customerCode', customerCode);
      formData.append('detail1', detail1);
      formData.append('detail2', detail2);
      formData.append('breakdownId', breakdownId);
      formData.append('addressLine1', addressLine1);
      formData.append('addressLine2', addressLine2);
      formData.append('subDistrictId', subDistrictId);
      formData.append('zipCode', zipCode);
      formData.append('remark', remark);
      formData.append('other', other);
      const response = await POST(POST_CLAIM_UPDATE(), formData);
      if (onSuccessCallBack) onSuccessCallBack(response);
      return response;
    },
    onSuccess: (response) => {
      openAlert({ model: 'message', type: 'success', message: response.message });
      setTimeout(() => {
        navigateTo({
          pathname: ROUTES_PATH.ROUTE_CLAIM_DETAIL.PATH,
        });
      }, 1000);
    },
  });
