import React from 'react';
import { data, columns as tableColumns } from './columns/index';
import { StyledContainer } from './styles';
import { FORM_SEARCH } from './forms';
import { TextLarge, CardTheme, PageLayout } from '../../../components/index';
import { Icon } from '../../../resources';

export const ClaimHistory = () => {
  return (
    <StyledContainer>
      <TextLarge text="ประวัติการเคลม" />
      <CardTheme>
        <PageLayout
          searchLayout={{
            title: 'ค้นหาการเคลมสินค้า',
            formSearch: FORM_SEARCH(),
            icon: <Icon.todo />,
          }}
          tableLayout={{
            columns: tableColumns,
            dataSource: data,
            hasPagination: true,
            expandable: {
              expandedRowRender: (record) => (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div style={{ marginBottom: '8px' }}>
                    <strong>เลขที่การเคลม: </strong>
                    {record.claimNo}
                  </div>
                  <div style={{ marginBottom: '8px' }}>
                    <strong>สินค้า: </strong>
                    {record.productName}
                  </div>
                  <div>
                    <strong>วันที่แจ้งเคลม: </strong>
                    {record.dateReport}
                  </div>
                  <div>
                    <strong>เลขพัสดุ: </strong>
                    {record.transportNo}
                  </div>
                  <div>
                    <strong>ประเภทการเคลม: </strong>
                    {record.claimType}
                  </div>
                </div>
              ),
              rowExpandable: (record) => record.name !== 'Not Expandable',
            },
          }}
        />
      </CardTheme>
    </StyledContainer>
  );
};
