import { convertYearInputToPayload } from '../../../functions/convert';

export const createFocusShop = (value) => {
  return {
    customerAccountCode: value.customerAccountCode,
    saleCode: value.saleCode,
    storeMasterName: value.storeMasterName,
    storeName: value.storeName,
    phoneNumber: value.phoneNumber,
    email: value.email,
    storeTypeId: value.storeTypeId,
  };
};

export const updateFocusShop = (value) => {
  return {
    storeMasterName: value.storeMasterName,
    storeName: value.storeName,
    phoneNumber: value.phoneNumber,
    email: value.email,
    storeTypeId: value.storeTypeId,
    storeId: value.storeId,
    storeStatus: value.storeStatus === 'ACTIVE' ? true : false,
  };
};

export const createOrderlist = (value) => {
  return {
    invImage: value.invImage ? value.invImage.originFileObj : null,
    invNo: value.invNo,
    promotionId: value.promotionId,
    telephone: value.telephone,
    qty: value.qty,
    orderFrom: value.orderFrom,
    orderDate: value.orderDate,
    remark: value.remark,
  };
};

export const UpdateOrderList = (values) => {
  const formData = new FormData();

  formData.append('orderId', values.orderId);
  formData.append('promotionId', values.promotionId);
  formData.append('qty', values.qty);
  formData.append('orderStatus', values.orderStatus);
  formData.append('orderFrom', values.orderFrom);
  formData.append('remark', values.remark);
  formData.append('orderDate', values.orderDate);

  if (values.invImageUrl && values.invImageUrl.length > 0) {
    const file = values.invImageUrl[0];
    if (file.originFileObj) {
      formData.append('invImage', file.originFileObj);
    } else if (file.url) {
      formData.append('invImageUrl', file.url);
    }
  }

  return formData;
};
