import { useMutation } from '@tanstack/react-query';
import { POST } from '../../../../services';

const POST_APPROVE_CLAIM = `/warranty/abm/api/v2/admin/claims/update/status/approve-list`;

export const useApproveClaim = ({ modal, selectedRowKeys, setEmployee, onSuccessCallback }) =>
  useMutation({
    mutationFn: async () => {
      const payload = {
        claimIdList: selectedRowKeys,
      };
      const response = await POST(POST_APPROVE_CLAIM, payload);
      return response;
    },

    onSuccess: (response) => {
      modal.success({ title: 'อนุมัติเรื่องเคลมสำเร็จ', okText: 'ตกลง' }).then(() => {
        if (onSuccessCallback) {
          onSuccessCallback();
        }
        setEmployee([]);
      });
    },
  });
