import { useMemo } from 'preact/hooks';
import { serviceOptions } from '../../../../functions/serviceOption';

export const useOptionQuery = ({ products, watch, setValue }) => {
  const selectedBrandId = watch('mobileBrandId');
  const selectedModelId = watch('mobileModelId');

  const mobileBrandOptions = useMemo(() => {
    return serviceOptions('MOBILE_BRAND', products);
  }, [products]);

  const selectedBrand = useMemo(() => {
    return products?.find((brand) => brand?.mobileBrandId === selectedBrandId);
  }, [products, selectedBrandId]);

  const modelOptions = useMemo(() => {
    if (!selectedBrand?.mobileModels) return [];
    return selectedBrand?.mobileModels?.map((model) => ({
      value: model?.mobileModelId,
      label: model?.mobileModelNameTh,
    }));
  }, [selectedBrand]);

  const selectedModel = useMemo(() => {
    return selectedBrand?.mobileModels?.find((model) => model?.mobileModelId === selectedModelId);
  }, [selectedBrand, selectedModelId]);

  const productOptions = useMemo(() => {
    if (!selectedModel?.products) return [];
    return selectedModel?.products?.map((product) => ({
      value: product?.mapProductId,
      label: product?.productFullName,
    }));
  }, [selectedModel]);

  return { mobileBrandOptions, modelOptions, productOptions };
};
