import dayjs from 'dayjs';

export const FORM_SEARCH = ({ onEnter }) => {
  return [
    {
      name: 'search',
      label: 'ค้นหารายการ (กรอกข้อมูลแล้วกด Enter เพื่อค้นหา)',
      type: 'INPUT',
      span: 16,
      helper: 'Tips : สามารถค้นหาด้วย เบอร์ , รหัสลูกค้า , ชื่อลูกค้า',
      placeholder: 'ค้นหาข้อมูลความถี่การใช้คูปอง',
      onEnter,
    },
    {
      name: 'startDate',
      label: 'ค้นหาข้อมูลความถี่ใช้คูปองโดยอิงวันที่',
      type: 'DATE_RANGE',
      placeholder: '',
      span: 8,
    },
  ];
};

export const searchDefaultValue = {
  search: '',
  startDate: [dayjs().startOf('month'), dayjs()],
};
