import {
  createColumnDate,
  createColumnText,
  widthOptions,
} from '../../../../components/table/function/createColumn';

export const columns = [
  createColumnText('ลำดับ', 'key', widthOptions(150)),
  createColumnText('ชื่อ-นามสกุล', 'name', widthOptions(150)),
  createColumnText('เบอร์โทร', 'tel', widthOptions(120)),
  createColumnText('จำนวนการเคลม', 'claimCount', widthOptions(120)),
  createColumnDate('วันที่เคลมล่าสุด', 'lastestClaim', widthOptions(150)),
];

export const data = [
  {
    key: 1,
    name: 'John Brown',
    tel: '0931356897',
    lastestClaim: '01/08/2024',
    claimCount: '2',
  },
  {
    key: 2,
    name: 'Brown John',
    tel: '0641326857',
    lastestClaim: '04/08/2024',
    claimCount: '5',
  },
  {
    key: 3,
    name: 'Sit Down',
    tel: '0877778888',
    lastestClaim: '01/04/2024',
    claimCount: '3',
  },
  {
    key: 4,
    name: 'Anwa DekD',
    tel: '0911123456',
    lastestClaim: '01/07/2024',
    claimCount: '1',
  },
];
