import { useMutation } from '@tanstack/react-query';
import { GET, GET_CUSTOMERS_TEL } from '../../../../services';
import { openAlert } from '../../../../components/alert/useAlert';

export const useTeleQuery = ({ reset, getValues }) =>
  useMutation({
    mutationFn: async (value) => {
      try {
        const response = await GET(GET_CUSTOMERS_TEL(value));
        return response.result;
      } catch (error) {
        throw { ...error, disabledThrow: true };
      }
    },
    onSuccess: (result) => {
      openAlert({
        type: 'success',
        message: 'ค้นหาเบอร์โทรศัพท์สำเร็จ',
      });
      reset({
        ...getValues(),
        fullName: result.item.fullName,
      });
    },
    onError: (error) => {
      openAlert({
        type: 'error',
        message: error.message,
      });
      reset({
        ...getValues(),
        fullName: '',
      });
    },
  });
