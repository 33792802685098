import React from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { StyledContainer } from '../../cliam/claim-detail/styles';
import { ButtonTheme, CardTheme, RenderForm, TextLarge } from '../../../components';
import { Col, Row, Spin } from 'antd';
import { useForm } from 'react-hook-form';
import { FORM_EDIT_MANAGEMENT } from './forms';
import { useManagementData } from '../management/hooks/onGetOptions';
import { useEffect, useState } from 'preact/hooks';
import { useGetEditManagement } from './hooks/useGetEditManagement';
import { ResponsivePageLayout } from '../../../components/page-layout/ResponsivePageLayout';
import { useGetManageData } from './hooks/useGetManageData';
import { columns } from './columns';
import { ContainerButton } from '../../../styles/global-style';
import { useModal } from '../../../components/modal/hooks/useModal';
import { AddManagement } from './modals/AddModal';
import { EditModal } from './modals/EditModal';
import { useDeleteManageData } from './hooks/useDeleteManageData';
import { useUpdateManagement } from './hooks/useUpdateManagement';
import {
  categoryQuery,
  productGroupCodeQuery,
  productTypeQuery,
} from '../../../services/react-query/optionMasterQuery';
import { handleSubmitForm } from './func/handleSubmitform';

export const EditManagement = () => {
  const [dataModal, setDataModal] = useState(null);
  const { control, setValue, getValues, watch, reset, handleSubmit } = useForm();
  const { open: openAdd, onToggle: onToggleAdd } = useModal();
  const { open: openEdit, onToggle: onToggleEdit } = useModal();
  const [_, setIsSubmitting] = useState(false);
  const { state } = useLocation();

  const mapProductId = state?.mapProductId || null;

  if (!mapProductId) {
    return null;
  }

  const { dataSource: dataForm, loading: isPending } = useGetEditManagement({
    mapProductId: state.mapProductId,
    reset,
    setValue,
  });

  const { data: dataTable } = useGetManageData(mapProductId);

  const selectedBrandId = watch('mobileBrandId');
  const isSpecialWarrantyStatus = watch('specialWarrantyStatus');

  const { mutate: mutateUpload, isPending: isPendingUpload } = useUpdateManagement({
    onSuccessCallBack: () => {
      setIsSubmitting(false);
    },
  });

  const { data: productGroupCodeList } = productGroupCodeQuery();
  const { data: productTypeList } = productTypeQuery();
  const { data: categoryList } = categoryQuery();
  const { brandList, modelList } = useManagementData(selectedBrandId);

  useEffect(() => {
    setValue('isSpecialWarrantyStatus', false);
  }, [setValue]);

  const { mutate: onSubmitDeleteData } = useDeleteManageData();

  const onEdit = (record) => {
    setDataModal(record);
    onToggleEdit({ defaultValues: record });
  };

  const onSubmit = (value) => handleSubmitForm(value, setIsSubmitting, mutateUpload);

  return (
    <Spin spinning={isPending}>
      <StyledContainer>
        <Row gutter={16}>
          <Col span={12}>
            <CardTheme>
              <TextLarge text="แก้ไขจัดกลุ่มสินค้า " />
              <RenderForm
                control={control}
                setValue={setValue}
                getValues={getValues}
                forms={FORM_EDIT_MANAGEMENT({
                  brandList,
                  modelList,
                  productGroupCodeList,
                  productTypeList,
                  categoryList,
                })}
              />
              <ContainerButton center>
                {isPendingUpload ? (
                  <Spin spinning={isPendingUpload}></Spin>
                ) : (
                  <ButtonTheme useFor="SUBMIT" onClick={handleSubmit(onSubmit)} />
                )}
              </ContainerButton>
            </CardTheme>
          </Col>
          {isSpecialWarrantyStatus === 'ACTIVE' && (
            <Col span={12}>
              <CardTheme>
                <TextLarge text="ช่องทางการรับประกันพิเศษ" />
                <ContainerButton right>
                  <ButtonTheme
                    useFor="WARRANTY"
                    title="เพิ่มช่องทางรับประกัน"
                    onClick={onToggleAdd}
                  />
                  {openAdd && (
                    <AddManagement
                      open={openAdd}
                      onToggle={onToggleAdd}
                      mapProductId={mapProductId}
                    />
                  )}
                </ContainerButton>
                <ResponsivePageLayout
                  tableLayout={{
                    columns: columns({ onEdit, onSubmitDeleteData }) || [],
                    dataSource: Array.isArray(dataTable) ? dataTable : [],
                  }}
                />
              </CardTheme>
              {openEdit && (
                <EditModal open={openEdit} onToggle={onToggleEdit} initailValues={dataModal} />
              )}
            </Col>
          )}
        </Row>
      </StyledContainer>
    </Spin>
  );
};
