import React from 'react';
import { ModalTheme } from '../../../../components/modal/index.jsx';
import { FORM_EDIT_STORE, useFormState } from '../forms/index.js';
import { useForm } from 'react-hook-form';
import { ButtonTheme, RenderForm } from '../../../../components/index.js';
import { ContainerButton } from '../../../../styles/global-style.js';
import { useEffect } from 'preact/hooks';
import { storeTypeQuery } from '../../../../services/react-query/optionMasterQuery.js';
import { useUpdateStore } from '../hooks/useUpdateStore.js';
import { allOptions } from '../../../../functions/optionAll.js';

export const EditModal = ({ open, onToggle, initialValues, onFetch }) => {
  const { defaultValues } = useFormState(initialValues);
  const { control, setValue, getValues, handleSubmit } = useForm({
    defaultValues,
  });
  const { mutate: updateStore } = useUpdateStore({ onSuccessCallBack: onFetch });
  const { data: storeTypeList } = storeTypeQuery();
  const { statusOptions } = allOptions();

  useEffect(() => {
    if (!initialValues) {
      return;
    }
    Object.keys(initialValues).forEach((key) => {
      setValue(key, initialValues[key]);
    });
  }, [initialValues, setValue]);

  const onSubmit = (value) => {
    updateStore(value);
    onToggle({ defaultValues: '' });
  };

  return (
    <ModalTheme title="แก้ไขร้านค้า" open={open} onToggle={onToggle} initialValues={initialValues}>
      <RenderForm
        control={control}
        forms={FORM_EDIT_STORE({ storeTypeList, statusOptions })}
        setValue={setValue}
        getValues={getValues}
      />
      <ContainerButton right>
        <ButtonTheme useFor="SUBMIT" onClick={handleSubmit(onSubmit)} />
        <ButtonTheme useFor="CANCEL" onClick={onToggle} />
      </ContainerButton>
    </ModalTheme>
  );
};
