import { useMutation } from '@tanstack/react-query';
import { GET, GET_WARRANTY_REGISTER } from '../../../../services';
import { openAlert } from '../../../../components/alert/useAlert';
import { useState } from 'preact/hooks';
export const useWarrantyQuery = ({ reset, getValues }) => {
  const [data, setData] = useState([]);
  const mutation = useMutation({
    mutationFn: async (value) => {
      try {
        const response = await GET(GET_WARRANTY_REGISTER(value));
        return response.result;
      } catch (error) {
        throw { ...error, disabledThrow: true };
      }
    },
    onSuccess: (result) => {
      openAlert({
        type: 'success',
        message: 'ค้นหารหัสรับประกันสินค้าสำเร็จ',
      });
      reset({
        ...getValues(),
        ...result,
      });
      setData(result);
    },
    onError: (error) => {
      openAlert({
        type: 'error',
        message: error.message,
      });
    },
  });

  return { mutate: mutation.mutate, dataSource: setData, data };
};
