import React from 'react';
import { useLocation } from 'react-router-dom';
import { ButtonTheme } from '../../../../components';
import { navigateTo } from '../../../../utils/navigation';
import { ROUTES_PATH } from '../../../../resources/routes-name';
import { ContainerButton } from '../../../../styles/global-style';
import BillContent from './BillContent';
import { useBillControls } from './hooks/useBillControls';
import './style.css'; //style of ctrl + p

export const PdfClaim = () => {
  const location = useLocation();
  const pdfData = location.state?.pdfData || [];

  const { currentPage, nextPage, prevPage, handlePrint } = useBillControls(pdfData.length);

  const backToClaimPage = () => navigateTo({ pathname: ROUTES_PATH.ROUTE_CLAIM_PRODUCT.PATH });

  return (
    <>
      <div className="for-screen page-container">
        <div className="bill-wrapper">
          <BillContent data={pdfData[currentPage]} />
          <ContainerButton center className="no-print">
            <div
              style={{
                display: 'flex',
                gap: '10px',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '10px',
              }}
            >
              <ButtonTheme useFor="BACK" onClick={backToClaimPage} />
              {pdfData.length > 1 && (
                <>
                  <ButtonTheme
                    useFor="PREVIOUS"
                    title="ก่อนหน้า"
                    onClick={prevPage}
                    disabled={currentPage === 0}
                  />
                  <span>
                    {currentPage + 1} / {pdfData.length}
                  </span>
                  <ButtonTheme
                    useFor="GO"
                    title="ถัดไป"
                    onClick={nextPage}
                    disabled={currentPage === pdfData.length - 1}
                  />
                </>
              )}
              <ButtonTheme useFor="PRINT2" title="พิมพ์" onClick={handlePrint} />
            </div>
          </ContainerButton>
        </div>
      </div>

      <div className="for-print">
        {pdfData.map((data, index) => (
          <div key={index} className="print-page">
            <BillContent data={data} />
          </div>
        ))}
      </div>
    </>
  );
};
