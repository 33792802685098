import { useMemo } from 'preact/hooks';
import { serviceOptions } from '../../../../functions/serviceOption';

export const useOptions = ({ data, watch }) => {
  const { categoryId, mobilebrands, mobileBrandId, mobileModelId } = watch();
  const categoryOptions = useMemo(() => serviceOptions('CATEGORY_OPTIONS', data), [data]);
  const mobileBrandOptions = useMemo(
    () =>
      serviceOptions(
        'MOBILE_BRAND',
        categoryOptions?.find((el) => el?.value === categoryId)?.mobilebrands,
      ),
    [mobilebrands, categoryId],
  );

  const mobileModelOptions = useMemo(
    () =>
      serviceOptions(
        'MOBILE_MODEL',
        mobileBrandOptions?.find((el) => el?.value === mobileBrandId)?.mobileModels,
      ),
    [mobileBrandId, mobileBrandOptions],
  );

  const productOptions = useMemo(
    () =>
      serviceOptions(
        'PRODUCT',
        mobileModelOptions?.find((el) => el?.value === mobileModelId)?.products,
      ),
    [mobileModelId, mobileModelOptions],
  );

  return { categoryOptions, mobileBrandOptions, mobileModelOptions, productOptions };
};
