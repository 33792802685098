export const handleSubmitForm = (values, setIsSubmitting, mutateUpload) => {
  setIsSubmitting(true);

  const uploadData = {
    claimImageUrl: values.claimImageUrl[0] || null,
    claimId: values.claimId,
    customerCode: values.customerCode,
    detail1: values.detail1 || '',
    detail2: values.detail2 || '',
    breakdownId: values.breakdownId,
    addressLine1: values.addressLine1 || '',
    addressLine2: values.addressLine2 || '',
    subDistrictId: values.subDistrictId,
    zipCode: values.zipCode,
    remark: values.remark || '',
    other: values.other || '',
  };

  mutateUpload(uploadData);
};
