import styled from 'styled-components';
import { color } from '../../../../resources';

export const ScrollMenu = styled.div`
  height: calc(85vh - 180px);
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: ${color.theme};
    border-radius: 3px;
  }
`;
