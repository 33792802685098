import { useState } from 'react';
import { theme } from 'antd';
import { setStored } from '../store/useLocalStored';
import { getStored } from './../store/useLocalStored';
import { useEffect } from 'preact/hooks';
import { color } from './color';

const themeColor = color.theme;

export const getTheme = {
  sidebar: {
    dark: {
      background: '#141414',
    }, //defulat dark blue
    light: {
      background: '#FFFFFF',
    },
  },
  text: {
    dark: { color: 'white' },
    light: { color: 'black' },
  },
};

export const useTheme = () => {
  const globalTheme = {
    token: {
      //antD property
      colorPrimary: themeColor,
      fontFamily: 'Sarabun-Light',
    },
  };

  const [sidebarMode, setSidebarMode] = useState(getStored('theme') || 'light');
  const [algorithm, setAlgorithm] = useState({
    algorithm: theme.defaultAlgorithm,
  });

  useEffect(() => {
    if (getStored('theme') === 'dark') {
      setSidebarMode(getStored('theme'));
      setAlgorithm({ algorithm: theme.darkAlgorithm });
    }
  }, []);

  useEffect(() => {}, [sidebarMode]);

  const switchTheme = () => {
    const check = sidebarMode === 'dark';
    let mode = check ? 'light' : 'dark';
    let algo = check ? { algorithm: theme.defaultAlgorithm } : { algorithm: theme.darkAlgorithm };
    setStored('theme', mode);
    setSidebarMode(mode);
    setAlgorithm(algo);
  };

  return {
    sidebarMode,
    switchTheme,
    globalTheme: { ...globalTheme, ...algorithm },
  };
};
