import { useForm } from 'react-hook-form';
import { useState } from 'preact/hooks';
import { useGetUser } from './useGetUser';
import { userQuery } from '@/services/react-query/optionMasterQuery';
import { useUpdateUser } from './useUpdateUser';
import { searchDefaultValue } from '../forms';

export const useManagePermission = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const formSearchObj = useForm({ defaultValues: searchDefaultValue });

  const { data: userList } = userQuery();
  const {
    dataSource,
    onFetch,
    userId,
    loading: isPending,
  } = useGetUser({
    handleSubmit: formSearchObj.handleSubmit,
    setSelectedRowKeys,
  });

  const { mutate: updateUserPermission, isPending: isPendingUpdateUser } = useUpdateUser({
    userId,
    selectedRowKeys,
    dataSource,
    onSuccessCallback: onFetch,
  });

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleSubmitAccident = () => {
    updateUserPermission();
  };

  return {
    formSearchObj,
    userList,
    dataSource,
    selectedRowKeys,
    onSelectChange,
    handleSubmitAccident,
    onFetch,
    isPendingUpdateUser,
    isPending,
  };
};
