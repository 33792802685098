import React from 'react';
import { RowSearchLayout } from './views/RowSearchLayout.jsx';
import { ResponsiveTableLayout } from './views/ResponsiveTableLayout.jsx';
export const ResponsivePageLayout = ({ searchLayout, tableLayout, content, action }) => {
  return (
    <>
      {searchLayout && <RowSearchLayout props={{ ...searchLayout }} />}
      {content}
      {tableLayout && <ResponsiveTableLayout {...tableLayout} />}
      {action}
    </>
  );
};
