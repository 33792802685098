import { useMutation } from '@tanstack/react-query';
import { POST } from '../../../../services';
import { openAlert } from '../../../../components/alert/useAlert';

const POST_UPDATE_MEMBER = '/warranty/abm/api/v2/admin/member-orders/update';

export const useUpdateMember = ({ onSuccessCallBack }) => {
  return useMutation({
    mutationFn: async (formData) => {
      const response = await POST(POST_UPDATE_MEMBER, formData);
      if (onSuccessCallBack) onSuccessCallBack(response);

      return response.result;
    },
    onSuccess: () => {
      openAlert({
        model: 'message',
        type: 'success',
        message: 'แก้ไขข้อมูลสำเร็จ',
      });
    },
  });
};
