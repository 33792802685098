export const FORM_EDIT_MANAGEMENT2 = ({ categoryList, brandList, productList, modelList }) => {
  const span = 24;
  return [
    {
      name: 'categoryId',
      label: 'ชื่อหมวดหมู่สินค้า',
      type: 'DROPDOWN',
      properties: {
        options: categoryList || [],
      },
      rules: {
        required: true,
      },
      span,
    },
    {
      name: 'mobileBrandId',
      label: 'ชื่อแบรนด์',
      type: 'DROPDOWN',
      properties: {
        options: brandList || [],
      },
      rules: {
        required: true,
      },
      span,
    },
    {
      name: 'mobileModelId',
      label: 'ชื่อรุ่นสินค้า',
      type: 'DROPDOWN',
      properties: {
        options: modelList || [],
      },
      rules: {
        required: true,
      },
      span,
    },
    {
      name: 'productId',
      label: 'ชื่อสินค้า',
      type: 'DROPDOWN',
      properties: {
        options: productList || [],
      },
      rules: {
        required: true,
      },
      span,
    },
    {
      name: 'itemCode',
      label: 'รหัส Item',
      type: 'INPUT',
      span,
      rules: {
        required: true,
      },
    },
    {
      name: 'warrantyPeriod',
      label: 'วันรับประกัน',
      type: 'INPUT',
      span,
      rules: {
        required: true,
      },
    },
    {
      name: 'isSpecialWarrantyStatus',
      label: 'ช่องทางรับประกันพิเศษ',
      type: 'SWITCH',
      propperties: {
        checkedChildren: 'ON',
        unCheckedChildren: 'OFF',
      },
      switchColor: {
        onColor: 'green',
        offColor: 'red',
      },
      span,
    },
  ];
};

export const FORM_EDIT_MANAGEMENT = ({
  productGroupCodeList,
  productTypeList,
  brandList,
  modelList,
  categoryList,
}) => {
  const span = 24;
  const accept = [`image/jpeg`, 'image/png', 'image/jpg', 'image/bmp', 'image/gif'];
  return [
    {
      name: 'seqNo',
      label: 'ลำดับ',
      type: 'INPUT',
      span,
    },
    {
      name: 'categoryId',
      label: 'สี QR',
      type: 'DROPDOWN',
      properties: {
        options: categoryList || [],
      },
      rules: {
        required: true,
      },
      span,
    },
    {
      name: 'productId',
      label: 'รหัสสินค้า',
      type: 'HIDDEN',
    },
    {
      name: 'mobileBrandId',
      label: 'ชื่อแบรนด์ (iBrand2)',
      type: 'DROPDOWN',
      properties: {
        options: brandList || [],
      },
      rules: {
        required: true,
      },
      span,
    },
    {
      name: 'mobileModelId',
      label: 'ชื่อรุ่นสินค้า (iBrand3)',
      type: 'DROPDOWN',
      properties: {
        options: modelList || [],
      },
      rules: {
        required: true,
      },
      span,
    },
    {
      name: 'productTypeCode',
      label: 'ประเภทสินค้า (Major Category)',
      type: 'DROPDOWN',
      properties: {
        options: productTypeList || [],
      },
      rules: {
        required: true,
      },
      span,
    },
    {
      name: 'productGroupCode',
      label: 'กลุ่มสินค้า (ItemG3)',
      type: 'DROPDOWN',
      properties: {
        options: productGroupCodeList || [],
      },
      rules: {
        required: true,
      },
      span,
    },
    {
      name: 'productName',
      label: 'ชื่อสินค้า (Product Name)',
      type: 'INPUT',
      rules: {
        required: true,
      },
      span,
    },
    {
      name: 'salebotDescriptionTh',
      label: 'Salebot Description TH',
      type: 'INPUT',
      span,
    },
    {
      name: 'salebotColorTh',
      label: 'Salebot Color',
      type: 'INPUT',
      span,
    },
    {
      name: 'itemCode',
      label: 'Code G',
      type: 'INPUT',
      rules: {
        required: true,
      },
      span,
    },
    {
      name: 'warrantyPeriod',
      label: 'วันรับประกัน',
      type: 'INPUT',
      rules: {
        required: true,
      },
      span,
    },
    {
      name: 'mapProductStatus',
      label: 'สถานะ',
      type: 'DROPDOWN',
      properties: {
        options: [
          { label: 'เปิดใช้งาน', value: 'ACTIVE' },
          { label: 'ปิดใช้งาน', value: 'INACTIVE' },
        ],
      },
      rules: {
        required: true,
      },
      span,
    },
    {
      name: 'productImageUrl',
      label: 'รูปภาพ (.png, .jpg, .jpeg, .bmp, .gif)',
      type: 'UPLOAD',
      accept: accept.join(','),
      listType: 'picture-card',
      maxCount: 1,
      span,
    },
    {
      name: 'specialWarrantyStatus',
      label: 'ช่องทางรับประกันพิเศษ',
      type: 'DROPDOWN',
      properties: {
        options: [
          { label: 'เปิดใช้งาน', value: 'ACTIVE' },
          { label: 'ปิดใช้งาน', value: 'INACTIVE' },
        ],
      },
      span,
    },
  ];
};

export const FORM_ADD_MANAGEMENT_SPECIAL = ({ channelBuyList }) => {
  const span = 24;
  return [
    {
      name: 'mapProductId',
      type: 'HIDDEN',
      span,
    },
    {
      name: 'channelBuyId',
      label: 'ช่องทางการสั่งซื้อ',
      type: 'DROPDOWN',
      properties: {
        options: channelBuyList || [],
      },
      placeholder: 'กรุณาเลือกช่องทางการสั่งซื้อ',
      span,
    },
    {
      name: 'specialWarrantyPeriod',
      label: 'ระยะเวลารับประกัน',
      type: 'INPUT',
      placeholder: 'กรุณากรอกระยะเวลารับประกัน',
      span,
    },
  ];
};

export const FORM_EDIT_MANAGEMENT_SPECIAL = ({ channelBuyList }) => {
  const span = 24;
  return [
    {
      name: 'mapProductSpecialWarrantyId',
      type: 'HIDDEN',
      span,
    },
    {
      name: 'mapProductId',
      type: 'HIDDEN',
      span,
    },
    {
      name: 'channelBuyId',
      label: 'ช่องทางการสั่งซื้อ',
      type: 'DROPDOWN',
      properties: {
        options: channelBuyList || [],
      },
      span,
    },
    {
      name: 'specialWarrantyPeriod',
      label: 'ระยะเวลารับประกัน',
      type: 'INPUT',
      span,
    },
  ];
};

export const useFormState = (initialValues = {}) => {
  const defaultValues = {
    mapProductSpecialWarrantyId: initialValues.mapProductSpecialWarrantyId || '',
    mapProductId: initialValues.mapProductId || '',
    channelBuyId: initialValues.channelBuyId || '',
    specialWarrantyPeriod: initialValues.specialWarrantyPeriod || '',
  };

  return {
    defaultValues,
  };
};
