import { useMutation } from '@tanstack/react-query';
import { POST } from '@/services';
import { openAlert } from '@/components/alert/useAlert';
import { updatePermission } from '../main';

const POST_UPDATE_PRIVILEGE = `/warranty/abm/api/v2/admin/auth/users/update/privilege`;

export const useUpdateUser = ({ userId, selectedRowKeys, dataSource, onSuccessCallback }) => {
  return useMutation({
    mutationFn: async () => {
      const payload = updatePermission({ userId, selectedRowKeys, dataSource });

      const response = await POST(POST_UPDATE_PRIVILEGE, payload);
      return response;
    },

    onSuccess: () => {
      openAlert({ model: 'message', type: 'success', message: 'แก้ไขข้อมูลสำเร็จ' });
      if (onSuccessCallback) {
        onSuccessCallback();
      }
    },
  });
};
