import React from 'react';
import { CardTheme } from '../card/index.jsx';
import { Row, Col, Divider } from 'antd';
import { TextSmall, TextInputLabel } from '../text/index.jsx';

export const CardListComponent = ({ columns, dataSource, locale }) => {
  return dataSource.map((record, index) => {
    if (dataSource.length == 0) {
      return (
        <Col span={24}>
          <TextInputLabel style={{ textAlign: 'center' }}>
            {locale?.emptyText ? locale?.emptyText : `ไม่พบข้มูล`}
          </TextInputLabel>
        </Col>
      );
    }

    return (
      <CardTheme>
        <Row gutter={[0, 16]}>{columns.map((column) => renderCard(column, record, index))}</Row>
      </CardTheme>
    );
  });
};

const renderCard = (column, record, index) => {
  const dataIndex = column.dataIndex;
  const data = record[dataIndex];
  if (column.dataIndex == 'rowNum') {
    return (
      <Col span={24}>
        <TextInputLabel style={{ margin: '0px' }} bold>{`รายการที่ ${column.render(
          data,
          record,
          index,
        )}`}</TextInputLabel>
        <Divider style={{ margin: 0 }} />
      </Col>
    );
  }

  if (column?.children?.length > 0) {
    return (
      <>
        <Col span={24}>
          <TextSmall style={{ margin: '0px', textDecoration: 'underline' }} bold>
            {column.title}
          </TextSmall>
        </Col>
        {column.children.map((children) => renderCard(children, record, index))}
      </>
    );
  }

  return (
    <>
      <Col span={24}>
        <Row>
          {column.title && (
            <Col span={column?.cardProps?.titleSpan || 12}>
              <TextSmall style={{ margin: '0px' }} bold>
                {column.title}
              </TextSmall>
            </Col>
          )}
          <Col span={column?.cardProps?.dataSpan}>
            <TextInputLabel style={{ margin: '0px', ...column?.cardProps?.textStyle }}>
              <div style={{ marginLeft: column.dataSpan !== 24 ? '20px' : '0px' }}>
                {column.render ? column.render(data, record, index) : data}
              </div>
            </TextInputLabel>
          </Col>
        </Row>
      </Col>
    </>
  );
};
