import {
  createColumnTag,
  createColumnText,
  widthOptions,
} from '../../../../components/table/function/createColumn';
import { PROMOTION_STATUS } from '../../../../resources/status';

export const columns = () => {
  return [
    createColumnTag(
      'สถานะ',
      { key: 'promotionStatus', text: 'promotionStatus' },
      PROMOTION_STATUS,
      {
        width: '120px',
      },
    ),
    createColumnText('ลำดับ', 'rowNumber', widthOptions(150)),
    createColumnText('สิทธิพิเศษ', 'promotionName', widthOptions(150)),
  ];
};
