import React from 'react';
import { ModalTheme } from '../../../../components/modal/index.jsx';
import { FORM_EDIT_USER, useFormState } from '../forms/index.js';
import { useForm } from 'react-hook-form';
import { ButtonTheme, RenderForm } from '../../../../components/index.js';
import { ContainerButton } from '../../../../styles/global-style.js';
import { useEffect } from 'preact/hooks';
import { useUpdateMember } from '../hooks/useUpdateMember.js';
import {
  channelMemberQuery,
  orderStatusQuery,
  promotionsTypeQuery,
} from '../../../../services/react-query/optionMasterQuery.js';
import { UpdateOrderList } from '../../main/createManageData.js';

export const EditModal = ({ open, onToggle, initialValues, onFetch }) => {
  const { defaultValues } = useFormState(initialValues);
  const { control, setValue, getValues, handleSubmit } = useForm({
    defaultValues,
  });

  const { mutate: updateMember } = useUpdateMember({
    onSuccessCallBack: () => {
      onFetch();
    },
  });

  const { data: promotionsType } = promotionsTypeQuery();
  const { data: channelMember } = channelMemberQuery();
  const { data: orderStatus } = orderStatusQuery();

  useEffect(() => {
    if (!initialValues) {
      return;
    }
    Object.keys(initialValues).forEach((key) => {
      const temp =
        key === 'invImageUrl' && initialValues[key]
          ? [
              {
                uid: '-1',
                name: 'image.png',
                status: 'done',
                url: initialValues[key],
              },
            ]
          : initialValues[key];

      setValue(key, temp);
    });
  }, [initialValues, setValue]);

  const onSubmit = (values) => {
    const formData = UpdateOrderList(values);

    updateMember(formData);
    onToggle({ defaultValues: '' });
  };

  return (
    <ModalTheme title="แก้ไขรายการสั่งซื้อ" open={open} onToggle={onToggle}>
      <RenderForm
        control={control}
        forms={FORM_EDIT_USER({ promotionsType, channelMember, orderStatus })}
        setValue={setValue}
        getValues={getValues}
      />
      <ContainerButton right>
        <ButtonTheme useFor="SUBMIT" onClick={handleSubmit(onSubmit)} />
        <ButtonTheme useFor="CANCEL" onClick={onToggle} />
      </ContainerButton>
    </ModalTheme>
  );
};
