import React from 'react';
import { columns } from './columns/index';
import { SpaceBetween, StyledContainer } from './styles';
import { FORM_SEARCH, searchDefaultValue } from './forms';
import { TextLarge, CardTheme, ButtonTheme } from '../../../components/index';
import { Icon } from '../../../resources';
import { EditModal } from './modal/EditModal';
import { useModal } from '../../../components/modal/hooks/useModal';
import { useState } from 'preact/hooks';
import { ContainerButton } from '../../../styles/global-style';
import ButtonExcelEmbedded from '../../../components/report-excel/ButtonExcelEmbedded';
import { AddModal } from './modal/AddModal';
import { useGetStore } from './hooks/useGetStore';
import { useForm } from 'react-hook-form';
import { ResponsivePageLayout } from '../../../components/page-layout/ResponsivePageLayout';
import { useDeleteStore } from './hooks/useDeleteStore';
import { RowSearchLayout } from '../../../components/page-layout/views/RowSearchLayout';

export const FocusShop = () => {
  const [modalData, setModalData] = useState(null);
  const { open: openAdd, onToggle: onToggleAdd } = useModal();
  const { open: openEdit, onToggle: onToggleEdit } = useModal();

  const formSearchObj = useForm({ defaultValues: searchDefaultValue });
  const {
    dataSource,
    onSearchFull,
    onFetch,
    loading: isLoading,
  } = useGetStore({
    handleSubmit: formSearchObj.handleSubmit,
  });

  const { mutate: handleDelete, isLoading: _ } = useDeleteStore({
    onSuccessCallBack: onFetch,
  });

  const onEdit = (record) => {
    setModalData(record);
    onToggleEdit({ defaultValues: record });
  };

  return (
    <StyledContainer>
      <TextLarge text="ร้านค้า" />
      <CardTheme>
        <ContainerButton right>
          <ButtonTheme useFor="WARRANTY" title="เพิ่มร้านค้าใหม่" onClick={onToggleAdd} />
          {openAdd && <AddModal open={openAdd} onToggle={onToggleAdd} />}
        </ContainerButton>
        <RowSearchLayout
          props={{
            title: 'ค้นหาร้านค้า',
            formSearch: FORM_SEARCH(),
            useFormParam: formSearchObj,
            icon: <Icon.todo />,
            onSearch: formSearchObj.handleSubmit(onSearchFull),
          }}
        ></RowSearchLayout>
        <ResponsivePageLayout
          tableLayout={{
            columns: columns({ onEdit, handleDelete }),
            dataSource,
            loading: isLoading,
            hasPagination: true,
            extraContent: (
              <SpaceBetween>
                <ContainerButton right>
                  <ButtonExcelEmbedded
                    title="Export Excel"
                    header={columns({ onEdit, handleDelete })}
                    data={dataSource}
                    filename="รายงานรายการร้านค้า"
                  />
                </ContainerButton>
              </SpaceBetween>
            ),
          }}
        />
      </CardTheme>
      {openEdit && (
        <EditModal
          open={openEdit}
          onToggle={onToggleEdit}
          initialValues={modalData}
          onFetch={onFetch}
        />
      )}
    </StyledContainer>
  );
};
