import { useMutation } from '@tanstack/react-query';
import { POST } from '../../../../services';
import { openAlert } from '../../../../components/alert/useAlert';
import { updateFocusShop } from '../../main/createManageData';

const POST_USER_UPDATE = '/warranty/abm/api/v2/admin/stores/update/info';

export const useUpdateStore = ({ onSuccessCallBack }) =>
  useMutation({
    mutationFn: async ({ ...values }) => {
      const payload = updateFocusShop(values);
      const response = await POST(POST_USER_UPDATE, payload);
      if (onSuccessCallBack) onSuccessCallBack(response);

      return response.result;
    },
    onSuccess: () => {
      openAlert({
        model: 'message',
        type: 'success',
        message: 'แก้ไขข้อมูลสำเร็จ',
      });
    },
  });
