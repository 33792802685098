import { useMutation } from '@tanstack/react-query';
import { POST } from '../../../../services';
import { openAlert } from '../../../../components/alert/useAlert';

const POST_INSERT_PRODUCT = '/warranty/abm/api/v2/admin/options/products/update';

export const useUpdateProduct = ({ onSuccessCallBack }) => {
  return useMutation({
    mutationFn: async (formData) => {
      const response = await POST(POST_INSERT_PRODUCT, formData);
      if (onSuccessCallBack) onSuccessCallBack(response);

      return response;
    },
    onSuccess: () => {
      openAlert({
        model: 'message',
        type: 'success',
        message: 'แก้ไข Product สำเร็จ',
      });
    },
  });
};
