import {
  createColumnText,
  widthOptions,
  createColumnButtonArr,
} from '@/components/table/function/createColumn';
import { Popconfirm } from 'antd';
import { ButtonTheme, ButtonToolTip } from '../../../../components';
import { createColumnTag } from '../../../../components/table/function/createColumn';
import { STORE_STATUS, STORE_TYPE } from '../../../../resources/status';

export const columns = ({ onEdit = () => {}, handleDelete }) => {
  return [
    createColumnTag('สถานะ', { key: 'storeStatus', text: 'storeStatusName' }, STORE_STATUS, {
      width: '120px',
    }),
    createColumnText('รหัสร้านค้า', 'customerAccountCode', widthOptions(150)),
    createColumnText('ชื่อร้านค้า', 'storeMasterName', widthOptions(150)),
    createColumnText('สาขา', 'storeName', widthOptions(150)),
    createColumnTag('บริษัท', { key: 'storeTypeNameTh', text: 'storeTypeNameTh' }, STORE_TYPE),
    createColumnButtonArr('จัดการ', 'rowNo', {
      others: {
        fixed: 'right',
        width: 180,
      },
      buttonArr: [
        {
          useFor: 'EDIT3',
          render: (text, record) => (
            <ButtonToolTip toolTip="แก้ไข" placement="topLeft">
              <ButtonTheme useFor="EDIT3" onClick={() => onEdit(record)} />
            </ButtonToolTip>
          ),
        },
        {
          useFor: 'DELETE2',
          render: (text, record) => (
            <ButtonToolTip toolTip="ลบ" placement="topLeft">
              <Popconfirm
                title="คุณต้องการที่จะลบร้านค้าใช่หรือไม่?"
                onConfirm={() => handleDelete(record)}
                okText="ใช่"
                cancelText="ไม่"
              >
                <ButtonTheme useFor="DELETE2" />
              </Popconfirm>
            </ButtonToolTip>
          ),
        },
      ],
    }),
  ];
};
