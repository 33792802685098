import { useMutation } from '@tanstack/react-query';
import { GET, GET_CLAIMS_CUSTOMER } from '../../../../services';
import { openAlert } from '../../../../components/alert/useAlert';

export const useTeleQuery = ({ reset, getValues }) =>
  useMutation({
    mutationFn: async (value) => {
      try {
        const response = await GET(GET_CLAIMS_CUSTOMER(value));
        return response.result;
      } catch (error) {
        throw { ...error, disabledThrow: true };
      }
    },
    onSuccess: (result) => {
      openAlert({
        type: 'success',
        message: 'ค้นหาเบอร์โทรศัพท์สำเร็จ',
      });
      const fullName = result.customer.fullName;
      const customerCode = result.customer.customerCode;
      const address = result.addresses;

      const telephone = getValues('telephone');

      const warrantyId = 0;

      reset({
        ...getValues(),
        telephone,
        fullName,
        customerCode,
        address,
        warrantyId,
        breakdownId,
        ...result,
      });
    },
    onError: (error) => {
      openAlert({
        type: 'error',
        message: error.message,
      });
    },
  });
