import { useMutation } from '@tanstack/react-query';
import { POST } from '../../../../services';
import { openAlert } from '../../../../components/alert/useAlert';

const POST_USER_TEL = '/warranty/abm/api/v2/admin/customers/update/tel';

export const useUpdateTel = ({ onSuccessCallBack }) => {
  return useMutation({
    mutationFn: async ({ customerId, telephone }) => {
      const payload = {
        customerId,
        telephone,
      };
      const response = await POST(POST_USER_TEL, payload);
      if (onSuccessCallBack) onSuccessCallBack(response);

      return response.result.item;
    },
    onSuccess: () => {
      openAlert({
        model: 'message',
        type: 'success',
        message: 'แก้ไขข้อมูลสำเร็จ',
      });
    },
  });
};
