import { Card } from 'antd';
import { CustomImage } from '../../../../functions/Image';

export const expanded = (record) => {
  return (
    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
      <thead>
        <tr>
          <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>ชื่อคูปอง</th>
          <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>ประเภท</th>
          <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>รูป</th>
          <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>ขั้นต่ำ</th>
          <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>จำนวน</th>
          <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>
            วันที่เริ่ม
          </th>
          <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>
            วันที่สิ้นสุด
          </th>
          <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>
            รหัสสินค้า
          </th>
        </tr>
      </thead>
      <tbody>
        {record.promotionDetails.map((detail, index) => (
          <tr key={index}>
            <td style={{ border: '1px solid #ddd', padding: '8px' }}>{detail.productName}</td>
            <td style={{ border: '1px solid #ddd', padding: '8px' }}>{detail.couponType}</td>
            <td style={{ border: '1px solid #ddd', padding: '8px' }}>
              <CustomImage width={60} src={detail.promotionImageUrl} />
            </td>
            <td style={{ border: '1px solid #ddd', padding: '8px' }}>{detail.min}</td>
            <td style={{ border: '1px solid #ddd', padding: '8px' }}>{detail.qty}</td>
            <td style={{ border: '1px solid #ddd', padding: '8px' }}>{detail.startDate}</td>
            <td style={{ border: '1px solid #ddd', padding: '8px' }}>{detail.endDate}</td>
            <td style={{ border: '1px solid #ddd', padding: '8px' }}>{detail.itemCode}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
