import React from 'react';
import { CardTheme } from '../card/index';
import TableComponent from './../table/index.jsx';
import { Spin } from 'antd';

export const TableLayout = ({ props }) => {
  const {
    style,
    columns,
    dataSource,
    rowSelection,
    extraContent,
    hasPagination,
    rowKey,
    expandable,
    components,
    loading = false,
    ...rest
  } = props;
  return (
    <CardTheme
      style={{ marginTop: '2%', ...style }}
      content={
        <>
          {extraContent}
          <Spin spinning={loading}>
            <TableComponent
              rowKey={rowKey}
              columns={columns}
              dataSource={dataSource}
              hasPagination={hasPagination || false}
              rowSelection={rowSelection}
              components={components}
              expandable={expandable}
              {...rest}
            />
          </Spin>
        </>
      }
    />
  );
};
