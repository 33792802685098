import { useMutation } from '@tanstack/react-query';
import { POST } from '../../../../services';

const POST_APPROVE_CLAIM = `/warranty/abm/api/v2/admin/claims/update/status/approve-list`;

export const useApproveClaim = ({ modal }) =>
  useMutation({
    mutationFn: async ({ claimIdList }) => {
      const payload = {
        claimIdList,
      };
      const response = await POST(POST_APPROVE_CLAIM, payload);
      return response;
    },

    onSuccess: (response) => {
      modal.success({ title: response.message, okText: 'ตกลง' });
    },
  });
