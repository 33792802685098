import React, { useState } from 'react';
import { StyledContainer } from '../../cliam/claim-detail/styles';
import { TextLarge } from '../../../components';
import { ResponsivePageLayout } from '../../../components/page-layout/ResponsivePageLayout';
import { Icon } from '../../../resources';
import { useForm } from 'react-hook-form';
import { useModal } from '../../../components/modal/hooks/useModal';
import { FORM_SEARCH, searchDefaultValues } from './forms';
import { useGetProductList } from './hooks/useGetProductList';
import { columns } from './columns';
import { useDeleteProduct } from './hooks/useDeleteProduct';
import { EditProduct } from './modals/EditModal';
import { SpaceBetween } from '../../manage-data/user-info/styles';
import { ContainerButton } from '../../../styles/global-style';
import ButtonExcelEmbedded from '../../../components/report-excel/ButtonExcelEmbedded';

export const ProductList = () => {
  const [modalEdit, setModalEdit] = useState(null);
  const { open: openEdit, onToggle: onToggleEdit } = useModal();

  const formSearchObj = useForm({ defaultValues: searchDefaultValues });
  const formSearch = FORM_SEARCH();

  const {
    dataSource,
    onFetch,
    loading: isLoadingProductList,
  } = useGetProductList({ handleSubmit: formSearchObj.handleSubmit });
  const { mutate: onSubmitDeleteProduct } = useDeleteProduct({
    onSuccessCallBack: onFetch,
  });

  const onEdit = (record) => {
    setModalEdit(record);
    onToggleEdit({ defaultValues: record });
  };

  return (
    <div>
      <StyledContainer>
        <TextLarge>ค้นหาสินค้า</TextLarge>
        <ResponsivePageLayout
          searchLayout={{
            title: 'ค้นหาสินค้า',
            formSearch,
            useFormParam: formSearchObj,
            icon: <Icon.todo />,
            ignoreDateEmpty: true,
            onSearch: onFetch,
          }}
          tableLayout={{
            columns: columns({ onEdit, onSubmitDeleteProduct }),
            dataSource: dataSource || [],
            hasPagination: true,
            loading: isLoadingProductList,
            extraContent: (
              <SpaceBetween>
                <ContainerButton right>
                  <ButtonExcelEmbedded
                    title="Export"
                    header={columns({})}
                    data={dataSource}
                    filename="รายงานสินค้า"
                  />
                </ContainerButton>
              </SpaceBetween>
            ),
          }}
        />
        {openEdit && (
          <EditProduct
            open={openEdit}
            onToggle={onToggleEdit}
            onFetch={onFetch}
            initialValues={modalEdit}
          />
        )}
      </StyledContainer>
    </div>
  );
};
