import {
  createColumnText,
  widthOptions,
  createColumnNumber,
  createColumnButtonArr,
} from '@/components/table/function/createColumn';
import { ButtonTheme, ButtonToolTip } from '../../../../components';
import { Popconfirm } from 'antd';

export const columns = ({ onEdit, onSubmitDeleteData }) => {
  return [
    createColumnText('รหัสช่องทางการสั่งซื้อ', 'channelBuyCode', widthOptions(190)),
    createColumnText('ชื่อช่องทางการสั่งซื้อ', 'channelBuyNameTh', widthOptions(180)),
    createColumnNumber('ระยะเวลารับประกัน', 'specialWarrantyPeriod', widthOptions(180)),
    createColumnButtonArr('จัดการ', 'rowNo', {
      others: {
        fixed: 'right',
        width: 170,
      },
      buttonArr: [
        {
          useFor: 'EDIT3',
          render: (text, record) => (
            <ButtonToolTip toolTip="แก้ไข" placement="topLeft">
              <ButtonTheme useFor="EDIT3" onClick={() => onEdit(record)} />
            </ButtonToolTip>
          ),
        },
        {
          useFor: 'DELETE2',
          render: (text, record) => (
            <ButtonToolTip toolTip="ลบ" placement="topLeft">
              <Popconfirm
                title="คุณต้องการที่จะลบร้านค้าใช่หรือไม่?"
                onConfirm={() => onSubmitDeleteData(record)}
                okText="ใช่"
                cancelText="ไม่"
              >
                <ButtonTheme useFor="DELETE2" />
              </Popconfirm>
            </ButtonToolTip>
          ),
        },
      ],
    }),
  ];
};
