import { Fragment } from 'preact';
import { useController } from 'react-hook-form';
import { Input as InputAntd, InputNumber as InputNumberAntD } from 'antd';
import { Required, RowComponent } from '../../../styles/global-style';
import { TextXSMall, TextInputLabel } from '../../text';
import { renderTypeError } from '..';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { useRef } from 'preact/hooks';

const InputLabel = ({ control, item, handleChange }) => {
  const {
    rules,
    name,
    defaultValue,
    label,
    placeholder,
    disabled,
    onEnter,
    inputType = 'text',
    inputStep = '0.01',
    ...propsInput
  } = item;

  const { field, fieldState } = useController({
    control,
    name,
    rules,
    defaultValue,
  });
  const { error } = fieldState;
  const { value } = field;

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      const { value: v } = event.target;
      onEnter && onEnter(v, item, true);
    }
  };

  const helperStyle = {
    color: 'gray',
    fontSize: '12px',
    marginTop: '4px',
    paddingLeft: '4px',
  };
  return (
    <Fragment key={name}>
      <RowComponent>
        {label && <TextInputLabel text={label} />}
        {rules && rules.required && <Required>*</Required>}
      </RowComponent>
      <InputAntd
        id={name}
        name={name}
        value={value}
        type={inputType}
        step={inputStep}
        disabled={disabled}
        onChange={(e) => handleChange(e, item)}
        onKeyDown={(e) => handleKeyDown(e)}
        placeholder={placeholder}
        autoComplete={'off'}
        {...propsInput}
      />
      {error ? (
        <TextXSMall text={renderTypeError(item, error)} color="red" />
      ) : (
        item?.helper && <div style={helperStyle}>{item.helper}</div>
      )}
    </Fragment>
  );
};

export const InputArea = ({ control, item, handleChange }) => {
  const {
    rules,
    name,
    defaultValue,
    label,
    placeholder,
    disabled,
    onEnter,
    inputType = 'text',
    inputStep = '0.01',
    ...propsInput
  } = item;

  const { field, fieldState } = useController({
    control,
    name,
    rules,
    defaultValue,
  });
  const { error } = fieldState;
  const { value } = field;

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      const { value: v } = event.target;
      onEnter && onEnter(v, true);
    }
  };

  return (
    <Fragment key={name}>
      <RowComponent>
        {label && <TextInputLabel text={label} />}
        {rules && rules.required && <Required>*</Required>}
      </RowComponent>
      <InputAntd.TextArea
        id={name}
        name={name}
        value={value}
        type={inputType}
        step={inputStep}
        disabled={disabled}
        onChange={(e) => handleChange(e, item)}
        onKeyDown={(e) => handleKeyDown(e)}
        placeholder={placeholder}
        autoComplete={'off'}
        {...propsInput}
      />
      {error && <TextXSMall text={renderTypeError(item, error)} color="red" />}
    </Fragment>
  );
};

export const InputNumber = ({ control, item, handleChange, setValue }) => {
  const {
    rules,
    name,
    defaultValue,
    label,
    placeholder,
    disabled,
    onEnter,
    inputPrecision = 2,
    dataCy,
    hideRequire,
    hideError,
    onChangeCallBack,
    ...propsInput
  } = item;

  const { field, fieldState } = useController({
    control,
    name,
    rules,
    defaultValue,
  });
  const { error } = fieldState;
  const { value } = field;

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      const { value: v } = event.target;
      onEnter && onEnter(v, true);
    }
  };

  const currentValidValue = useRef(null);
  const resetToValidValue = () => {
    if (currentValidValue.current) {
      return setValue(name, currentValidValue.current);
    }
    return setValue(name, 0);
  };
  function handleBlur(element) {
    const curValue = parseFloat(element.target.value);

    if (isNaN(curValue)) {
      return resetToValidValue();
    }

    if (rules.min !== null && curValue < rules.min) {
      return resetToValidValue();
    }

    if (rules.max !== null && curValue > rules.max) {
      return resetToValidValue();
    }

    const fixedValue = curValue.toFixed(inputPrecision);
    currentValidValue.current = fixedValue;
    setValue(name, fixedValue, { shouldDirty: true });
  }

  return (
    <Fragment key={name}>
      <RowComponent>
        {label && <TextInputLabel text={label} />}
        {rules && rules.required && <Required>*</Required>}
      </RowComponent>
      <InputNumberAntD
        style={{ width: '100%', textAlign: 'right', ...item.style }}
        id={name}
        name={name}
        value={value}
        precision={inputPrecision}
        disabled={disabled}
        onChange={(e) => {
          handleChange(e, item);
          if (onChangeCallBack) {
            onChangeCallBack(e, item);
          }
        }}
        onKeyDown={(e) => handleKeyDown(e)}
        placeholder={placeholder}
        onBlur={handleBlur}
        {...dataCy}
        {...propsInput}
        type={'number'}
      />
      {error && <TextXSMall text={renderTypeError(item, error)} color="red" />}
    </Fragment>
  );
};

export const MemoizedInputLabel = InputLabel; //memo(, compareRender);

const InputPassword = ({ control, item, handleChange }) => {
  const { rules, name, defaultValue, label, placeholder, disabled, onEnter, ...propsInput } = item;

  const { field, fieldState } = useController({
    control,
    name,
    rules,
    defaultValue,
  });
  const { error } = fieldState;
  const { value } = field;

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      const { value: v } = event.target;
      onEnter && onEnter(v, true);
    }
  };

  return (
    <Fragment>
      <RowComponent>
        {label && <TextXSMall text={label} />}
        {rules && rules.required && <Required>*</Required>}
      </RowComponent>
      <InputAntd.Password
        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
        id={name}
        name={name}
        value={value}
        disabled={disabled}
        onChange={(e) => handleChange(e, item)}
        onKeyDown={(e) => handleKeyDown(e)}
        placeholder={placeholder}
        autoComplete={'off'}
        {...propsInput}
      />
      {error && <TextXSMall text={renderTypeError(item, error)} color="red" />}
    </Fragment>
  );
};

export const MemoizedInputPassword = InputPassword;

const InputPhoneNumber = ({ control, item, handleChange }) => {
  const { rules, name, defaultValue, label, placeholder, disabled, onEnter, ...propsInput } = item;

  const { field, fieldState } = useController({
    control,
    name,
    rules,
    defaultValue,
  });
  const { error } = fieldState;
  const { value } = field;

  const handleKeyDown = (event) => {
    if (
      !/^[0-9]$/.test(event.key) &&
      event.key !== 'Backspace' &&
      event.key !== 'ctrl' &&
      event.key !== 'v' &&
      event.key !== 'c' &&
      event.key !== 'CapsLock' &&
      event.key !== 'แ' &&
      event.key !== 'อ' &&
      event.key !== 'ฉ' &&
      event.key !== 'ฮ' &&
      event.key !== 'V' &&
      event.key !== 'C'
    ) {
      event.preventDefault();
    }

    if (event.key === 'Enter') {
      const { value: v } = event.target;
      onEnter && onEnter(v, item, true);
    }
  };

  return (
    <Fragment key={name}>
      <RowComponent>
        {label && <TextInputLabel text={label} />}
        {rules && rules.required && <Required>*</Required>}
      </RowComponent>
      <InputAntd
        id={name}
        name={name}
        value={value}
        disabled={disabled}
        onChange={(e) => handleChange(e, item)}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        autoComplete="off"
        {...propsInput}
      />
      {error && <TextXSMall text={renderTypeError(item, error)} color="red" />}
    </Fragment>
  );
};

export const MemoizedInputPhoneNumber = InputPhoneNumber;
