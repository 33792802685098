import React from 'react';
import { ImCheckmark } from 'react-icons/im';
import { BiEdit } from 'react-icons/bi';
import { RiDeleteBin6Line, RiFundsBoxFill, RiTBoxFill, RiTimeLine } from 'react-icons/ri';
import { color, Icon } from '../../../../resources';

export const requestStatusObg = {
  [10]: {
    icon: <BiEdit />,
    color: color.orangeTheme,
    label: 'ACTIVE (ลงทะเบียน)',
  },
  [20]: {
    icon: <ImCheckmark />,
    color: color.search2,
    label: 'CLAIMING (กำลังเคลม)',
  },
  [30]: {
    icon: <Icon.box />,
    color: color.orangeTheme,
    label: 'COMPLETED (สำเร็จ)',
  },
  [40]: {
    icon: <RiTimeLine />,
    color: '#9e9e9e',
    label: 'EXPIRED (หมดอายุ)',
  },
  [50]: {
    icon: <RiDeleteBin6Line />,
    color: color.red2,
    label: 'CANCEL (ยกเลิก)',
  },
};
