import { useMutation } from '@tanstack/react-query';
import { POST } from '../../../../services';
import { openAlert } from '../../../../components/alert/useAlert';
import { UpdateManagementData } from '../../management/main';

const POST_EDIT_MANAGEMENT_DATE =
  '/warranty/abm/api/v2/admin/options/map-products/special-warranties/update';

export const useEditManageData = () => {
  return useMutation({
    mutationFn: async (values) => {
      const payload = UpdateManagementData(values);
      const response = await POST(POST_EDIT_MANAGEMENT_DATE, payload);

      return response.result;
    },
    onSuccess: () => {
      openAlert({
        model: 'message',
        type: 'success',
        message: 'แก้ไข Management สำเร็จ',
      });
      setTimeout(() => {
        window.location.reload();
      }, 500);
    },
  });
};
