import { useEffect, useState } from 'preact/hooks';
import { openLoading, closeLoading } from '../../../components/alert/useAlert';

export const useFetchApi = () => {
  const [graphData, setGraphData] = useState([]);

  useEffect(() => {
    onFetchGraphdata();
  }, []);

  const onFetchGraphdata = () => {
    openLoading();
    const data = [
      {
        name: 'Page A',
        uv: 4000,
        pv: 2400,
        amt: 2400,
      },
      {
        name: 'Page B',
        uv: 3000,
        pv: 1398,
        amt: 2210,
      },
      {
        name: 'Page C',
        uv: 2000,
        pv: 9800,
        amt: 2290,
      },
      {
        name: 'Page D',
        uv: 2780,
        pv: 3908,
        amt: 2000,
      },
      {
        name: 'Page E',
        uv: 1890,
        pv: 4800,
        amt: 2181,
      },
      {
        name: 'Page F',
        uv: 2390,
        pv: 3800,
        amt: 2500,
      },
      {
        name: 'Page G',
        uv: 3490,
        pv: 4300,
        amt: 2100,
      },
    ];
    setGraphData(data);
    closeLoading();
  };

  const config = {
    data: graphData,
    margin: {
      top: 10,
      right: 30,
      left: 0,
      bottom: 0,
    },
  };

  return {
    config,
  };
};
