import React from 'react';
import { Col, Row } from 'antd';
import { TextSmall } from '../../../../components';
import { IconContainerDiv, StatusColumnContainer } from '../../../../styles/global-style';
import { requestStatusObg } from '../resource/requestStatusObg';
import { StepIcon } from '../../../../resources';

export const InfoClaimProcessStatus = () => {
  const statusArray = Object.entries(requestStatusObg);

  return (
    <>
      <Col span={24}>
        <TextSmall bold style={{ textDecoration: 'underline' }}>
          สถานะภายใน
        </TextSmall>
      </Col>
      <Col span={24}>
        <Row align={'middle'} style={{ marginTop: '3px', marginBottom: '3px' }}>
          {statusArray?.map(([key, { icon, color, label }], index) => (
            <React.Fragment key={key}>
              <Col>
                <StatusColumnContainer>
                  <IconContainerDiv style={{ color }}>{icon}</IconContainerDiv>
                  <TextSmall>{label}</TextSmall>
                </StatusColumnContainer>
              </Col>

              {index < statusArray.length - 1 && (
                <Col>
                  <StepIcon />
                </Col>
              )}
            </React.Fragment>
          ))}
        </Row>
      </Col>
    </>
  );
};
