import React, { useState } from 'react';
import { StyledContainer } from '../../cliam/claim-detail/styles';
import { ButtonTheme, TextLarge } from '../../../components';
import { ContainerButton } from '../../../styles/global-style';
import { ResponsivePageLayout } from '../../../components/page-layout/ResponsivePageLayout';
import { Icon } from '../../../resources';
import { FORM_SEARCH, searchDefaultValues } from './forms';
import { useForm } from 'react-hook-form';
import { columns } from './columns';
import { useGetCategoryList } from './hooks/useGetCategoryList';
import { useModal } from '../../../components/modal/hooks/useModal';
import { AddCategory } from './modals/AddCategory';
import { useDeleteCategoryList } from './hooks/useDeleteCategoryList';
import { EditCategory } from './modals/EditCategory';
import { SpaceBetween } from '../../manage-data/user-info/styles';
import ButtonExcelEmbedded from '../../../components/report-excel/ButtonExcelEmbedded';

export const CategoryList = () => {
  const [modalEdit, setModalEdit] = useState(null);
  const { open: openAdd, onToggle: onToggleAdd } = useModal();
  const { open: openEdit, onToggle: onToggleEdit } = useModal();

  const formSearchObj = useForm({ defaultValues: searchDefaultValues });
  const formSearch = FORM_SEARCH();

  const {
    dataSource,
    onFetch,
    loading: isLoadingCategoryList,
  } = useGetCategoryList({
    handleSubmit: formSearchObj.handleSubmit,
  });

  const { mutate: onSubmitDeleteCategory } = useDeleteCategoryList({
    onSuccessCallBack: onFetch,
  });

  const onEdit = (record) => {
    setModalEdit(record);
    onToggleEdit({ defaultValues: record });
  };

  return (
    <div>
      <StyledContainer>
        <TextLarge>ค้นหาสี QR</TextLarge>
        <ContainerButton right>
          <ButtonTheme useFor="WARRANTY" title="เพิ่มสี QR" onClick={onToggleAdd} />
          {openAdd && <AddCategory open={openAdd} onToggle={onToggleAdd} onFetch={onFetch} />}
        </ContainerButton>
        <ResponsivePageLayout
          searchLayout={{
            title: 'ค้นหาหมวดหมู่สินค้า',
            formSearch,
            useFormParam: formSearchObj,
            icon: <Icon.todo />,
            ignoreDateEmpty: true,
            onSearch: onFetch,
          }}
          tableLayout={{
            columns: columns({ onEdit, onSubmitDeleteCategory }),
            dataSource,
            loading: isLoadingCategoryList,
            extraContent: (
              <SpaceBetween>
                <ContainerButton right>
                  <ButtonExcelEmbedded
                    title="Export"
                    header={columns({})}
                    data={dataSource}
                    filename="รายงานหมวดหมู่สินค้า"
                  />
                </ContainerButton>
              </SpaceBetween>
            ),
          }}
        />
        {openEdit && (
          <EditCategory
            open={openEdit}
            onToggle={onToggleEdit}
            onFetch={onFetch}
            initialValues={modalEdit}
          />
        )}
      </StyledContainer>
    </div>
  );
};
