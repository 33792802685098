import { useMutation } from '@tanstack/react-query';
import { POST, POST_UPDATE_TRACKING } from '../../../../services';
import { openAlert } from '../../../../components/alert/useAlert';
import { updateTracking } from '../main';

export const useUpdateTracking = ({ onSuccessCallBack }) => {
  return useMutation({
    mutationFn: async (values) => {
      const payload = updateTracking(values);
      const response = await POST(POST_UPDATE_TRACKING(), payload);
      if (onSuccessCallBack) onSuccessCallBack(response);

      return response.result;
    },
    onSuccess: () => {
      openAlert({
        model: 'message',
        type: 'success',
        message: 'แก้ไขเลขพัสดุสำเร็จ',
      });
    },
  });
};
