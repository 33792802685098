import React from 'react';
import { color, Icon } from '../../../../resources';

export const requestStatusObg = {
  [10]: {
    icon: <Icon.smile />,
    color: color.search2,
    label: 'กลุ่มลูกค้าทั่วไป',
  },
  [20]: {
    icon: <Icon.crown />,
    color: color.orangeTheme,
    label: 'Super Member',
  },
};
