export const handleSubmitForm = (values, setIsSubmitting, mutateUpload) => {
  setIsSubmitting(true);

  const uploadData = {
    invImageUrl: values.invImageUrl[0] || null,
    packageImageUrl: values.packageImageUrl[0] || null,
    warrantyId: values.warrantyId,
    telephone: values.telephone,
    fullName: values.fullName,
    warrantyNumberCode: values.warrantyNumberCode,
    mobileBrandId: values.mobileBrandId,
    mobileModelId: values.mobileModelId,
    mapProductId: values.mapProductId,
    channelBuyId: values.channelBuyId,
    warrantyTypeId: values.warrantyTypeId,
  };
  mutateUpload(uploadData);
};
