import { useMutation } from '@tanstack/react-query';
import { openAlert } from '../../../components/alert/useAlert';
import { POST, POST_TRACKING_UPDATE } from '../../../services';
import { updateTrack } from '../main/Allpayload';

export const useUpdateTracking = () =>
  useMutation({
    mutationFn: async (values) => {
      const payload = updateTrack(values);

      const response = await POST(POST_TRACKING_UPDATE(), payload);

      return response;
    },
    onSuccess: () => {
      openAlert({
        model: 'message',
        type: 'success',
        message: 'แก้ไข Track number สำเร็จ',
      });
    },
  });
