import { color, Icon } from '../../../../resources';

export const requestStatusObg = {
  [10]: {
    label: 'รอตรวจสอบ (เคลม, ของขาด, ภาพ)',
    color: color.blue,
    icon: <Icon.hourglass />,
  },
  [20]: {
    label: 'รอติดต่อลูกค้า',
    color: color.red3,
    icon: <Icon.hourglass />,
  },
  [30]: {
    label: 'เตรียมพัสดุ',
    color: color.yellow,
    icon: <Icon.cube />,
  },
  [40]: {
    label: 'จัดส่งเรียบร้อย',
    color: color.line,
    icon: <Icon.paperPlane />,
  },
};

export const requestStatusObg2 = {
  [10]: {
    label: 'รอตรวจสอบ',
    color: color.blue,
    icon: <Icon.hourglass />,
  },
  [20]: {
    label: 'กำลังจัดส่ง',
    color: color.search2,
    icon: <Icon.truck />,
  },
  [30]: {
    label: 'เรียบร้อยแล้ว',
    color: color.line,
    icon: <Icon.paperPlane />,
  },
  [40]: {
    label: 'ยกเลิก',
    color: color.red2,
    icon: <Icon.times />,
  },
};
