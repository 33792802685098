import {
  createColumnCenter,
  createColumnDate,
  createColumnText,
  createColumnButtonArr,
  widthOptions,
} from '@/components/table/function/createColumn';
import { Icon } from '@/resources';
import { color } from '../../../../resources';
import { ButtonTheme, ButtonToolTip } from '../../../../components';

export const columns = (onModalMessage = () => {}, onEdit = () => {}) => [
  createColumnText('รหัสลูกค้า', 'customerCode', widthOptions(150)),
  createColumnText('ชื่อ', 'fullName', widthOptions(130)),
  createColumnDate('วันเกิด', 'birthday', widthOptions(120)),
  createColumnCenter(
    'กลุ่มลูกค้า',
    'customerLevel',
    widthOptions(150, {
      render: (text, record) => {
        if (record.customerLevel === 'GENERAL') {
          return (
            <div style={{ color: color.search2 }}>
              <Icon.smile />
            </div>
          );
        } else if (record.customerLevel === 'MEMBER') {
          return (
            <div style={{ color: color.orangeTheme }}>
              <Icon.crown />
            </div>
          );
        }
        return null;
      },
    }),
  ),
  createColumnText('เบอร์ติดต่อ', 'telephone', widthOptions(150)),
  createColumnText('ไลน์', 'lineName', widthOptions(120)),
  createColumnButtonArr('จัดการ', 'rowNo', {
    others: {
      fixed: 'right',
      width: 180,
    },
    buttonArr: [
      {
        useFor: 'EDIT3',
        render: (text, record) => (
          <ButtonToolTip toolTip="แก้ไข" placement="topLeft">
            <ButtonTheme useFor="EDIT3" onClick={() => onEdit(record)} />
          </ButtonToolTip>
        ),
      },
      {
        useFor: 'LINE2',
        render: (text, record) => (
          <ButtonToolTip toolTip="ส่งข้อความ" placement="topLeft">
            <ButtonTheme useFor="LINE2" onClick={() => onModalMessage(record)} />
          </ButtonToolTip>
        ),
      },
    ],
  }),
];
