export const FORM_USER_REGISTRATION_WARRANTY = () => {
  const span = 24;
  return [
    {
      name: 'telephone',
      label: 'เบอร์ติดต่อ',
      type: 'TEXT',
      placeholder: '',
      span,
      maxLength: 10,
      rules: {
        required: true,
        pattern: /^[0-9]*$/,
      },
    },
    {
      name: 'fullName',
      label: 'ชื่อ-นามสกุล',
      type: 'TEXT',
      placeholder: '',
      span,
    },
    {
      name: 'createDate',
      label: 'วันที่เริ่มรับประกัน',
      type: 'TEXT',
      placeholder: '',
      format: 'YYYY-MM-DD',
      span,
      disabled: true,
    },
    {
      name: 'expireDate',
      label: 'วันหมดอายุ',
      type: 'TEXT',
      format: 'YYYY-MM-DD',
      placeholder: '',
      span,
      disabled: true,
    },
  ];
};

export const FORM_PRODUCT_REGISTRATION_WARRANTY = () => {
  const span = 24;
  return [
    {
      name: 'warrantyCode',
      label: 'รหัสรับประกันสินค้า',
      type: 'TEXT',
      placeholder: '',
      span,
      rules: {
        required: true,
      },
      disabled: true,
    },
    {
      name: 'productName',
      label: 'ชื่อสินค้า',
      type: 'TEXT',
      placeholder: '',
      span,
      rules: {
        required: true,
      },
    },
    {
      name: 'mobileBrandName',
      label: 'ยี่ห้อมือถือ',
      type: 'TEXT',
      placeholder: '',
      span,
      rules: {
        required: true,
      },
    },
    {
      name: 'mobileModelName',
      label: 'รุ่นมือถือ',
      type: 'TEXT',
      placeholder: '',
      span,
      rules: {
        required: true,
      },
    },
    {
      name: 'channelBuyName',
      label: 'ช่องทางการซื้อขาย',
      type: 'TEXT',
      placeholder: '',
      span,
      rules: {
        required: true,
      },
    },
    {
      name: 'warrantyTypeName',
      label: 'ประเภทรับประกัน',
      type: 'TEXT',
      placeholder: '',
      span,
      rules: {
        required: true,
      },
    },
    {
      name: 'invImageUrl',
      label: 'รูปใบเสร็จ',
      type: 'PREVIEW',
      listType: 'picture-card',
      span: 12,
      maxCount: 1,
    },
    {
      name: 'packageImageUrl',
      label: 'รูปสินค้า',
      type: 'PREVIEW',
      listType: 'picture-card',
      span: 12,
      maxCount: 1,
    },
  ];
};
