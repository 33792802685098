import { useMutation } from '@tanstack/react-query';
import { POST, POST_DESTROY_MANAGEMENT_DATA } from '../../../../services';
import { openAlert } from '../../../../components/alert/useAlert';

export const useDeleteManageData = () =>
  useMutation({
    mutationFn: async ({ mapProductSpecialWarrantyId, remark }) => {
      const response = await POST(POST_DESTROY_MANAGEMENT_DATA(), {
        mapProductSpecialWarrantyId,
        remark,
      });

      return response;
    },
    onSuccess: () => {
      openAlert({ model: 'message', type: 'success', message: 'ลบข้อมูล Management data สำเร็จ' });
      setTimeout(() => {
        window.location.reload();
      }, 500);
    },
  });
