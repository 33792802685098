import React from 'react';
import { ModalTheme } from '../../../../components/modal/index.jsx';
import { FORM_CREATE_STORE } from '../forms/index.js';
import { useForm } from 'react-hook-form';
import { ButtonTheme, RenderForm } from '../../../../components/index.js';
import { ContainerButton } from '../../../../styles/global-style.js';
import { storeTypeQuery } from '../../../../services/react-query/optionMasterQuery.js';
import { useInsertStore } from '../hooks/useInsertStore.js';

export const AddModal = ({ open, onToggle, onFetch }) => {
  const { control, setValue, getValues, handleSubmit } = useForm({
    defaultValues: '',
  });
  const { mutate: onSubmitInsertStore } = useInsertStore({ onSuccessCallBack: onFetch });

  const { data: storeTypeList, isFetching: _ } = storeTypeQuery();

  const onSubmit = (value) => {
    onSubmitInsertStore(value);
    onToggle({ defaultValues: '' });
  };

  return (
    <ModalTheme title="เพิ่มร้านค้า" open={open} onToggle={onToggle}>
      <RenderForm
        control={control}
        forms={FORM_CREATE_STORE({ storeTypeList })}
        setValue={setValue}
        getValues={getValues}
      />
      <ContainerButton right>
        <ButtonTheme useFor="SUBMIT" onClick={handleSubmit(onSubmit)} />
        <ButtonTheme useFor="CANCEL" onClick={onToggle} />
      </ContainerButton>
    </ModalTheme>
  );
};
