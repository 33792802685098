import React from 'react';
import { columns } from './columns/index';
import { StyledContainer } from './styles';
import { FORM_SEARCH, searchDefaultValue } from './forms';
import { TextLarge, CardTheme, ButtonTheme } from '../../../components/index';
import { Icon } from '../../../resources';
import { useModal } from '../../../components/modal/hooks/useModal';
import { ContainerButton } from '../../../styles/global-style';
import { AddModal } from './modal/AddModal';
import { EditModal } from './modal/EditModal';
import { useEffect, useState } from 'preact/hooks';
import { ResponsivePageLayout } from '../../../components/page-layout/ResponsivePageLayout';
import { useForm } from 'react-hook-form';
import { useGetSuperMember } from './hooks/useGetSuperMember';
import { useGetFullSerperMember } from './hooks/useGetFullSerperMember';
import { useDeleteMember } from './hooks/useDeleteMember';
import { RowSearchLayout } from '../../../components/page-layout/views/RowSearchLayout';
import { SpaceBetween } from '../user-info/styles';
import ButtonExcelEmbedded from '../../../components/report-excel/ButtonExcelEmbedded';

export const OrderList = () => {
  const [modalData, setModalData] = useState(null);
  const [searchType, setSearchType] = useState(null);
  const { open: openEdit, onToggle: onToggleEdit } = useModal();
  const { open: openAdd, onToggle: onToggleAdd } = useModal();

  const formSearchObj = useForm({ defaultValues: searchDefaultValue });

  const {
    dataSource: superMemberData,
    onFetch,
    loading: isLoadingSuperMember,
  } = useGetSuperMember({
    handleSubmit: formSearchObj.handleSubmit,
  });
  const {
    dataSource: fullSerperMemberData,
    onFetchFull,
    loading: isLoadingFullSerperMember,
  } = useGetFullSerperMember({
    handleSubmit: formSearchObj.handleSubmit,
  });

  const onEdit = (record) => {
    setModalData(record);
    onToggleEdit({ defaultValues: record });
  };

  const { mutate: handleDelete } = useDeleteMember({
    onSuccessCallBack: onFetch,
  });

  useEffect(() => {
    onFetch(searchDefaultValue);
  }, []);

  const onSearchByField = (values) => {
    if (values.search) {
      setSearchType('search');
      onFetchFull(values);
    } else if (values.startDate) {
      setSearchType('date');
      onFetch(values);
    }
  };

  const formSearch = FORM_SEARCH({ onEnter: formSearchObj.handleSubmit(onSearchByField) });

  return (
    <StyledContainer>
      <TextLarge text="รายการสั่งซื้อ" />
      <CardTheme>
        <ContainerButton right>
          <ButtonTheme useFor="WARRANTY" title="เพิ่มรายการสั่งซื้อ" onClick={onToggleAdd} />
          {openAdd && <AddModal open={openAdd} onToggle={onToggleAdd} onFetch={onFetch} />}
        </ContainerButton>
        <RowSearchLayout
          props={{
            title: 'สถานะรายการสั่งซื้อ',
            formSearch,
            useFormParam: formSearchObj,
            icon: <Icon.todo />,
            onSearch: formSearchObj.handleSubmit(onSearchByField),
            ignoreDateEmpty: true,
          }}
        ></RowSearchLayout>
        <ResponsivePageLayout
          tableLayout={{
            columns: columns(onEdit, handleDelete),
            dataSource: searchType === 'search' ? fullSerperMemberData : superMemberData,
            hasPagination: true,
            loading: isLoadingSuperMember || isLoadingFullSerperMember,
            extraContent: (
              <SpaceBetween>
                <ContainerButton right>
                  <ButtonExcelEmbedded
                    title="Export"
                    header={columns({})}
                    data={searchType === 'search' ? fullSerperMemberData : superMemberData}
                    filename="รายงานรายการสั่งซื้อ"
                  />
                </ContainerButton>
              </SpaceBetween>
            ),
          }}
        />
      </CardTheme>
      {openEdit && (
        <EditModal
          open={openEdit}
          onToggle={onToggleEdit}
          initialValues={modalData}
          onFetch={onFetch}
        />
      )}
    </StyledContainer>
  );
};
