import { useMutation } from '@tanstack/react-query';
import { POST } from '../../../../services';
import { openAlert } from '../../../../components/alert/useAlert';
import { UpdateCategory } from '../../main/AllApi';

const POST_EDIT_CATEGORY = '/warranty/abm/api/v2/admin/options/categories/update';

export const useEditCategoryList = ({ onSuccessCallBack }) => {
  return useMutation({
    mutationFn: async (values) => {
      const payload = UpdateCategory(values);
      const response = await POST(POST_EDIT_CATEGORY, payload);
      if (onSuccessCallBack) onSuccessCallBack(response);

      return response.result;
    },
    onSuccess: () => {
      openAlert({
        model: 'message',
        type: 'success',
        message: 'แก้ไข Category สำเร็จ',
      });
    },
  });
};
