export const FORM_SEARCH = () => {
  return [
    {
      name: 'search',
      label: 'ค้นหารายการ (กรอกข้อมูลแล้วกด Enter เพื่อค้นหา)',
      type: 'INPUT',
      helper: 'Tips : สามารถค้นหาด้วย รหัสสี QR, ชื่อสี QR',
      placeholder: 'ค้นหาหมวดหมู่สินค้า',
      span: 12,
    },
  ];
};

export const FORM_INSERT_CATEGORY = () => {
  const span = 24;
  return [
    {
      name: 'categoryCode',
      label: 'รหัสสี',
      type: 'INPUT',
      placeholder: 'กรุณากรอกรหัสหมวดหมู่สินค้า',
      span,
      rules: {
        required: true,
      },
    },
    {
      name: 'categoryName',
      label: 'ชื่อสี',
      type: 'INPUT',
      placeholder: 'กรุณากรอกชื่อหมวดหมู่สินค้า',
      span,
      rules: {
        required: true,
      },
    },
    {
      name: 'defaultWarrantyPeriod',
      label: 'ระยะเวลารับประกัน (วัน)',
      type: 'INPUT_PHONE',
      span: 12,
      placeholder: 'กรุณากรอกระยะเวลารับประกัน',
      rules: {
        required: true,
      },
      pattern: /^[0-9]+$/,
    },
    {
      name: 'defaultClaimQty',
      label: 'จำนวนการรับประกัน (ครั้ง)',
      type: 'INPUT_PHONE',
      span: 12,
      defaultValue: 1,
      placeholder: 'กรุณากรอกจำนวนการรับประกัน',
      rules: {
        required: true,
      },
      pattern: /^[0-9]+$/,
    },
    {
      name: 'remark',
      label: 'หมายเหตุ (ระบุได้ไม่เกิน 250 ตัวอักษร)',
      type: 'INPUT_AREA',
      placeholder: 'กรุณากรอกหมายเหตุ',
      span: 24,
      rules: {
        maxLength: 250,
      },
    },
  ];
};

export const FORM_EDIT_CATEGORY = () => {
  return [
    {
      name: 'categoryId',
      type: 'HIDDEN',
    },
    {
      name: 'categoryCode',
      label: 'รหัสสี',
      type: 'INPUT',
      span: 24,
    },
    {
      name: 'categoryName',
      label: 'ชื่อสี',
      type: 'INPUT',
      span: 24,
    },
    {
      name: 'defaultWarrantyPeriod',
      label: 'ระยะเวลารับประกัน',
      type: 'INPUT',
      span: 12,
    },
    {
      name: 'defaultClaimQty',
      label: 'จำนวนการรับประกัน',
      type: 'INPUT',
      span: 12,
    },
    {
      name: 'remark',
      label: 'หมายเหตุ (ระบุได้ไม่เกิน 250 ตัวอักษร)',
      type: 'INPUT_AREA',
      span: 24,
      rules: {
        maxLength: 250,
      },
    },
  ];
};

export const searchDefaultValues = {
  search: '',
};
