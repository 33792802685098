import { Modal } from 'antd';
import React, { createContext, useContext } from 'react';

const AntDModalContext = createContext(null);

export const AntDModalContextProvider = ({ children }) => {
  const [modal, modalContext] = Modal.useModal();

  const openSuccessModal = ({ title = 'ทำรายการสำเร็จ', content = '', ...props }) =>
    modal.success({
      title,
      content,
      okText: 'ตกลง',
      ...props,
    });

  const openInfoModal = ({ title = 'ข้อมูล', content = '', ...props }) =>
    modal.info({
      title,
      content,
      okText: 'ตกลง',
      ...props,
    });

  const openWarningModal = ({ title = 'คำเตือน', content = '' }) =>
    modal.warning({
      title,
      content,
      okText: 'ตกลง',
    });

  const openConfirmModal = ({
    onOk = () => console.warn(`No onOk in openConfirmModal`),
    title = 'โปรดยืนยัน',
    content = '',
    ...props
  }) =>
    modal.confirm({
      title,
      content,
      okText: 'ยืนยัน',
      cancelText: 'ยกเลิก',
      onOk,
      ...props,
    });

  const value = { modal, openSuccessModal, openWarningModal, openConfirmModal, openInfoModal };
  return (
    <AntDModalContext.Provider value={value}>
      {modalContext}
      {children}
    </AntDModalContext.Provider>
  );
};

export const useAntDModalContext = () => {
  const value = useContext(AntDModalContext);

  if (!value) {
    throw new Error(`useAntDModalContext should be used within AntDModalContext`);
  }
  const { modal, openSuccessModal, openWarningModal, openConfirmModal, openInfoModal } = value;
  return { modal, openSuccessModal, openWarningModal, openConfirmModal, openInfoModal };
};
