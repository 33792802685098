import { useMutation } from '@tanstack/react-query';
import { GET, GET_WARRANTY_REGISTER } from '../../../../services';
import { openAlert } from '../../../../components/alert/useAlert';

export const useWarrantyQuery = ({ reset, getValues }) =>
  useMutation({
    mutationFn: async (value) => {
      try {
        const response = await GET(GET_WARRANTY_REGISTER(value));
        return response.result;
      } catch (error) {
        throw { ...error, disabledThrow: true };
      }
    },
    onSuccess: (result) => {
      openAlert({
        type: 'success',
        message: 'ค้นหารหัสรับประกันสินค้าสำเร็จ',
      });
      const mobilebrands = result.items[0]?.mobilebrands;
      reset({
        ...getValues(),
        mobilebrands,
        ...result,
      });
    },
    onError: (error) => {
      openAlert({
        type: 'error',
        message: error.message,
      });
    },
  });
