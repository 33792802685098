import { useMutation } from '@tanstack/react-query';
import { POST, POST_WARRANTY_ADD } from '../../../../services';
import { openAlert } from '../../../../components/alert/useAlert';
import { navigateTo } from '../../../../utils/navigation';
import { ROUTES_PATH } from '../../../../resources/routes-name';
import { InsertWarranty } from '../main/AllPayload';

export const onUploadFile = ({ onSuccessCallBack }) =>
  useMutation({
    mutationFn: async (values) => {
      const formData = new FormData();
      const payload = InsertWarranty(values);

      Object.entries(payload).forEach(([key, value]) => {
        if (value !== null && value !== undefined) {
          formData.append(key, value);
        }
      });

      const response = await POST(POST_WARRANTY_ADD(), formData);
      if (onSuccessCallBack) onSuccessCallBack(response);
      return response;
    },
    onSuccess: (response) => {
      openAlert({ model: 'message', type: 'success', message: response.message });
      setTimeout(() => {
        navigateTo({ pathname: ROUTES_PATH.ROUTE_REGISTRATION.PATH });
      }, 1000);
    },
  });
