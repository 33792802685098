import React from 'react';
import IndexScene from '../screens/home/index';
import Pages from '../screens/all-page.js';
import { Icon } from './icon.js';
import { FaCog } from 'react-icons/fa';

export const ROUTE_LOGIN = 'https://central.zetta-system.com';
export const ROUTE_LOGIN_MANUAL = '/account/login';
export const ROUTE_HANDLE = '/account/handle';
export const ROUTE_CHANGE_PASSWORD = '/account/change-password';
export const KEY_ROUTE_HOME = '@ROUTES/ROUTE_HOME';
export const KEY_ROUTE_MAIN = '@ROUTES/ROUTE_MAIN';

export const KEY_ROUTE_DASHBOARD = '@ROUTES/ROUTE_DASHBOARD';
export const KEY_ROUTE_TO_DO_LIST = '@ROUTES/ROUTE_TO_DO_LIST';
export const KEY_ROUTE_EXAMPLE = '@ROUTES/ROUTE_EXAMPLE';
export const KEY_ROUTE_EXAMPLE1 = '@ROUTES/ROUTE_EXAMPLE1';
export const KEY_ROUTE_EXAMPLE_CAROUSEL = '@ROUTES/ROUTE_EXAMPLE_CAROUSEL';
export const KEY_ROUTE_EXAMPLE_BUTTON = '@ROUTES/ROUTE_EXAMPLE_BUTTON';
export const KEY_ROUTE_EXAMPLE_TABLE = '@ROUTES/ROUTE_EXAMPLE_TABLE';
export const KEY_ROUTE_EXAMPLE_NOTI = '@ROUTES/ROUTE_EXAMPLE_NOTI';
export const KEY_ROUTE_EXAMPLE_YUP = '@ROUTES/ROUTE_EXAMPLE_YUP';
export const KEY_ROUTE_EXAMPLE_PAGE = '@ROUTES/ROUTE_EXAMPLE_PAGE';
export const KEY_ROUTE_EXAMPLE_MODAL = '@ROUTES/ROUTE_EXAMPLE_MODAL';
export const KEY_REGISTRATION_WARRANTY = '@ROUTES/ROUTE_REGISTRATION_WARRANTY';
export const KEY_SCAN_DELIVER = '@ROUTES/ROUTE_SCAN_DELIVER';
export const KEY_CLAIM_PRODUCT = '@ROUTES/ROUTE_CLAIM_PRODUCT';
export const KEY_CLAIM_DETAIL = '@ROUTES/ROUTE_CLAIM_DETAIL';
export const KEY_CLAIM_EDIT_DETAIL = '@ROUTES/ROUTE_CLAIM_EDIT_DETAIL';
export const KEY_CREATE_CLAIM = '@ROUTES/ROUTE_CREATE_CLAIM';
export const KEY_EDIT_WARRANTY = '@ROUTES/ROUTE_EDIT_WARRANTY';
export const KEY_MANAGE_DATA = '@ROUTES/ROUTE_MANAGE_DATA';
export const KEY_USER_INFO = '@ROUTES/ROUTE_USER_INFO';
export const KEY_PRIVILEGES = '@ROUTES/ROUTE_PRIVILEGES';
export const KEY_FOCUS_SHOP = '@ROUTES/ROUTE_FOCUS_SHOP';
export const KEY_ORDER_LIST = '@ROUTES/ROUTE_ORDER_LIST';
export const KEY_REPORT_ROUTE = '@ROUTES/ROUTE_REPORT_ROUTE';
export const KEY_REPORT_MEMBER = '@ROUTES/ROUTE_REPORT_MEMBER';
export const KEY_REPORT_COUPON = '@ROUTES/ROUTE_REPORT_COUPON';
export const KEY_REPORT_USE_COUPON = '@ROUTES/ROUTE_REPORT_USE_COUPON';
export const KEY_PDF_CLAIM = '@ROUTES/ROUTE_PDF_CLAIM';
export const KEY_REPORT_FREQUENCY = '@ROUTES/ROUTE_REPORT_FREQUENCY';
export const KEY_MANAGE_PERMISSION = '@ROUTES/ROUTE_MANAGE_PERMISSION';
export const KEY_PRODUCT_WARRANTY = '@ROUTES/ROUTE_PRODUCT_WARRANTY';
export const KEY_CATEGORY_LIST = '@ROUTES/ROUTE_CATEGORY_LIST';
export const KEY_BRAND_LIST = '@ROUTES/ROUTE_BRAND_LIST';
export const KEY_MODEL_LIST = '@ROUTES/ROUTE_MODEL_LIST';
export const KEY_PRODUCT_LIST = '@ROUTES/ROUTE_PRODUCT_LIST';
export const KEY_MAP_PRODUCT = '@ROUTES/ROUTE_MAP_PRODUCT';
export const KEY_GROUP_PRODUCT = '@ROUTES/ROUTE_GROUP_PRODUCT';
export const KEY_MANAGEMENT = '@ROUTES/ROUTE_MANAGEMENT';
export const KEY_EDIT_MANAGEMENT = '@ROUTES/ROUTE_EDIT_MANAGEMENT';
export const KEY_REGISTRATION = '@ROUTES/ROUTE_REGISTRATION';
export const KEY_REGISTRATION_VIEW = '@ROUTES/ROUTE_REGISTRATION_VIEW';

function renderStyle(comp) {
  return <div style={{ marginRight: '1px', color: '#FD8103' }}>{comp}</div>;
}

function whiteColor(comp) {
  return <div style={{ color: 'white' }}>{comp}</div>;
}

export const ROUTES_PATH = {
  ROUTE_MAIN: {
    KEY: KEY_ROUTE_MAIN,
    PATH: '/',
    LABEL: 'หน้าหลัก',
    LABEL2: 'หน้าหลัก',
    CODE: 'PE001',
    COMPONENT: Pages.MainPage,
  },
  ROUTE_HOME: {
    KEY: KEY_ROUTE_HOME,
    PATH: '/home',
    LABEL: 'หน้าหลัก',
    LABEL2: 'หน้าหลัก',
  },
  ROUTE_DASHBOARD: {
    KEY: KEY_ROUTE_DASHBOARD,
    ICON: renderStyle(<Icon.dashboard />),
    PATH: '/dashboard',
    LABEL: 'Dashboard',
    LABEL2: 'Dashboard',
    COMPONENT: IndexScene,
  },
  ROUTE_REGISTRATION: {
    KEY: KEY_REGISTRATION,
    ICON: renderStyle(<Icon.registered />),
    PATH: '/warranty',
    LABEL: 'รายการลงทะเบียน',
    LABEL2: 'รายการลงทะเบียน',
    CODE: 'PE101',
    COMPONENT: Pages.Registration,
  },
  ROUTE_REGISTRATION_WARRANTY: {
    KEY: KEY_REGISTRATION_WARRANTY,
    PATH: '/register-warranty',
    LABEL: 'ลงทะเบียนสินค้า',
    LABEL2: 'ลงทะเบียนสินค้า',
    CODE: 'PE111',
    COMPONENT: Pages.RegistrationProduct,
  },
  ROUTE_REGISTRATION_VIEW: {
    KEY: KEY_REGISTRATION_VIEW,
    PATH: '/registration-view',
    LABEL: 'ดูข้อมูลลงทะเบียน',
    LABEL2: 'ดูข้อมูลลงทะเบียน',
    CODE: 'PE113',
    COMPONENT: Pages.RegistrationView,
  },
  ROUTE_EDIT_WARRANTY: {
    KEY: KEY_EDIT_WARRANTY,
    PATH: '/edit-warranty',
    LABEL: 'แก้ไขข้อมูลรับประกัน',
    LABEL2: 'แก้ไขข้อมูลรับประกัน',
    CODE: 'PE112',
    COMPONENT: Pages.EditWarrantyProduct,
  },
  ROUTE_CLAIM_PRODUCT: {
    KEY: KEY_CLAIM_PRODUCT,
    ICON: renderStyle(<Icon.fileSearch />),
    PATH: '/claim',
    LABEL: 'เคลมสินค้า',
    LABEL2: 'เคลมสินค้า',
    CODE: 'PE201',
    COMPONENT: Pages.ClaimProduct,
  },
  ROUTE_SCAN_DELIVER: {
    KEY: KEY_SCAN_DELIVER,
    ICON: renderStyle(<Icon.qrcode />),
    PATH: '/multipost',
    LABEL: 'ยิงจ่ายพัสดุ',
    LABEL2: 'ยิงจ่ายพัสดุ',
    CODE: 'PE301',
    COMPONENT: Pages.ScanDeliver,
  },
  ROUTE_CLAIM_DETAIL: {
    KEY: KEY_CLAIM_DETAIL,
    ICON: renderStyle(<Icon.fileSearch />),
    PATH: '/claim-detail',
    LABEL: 'ดูการเคลมสินค้า',
    LABEL2: 'ดูการเคลมสินค้า',
    CODE: 'PE210',
    COMPONENT: Pages.ClaimDetail,
  },
  ROUTE_CLAIM_EDIT_DETAIL: {
    KEY: KEY_CLAIM_EDIT_DETAIL,
    ICON: renderStyle(<Icon.fileSearch />),
    PATH: '/claim_edit',
    LABEL: 'แก้ไขเคลมสินค้า',
    LABEL2: 'แก้ไขเคลมสินค้า',
    CODE: 'PE212',
    COMPONENT: Pages.ClaimEditDetail,
  },
  ROUTE_CREATE_CLAIM: {
    KEY: KEY_CREATE_CLAIM,
    ICON: renderStyle(<Icon.confirm />),
    PATH: '/create-claim',
    LABEL: 'ยื่นเรื่องเคลม',
    LABEL2: 'ยื่นเรื่องเคลม',
    CODE: 'PE211',
    COMPONENT: Pages.ClaimCreate,
  },
  ROUTE_USER_INFO: {
    KEY: KEY_USER_INFO,
    ICON: renderStyle(<Icon.users />),
    PATH: '/user',
    LABEL: 'ข้อมูลลูกค้า',
    LABEL2: 'ข้อมูลลูกค้า',
    CODE: 'PE401',
    COMPONENT: Pages.UserInfo,
  },
  ROUTE_PRIVILEGES: {
    KEY: KEY_PRIVILEGES,
    ICON: renderStyle(<Icon.special />),
    PATH: '/privileges',
    LABEL: 'สิทธิพิเศษ',
    LABEL2: 'สิทธิพิเศษ',
    CODE: 'PE402',
    COMPONENT: Pages.Privileges,
  },
  ROUTE_REPORT_MEMBER: {
    KEY: KEY_REPORT_MEMBER,
    ICON: renderStyle(<Icon.crown />),
    PATH: '/report-member',
    LABEL: 'สมัครเมมเบอร์',
    LABEL2: 'สมัครเมมเบอร์',
    CODE: 'PE601',
    COMPONENT: Pages.ReportMember,
  },
  ROUTE_REPORT_COUPON: {
    KEY: KEY_REPORT_COUPON,
    ICON: renderStyle(<Icon.coupon />),
    PATH: '/report-coupon',
    LABEL: 'คูปองทั้งหมด',
    LABEL2: 'คูปองทั้งหมด',
    CODE: 'PE602',
    COMPONENT: Pages.ReportCoupon,
  },
  ROUTE_REPORT_USE_COUPON: {
    KEY: KEY_REPORT_USE_COUPON,
    ICON: renderStyle(<Icon.ticket />),
    PATH: '/report-use-coupon',
    LABEL: 'ใช้คูปอง',
    LABEL2: 'ใช้คูปอง',
    CODE: 'PE603',
    COMPONENT: Pages.ReportUseCoupon,
  },
  ROUTE_FOCUS_SHOP: {
    KEY: KEY_FOCUS_SHOP,
    ICON: renderStyle(<Icon.store />),
    PATH: '/shop',
    LABEL: 'ร้านค้า',
    LABEL2: 'ร้านค้า',
    CODE: 'PE403',
    COMPONENT: Pages.FocusShop,
  },
  ROUTE_PDF_CLAIM: {
    KEY: KEY_PDF_CLAIM,
    ICON: renderStyle(<Icon.ticket />),
    PATH: '/bill-shipping',
    LABEL: 'บิล',
    LABEL2: 'บิล',
    CODE: 'PE213',
    COMPONENT: Pages.PdfClaim,
  },
  ROUTE_REPORT_FREQUENCY: {
    KEY: KEY_REPORT_FREQUENCY,
    ICON: renderStyle(<Icon.ticket />),
    PATH: '/report-frequency',
    LABEL: 'รายงานความถี่',
    LABEL2: 'รายงานความถี่',
    CODE: 'PE604',
    COMPONENT: Pages.ReportFrequency,
  },
  ROUTE_ORDER_LIST: {
    KEY: KEY_ORDER_LIST,
    ICON: renderStyle(<Icon.list />),
    PATH: '/order-list',
    LABEL: 'รายการสั่งซื้อ (Super Member)',
    LABEL2: 'รายการสั่งซื้อ (Super Member)',
    CODE: 'PE404',
    COMPONENT: Pages.OrderList,
  },
  ROUTE_MANAGE_PERMISSION: {
    KEY: KEY_MANAGE_PERMISSION,
    ICON: renderStyle(<FaCog />),
    PATH: '/manage-permission',
    LABEL: 'ปรับสิทธิ์เมนู',
    LABEL2: 'ปรับสิทธิ์เมนู',
    CODE: 'PE701',
    COMPONENT: Pages.ManagePermission,
  },
  ROUTE_CATEGORY_LIST: {
    KEY: KEY_CATEGORY_LIST,
    ICON: renderStyle(<Icon.ticket />),
    PATH: '/category-list',
    LABEL: 'สี QR',
    LABEL2: 'สี QR',
    CODE: 'PE501',
    COMPONENT: Pages.CategoryList,
  },
  ROUTE_BRAND_LIST: {
    KEY: KEY_BRAND_LIST,
    ICON: renderStyle(<Icon.ticket />),
    PATH: '/brand-list',
    LABEL: 'แบรนด์มือถือ',
    LABEL2: 'แบรนด์มือถือ',
    CODE: 'PE502',
    COMPONENT: Pages.MobileBrandList,
  },
  ROUTE_MODEL_LIST: {
    KEY: KEY_MODEL_LIST,
    ICON: renderStyle(<Icon.ticket />),
    PATH: '/model-list',
    LABEL: 'รุ่นมือถือ',
    LABEL2: 'รุ่นมือถือ',
    CODE: 'PE503',
    COMPONENT: Pages.ModelList,
  },
  ROUTE_PRODUCT_LIST: {
    KEY: KEY_PRODUCT_LIST,
    ICON: renderStyle(<Icon.ticket />),
    PATH: '/product-list',
    LABEL: 'สินค้า',
    LABEL2: 'สินค้า',
    CODE: 'PE504',
    COMPONENT: Pages.ProductList,
  },
  ROUTE_GROUP_PRODUCT: {
    KEY: KEY_GROUP_PRODUCT,
    ICON: renderStyle(<Icon.ticket />),
    PATH: '/product-group',
    LABEL: 'จัดกลุ่มสินค้า',
    LABEL2: 'จัดกลุ่มสินค้า',
    CODE: 'PE505',
    COMPONENT: Pages.GroupProduct,
  },
  ROUTE_MANAGEMENT: {
    KEY: KEY_MANAGEMENT,
    ICON: renderStyle(<Icon.ticket />),
    PATH: '/management',
    LABEL: 'การจัดการ',
    LABEL2: 'การจัดการ',
    CODE: 'PE511',
    COMPONENT: Pages.Management,
  },
  ROUTE_EDIT_MANAGEMENT: {
    KEY: KEY_EDIT_MANAGEMENT,
    ICON: renderStyle(<Icon.ticket />),
    PATH: '/edit-management',
    LABEL: 'แก้ไขการจัดการ',
    LABEL2: 'แก้ไขการจัดการ',
    CODE: 'PE512',
    COMPONENT: Pages.EditManagement,
  },
};

Object.keys(ROUTES_PATH).forEach((key) => {
  if (ROUTES_PATH[key].LABEL) {
    ROUTES_PATH[key].LABEL = ROUTES_PATH[key].LABEL;
  }
});

function getKeyLowerCase(obj) {
  return Object.fromEntries(Object.entries(obj).map(([k, v]) => [k.toLowerCase(), v]));
}

export const ROUTES_MENU = [
  getKeyLowerCase(ROUTES_PATH.ROUTE_REGISTRATION),
  getKeyLowerCase(ROUTES_PATH.ROUTE_CLAIM_PRODUCT),
  getKeyLowerCase(ROUTES_PATH.ROUTE_SCAN_DELIVER),
  getKeyLowerCase(ROUTES_PATH.ROUTE_USER_INFO),
  {
    key: KEY_MANAGE_DATA,
    icon: renderStyle(<Icon.formExample />),
    label: whiteColor('Super Member'),
    children: [
      getKeyLowerCase(ROUTES_PATH.ROUTE_PRIVILEGES),
      getKeyLowerCase(ROUTES_PATH.ROUTE_FOCUS_SHOP),
      getKeyLowerCase(ROUTES_PATH.ROUTE_ORDER_LIST),
    ],
  },
  {
    key: KEY_PRODUCT_WARRANTY,
    icon: renderStyle(<Icon.formExample />),
    label: whiteColor('สินค้ารับประกัน'),
    children: [
      getKeyLowerCase(ROUTES_PATH.ROUTE_CATEGORY_LIST),
      getKeyLowerCase(ROUTES_PATH.ROUTE_BRAND_LIST),
      getKeyLowerCase(ROUTES_PATH.ROUTE_MODEL_LIST),
      getKeyLowerCase(ROUTES_PATH.ROUTE_PRODUCT_LIST),
    ],
  },
  {
    key: KEY_REPORT_ROUTE,
    icon: renderStyle(<Icon.formExample />),
    label: whiteColor('รายงาน'),
    children: [
      getKeyLowerCase(ROUTES_PATH.ROUTE_REPORT_MEMBER),
      getKeyLowerCase(ROUTES_PATH.ROUTE_REPORT_COUPON),
      getKeyLowerCase(ROUTES_PATH.ROUTE_REPORT_USE_COUPON),
      getKeyLowerCase(ROUTES_PATH.ROUTE_REPORT_FREQUENCY),
    ],
  },
  getKeyLowerCase(ROUTES_PATH.ROUTE_MANAGE_PERMISSION),
];
