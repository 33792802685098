import { useQuery } from '@tanstack/react-query';
import { GET, GET_CATEGORY_LIST } from '../../../../services';

export const useGetCategoryQuery = () =>
  useQuery({
    queryKey: ['employeeListQuery'],
    queryFn: async () => {
      const response = await GET(GET_CATEGORY_LIST());
      return response.result;
    },
  });
