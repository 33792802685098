import { useMutation } from '@tanstack/react-query';
import { POST, POST_UPDATE_MAP_PRODUCT } from '../../../../services';
import { openAlert } from '../../../../components/alert/useAlert';
import { UpdateManagement } from '../../management/main';
import { navigateTo } from '../../../../utils/navigation';
import { ROUTES_PATH } from '../../../../resources/routes-name';

export const useUpdateManagement = ({ onSuccessCallBack }) =>
  useMutation({
    mutationFn: async (values) => {
      const formData = new FormData();
      const payload = UpdateManagement(values);

      Object.entries(payload).forEach(([key, value]) => {
        if (value !== null && value !== undefined) {
          formData.append(key, value);
        }
      });

      const response = await POST(POST_UPDATE_MAP_PRODUCT(), formData);
      if (onSuccessCallBack) onSuccessCallBack(response);
      return response.result;
    },
    onSuccess: () => {
      openAlert({
        model: 'message',
        type: 'success',
        message: 'แก้ไขจัดกลุ่มสินค้าสำเร็จ',
      });
      setTimeout(() => {
        navigateTo({ pathname: ROUTES_PATH.ROUTE_PRODUCT_LIST.PATH });
      }, 1500);
    },
  });
