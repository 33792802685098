import { GET, GET_PROMOTION_FULL_SEARCH } from '../../../../services';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';

export const useGetPromotion = () => {
  const [data, setData] = useState([]);

  const { isPending, mutate: onSearchFull } = useMutation({
    mutationFn: async () => {
      const response = await GET(GET_PROMOTION_FULL_SEARCH());
      return response.result.promotions || [];
    },
    onSuccess: (response) => {
      setData(response);
    },
  });

  return { dataSource: data, setData, onSearchFull, loading: isPending };
};
