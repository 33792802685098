import { useMutation } from '@tanstack/react-query';
import { POST } from '../../../../services';
import { openAlert } from '../../../../components/alert/useAlert';

const POST_SEND_MESSAGE = `/warranty/abm/api/v2/admin/claims/send-msg/line`;

export const useSendMessage = () => {
  return useMutation({
    mutationFn: async (payload) => {
      const response = await POST(POST_SEND_MESSAGE, payload);
      return response;
    },
    onSuccess: () => {
      openAlert({ model: 'message', type: 'success', message: 'ส่งข้อความสำเร็จ' });
    },
  });
};
