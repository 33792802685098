import { GET, GET_MAP_PRODUCT } from '../../../../services';
import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'preact/hooks';

export const useGetCategoryFull = ({ categoryCode, reset }) => {
  const [data, setData] = useState([]);
  const { isLoading, mutate } = useMutation({
    mutationFn: async () => {
      const response = await GET(GET_MAP_PRODUCT(categoryCode));
      return response.result;
    },
    onSuccess: (result) => {
      reset({ ...result.items });
      setData(result.items);
    },
  });

  useEffect(() => {
    if (categoryCode) {
      mutate();
    }
  }, [categoryCode]);

  return { dataSource: data, setData, onSearch: mutate, loading: isLoading };
};
