import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import {
  createColumnButton,
  createColumnText,
  widthOptions,
} from '../../../../components/table/function/createColumn';
import { ROUTES_PATH } from '../../../../resources/routes-name';

export const columns = () => {
  const history = useHistory();
  return [
    createColumnText('รหัสหมวดหมู่สินค้า', 'categoryCode', widthOptions(50)),
    createColumnText('ชื่อหมวดหมู่สินค้า', 'categoryName', widthOptions(100)),
    createColumnButton('แก้ไข', 'rowNo', {
      others: {
        width: 30,
      },
      buttonArr: [
        {
          useFor: 'EDIT2',
          title: 'แก้ไข',
          onClick: ({ record }) => {
            history.push({
              pathname: ROUTES_PATH.ROUTE_MANAGEMENT.PATH,
              state: { categoryCode: record.categoryCode },
            });
          },
        },
      ],
    }),
  ];
};
