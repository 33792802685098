import { useState } from 'react';

export const useBillControls = (pdfDataLength) => {
  const [currentPage, setCurrentPage] = useState(0);

  const nextPage = () => setCurrentPage((prev) => Math.min(prev + 1, pdfDataLength - 1));
  const prevPage = () => setCurrentPage((prev) => Math.max(prev - 1, 0));

  const handlePrint = () => window.print();

  return { currentPage, nextPage, prevPage, handlePrint };
};
