import { useEffect, useState } from 'react';
import { GET, GET_CLAIM_DETAIL } from '../../../../services';
import { useMutation } from '@tanstack/react-query';

export const useGetClaimEdit = ({ claimCode, reset }) => {
  const [data, setData] = useState();

  const { isPending, mutate } = useMutation({
    mutationFn: async () => {
      const response = await GET(GET_CLAIM_DETAIL(claimCode));
      return response.result;
    },
    onSuccess: (result) => {
      setData(result);
      reset({
        ...result?.claim,
        claimImageUrl: [{ uid: result?.claim?.claimImageUrl, url: result?.claim?.claimImageUrl }],
      });
    },
  });

  useEffect(() => {
    if (claimCode) {
      mutate();
    }
  }, [claimCode]);

  return { dataSource: data, loading: isPending };
};
