import { serviceOptions } from './serviceOption';

export const handleBreakdownOptions = (warranties, selectedWarrantyId) => {
  const selectedProduct = warranties?.find((item) => item.warrantyId === selectedWarrantyId);
  const breakdownOptions = serviceOptions(
    'BREAKDOWN',
    selectedProduct ? selectedProduct?.breakdowns : [],
  );
  return breakdownOptions;
};
