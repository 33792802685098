import { useCallback } from 'preact/hooks';
import { FORM_MESSAGE } from '../forms';
import { ButtonTheme, ModalTheme, RenderForm, TextLarge, TextSmall } from '../../../../components';
import { ContainerButton } from '../../../../styles/global-style';
import { useSendMessage } from '../hooks/useSendMessage';
import { useForm } from 'react-hook-form';

export const ModalMessage = ({ open, onToggle, initialValues }) => {
  const { control, setValue, handleSubmit } = useForm();

  const { mutate: sendMessage, isLoading } = useSendMessage();

  const onSubmitSuccess = useCallback(
    (formData) => {
      const payload = {
        customerId: initialValues.customerId,
        message: formData.message,
      };
      sendMessage(payload, {
        onSuccess: () => {
          onToggle({ defaultValues: '' });
        },
      });
    },
    [sendMessage, initialValues.customerId, onToggle],
  );

  return (
    <ModalTheme title=" " open={open} onToggle={onToggle} initialValues={initialValues}>
      <div style={{ textAlign: 'center', width: '100%' }}>
        <TextLarge text="ส่งข้อความ" />
      </div>
      <div>
        <TextSmall text={'ชื่อ : ' + initialValues.fullName} />
        <TextSmall text={'รหัสลูกค้า : ' + initialValues.customerCode} />
      </div>
      <RenderForm control={control} forms={FORM_MESSAGE()} setValue={setValue} />
      <ContainerButton right>
        <ButtonTheme useFor="SUBMIT" onClick={handleSubmit(onSubmitSuccess)} loading={isLoading} />
        <ButtonTheme useFor="CANCEL" onClick={onToggle} />
      </ContainerButton>
    </ModalTheme>
  );
};
