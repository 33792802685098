import React from 'react';
import { ModalTheme } from '@/components/modal/index.jsx';
import { FORM_EDIT_USER, useFormState } from '../forms/index.js';
import { useForm } from 'react-hook-form';
import { ButtonTheme, RenderForm } from '@/components/index.js';
import { ContainerButton } from '@/styles/global-style.js';
import { useEffect } from 'preact/hooks';
import { customerGroupsUserQuery } from '@/services/react-query/optionMasterQuery.js';
import { useUpdateInfo } from '../hooks/useUpdateInfo.js';
import { TelEdit } from './TelEdit.js';
import { useModal } from '@/components/modal/hooks/useModal.js';
import { useAntDModalContext } from '../../../../components/modal/context/useAntdModalContext.jsx';
import { color } from '../../../../resources/color.js';
import { useResetLine } from '../hooks/useResetLine.js';

export const ViewModal = ({ open, onToggle, initialValues, onFetch }) => {
  const { defaultValues } = useFormState(initialValues);
  const { control, setValue, getValues, handleSubmit } = useForm({
    defaultValues,
  });

  const { open: openTel, onToggle: onToggleTel } = useModal();

  const { data: customerGroupsList } = customerGroupsUserQuery();
  const { mutate: onSubmitUpdateUser } = useUpdateInfo({
    onSuccessCallBack: onFetch,
  });
  const { mutate: onSubmitResetLine } = useResetLine({ onSuccessCallBack: onFetch });
  const { openConfirmModal } = useAntDModalContext();

  const onCloseAll = () => {
    onToggle({ defaultValues: '' });
    onToggleTel({ defaultValues: '' });
  };

  useEffect(() => {
    if (!initialValues) {
      return;
    }
    Object.keys(initialValues).forEach((key) => {
      setValue(key, initialValues[key]);
    });
  }, [initialValues, setValue]);

  const onSubmit = (value) => {
    onSubmitUpdateUser(value);
    onToggle({ defaultValues: '' });
  };

  return (
    <ModalTheme
      title="แก้ไขข้อมูล"
      open={open}
      onToggle={onToggle}
      initialValues={initialValues}
      onFetch={onFetch}
    >
      <RenderForm
        control={control}
        forms={FORM_EDIT_USER({ customerGroupsList })}
        setValue={setValue}
        getValues={getValues}
      />
      <div className="button">
        <ButtonTheme
          useFor="TEL"
          style={{ backgroundColor: color.search2 }}
          title="แก้ไขเบอร์ติดต่อ"
          onClick={onToggleTel}
        />
        <ButtonTheme
          useFor="LINE"
          title="รีเซ็ตไลน์"
          style={{ marginLeft: '40%', backgroundColor: color.line }}
          onClick={() =>
            openConfirmModal({
              onOk: () => {
                onSubmitResetLine({ customerId: initialValues.customerId });
                onToggle({ defaultValues: '' });
              },
              title: 'คุณต้องการรีเซ็ตไลน์หรือไม่',
            })
          }
        />
      </div>

      <ContainerButton right>
        <ButtonTheme useFor="SUBMIT" onClick={handleSubmit(onSubmit)} />
        <ButtonTheme useFor="CANCEL" onClick={onToggle} />
      </ContainerButton>

      {openTel && (
        <TelEdit
          open={openTel}
          onToggle={onToggleTel}
          initialValues={initialValues}
          onFetch={onFetch}
          onCloseAll={onCloseAll}
        />
      )}
    </ModalTheme>
  );
};
