import { useMutation } from '@tanstack/react-query';
import { POST, POST_CANCEL_MEMBER } from '../../../../services';
import { openAlert } from '../../../../components/alert/useAlert';

export const useDeleteMember = ({ onSuccessCallBack }) =>
  useMutation({
    mutationFn: async ({ orderId, remark }) => {
      const response = await POST(POST_CANCEL_MEMBER(), {
        orderId,
        remark,
      });

      if (onSuccessCallBack) onSuccessCallBack(response);

      return response;
    },
    onSuccess: () => {
      openAlert({
        model: 'message',
        type: 'success',
        message: 'ลบข้อมูลสำเร็จ',
      });
    },
  });
