import React from 'react';
import { ResponsivePageLayout } from '@/components/page-layout/ResponsivePageLayout';
import { useGetCategoryQuery } from './hooks/useGetCategory';
import { columns } from './columns';
import { CardTheme, TextLarge, TextSmall } from '../../../components';

export const GroupProduct = () => {
  // ------------------------ hooks state -------------------------
  const { data } = useGetCategoryQuery();

  return (
    <CardTheme>
      <TextLarge text="จัดกลุ่มสินค้า"></TextLarge>
      <ResponsivePageLayout
        tableLayout={{
          columns: columns(),
          dataSource: data?.items || [],
        }}
      />
    </CardTheme>
  );
};
