export const FORM_ADD_MANAGEMENT = ({ categoryList, brandList, productList, modelList }) => {
  const span = 24;
  return [
    {
      name: 'categoryId',
      label: 'ชื่อหมวดหมู่',
      type: 'DROPDOWN',
      properties: {
        options: categoryList || [],
      },
      rules: {
        required: true,
      },
      placeholder: 'กรุณาเลือกชื่อหมวดหมู่',
      span,
    },
    {
      name: 'mobileBrandId',
      label: 'ชื่อแบรนด์',
      type: 'DROPDOWN',
      properties: {
        options: brandList || [],
      },
      rules: {
        required: true,
      },
      placeholder: 'กรุณาเลือกชื่อแบรนด์',
      span,
    },
    {
      name: 'mobileModelId',
      label: 'ชื่อรุ่นสินค้า',
      type: 'DROPDOWN',
      properties: {
        options: modelList || [],
      },
      rules: {
        required: true,
      },
      placeholder: 'กรุณาเลือกชื่อรุ่นสินค้า',
      span,
    },
    {
      name: 'productId',
      label: 'ชื่อสินค้า',
      type: 'DROPDOWN',
      properties: {
        options: productList || [],
      },
      rules: {
        required: true,
      },
      placeholder: 'กรุณาเลือกชื่อสินค้า',
      span,
    },
    {
      name: 'itemCode',
      label: 'Code g',
      type: 'INPUT',
      span,
      rules: {
        required: true,
      },
      placeholder: 'กรุณากรอกเลขคำสั่งซื้อ',
    },
    {
      name: 'warrantyPeriod',
      label: 'วันรับประกัน',
      type: 'INPUT',
      span,
      rules: {
        required: true,
      },
      placeholder: 'กรุณากรอกวันรับประกัน',
    },
  ];
};
