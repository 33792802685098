import { useQuery } from '@tanstack/react-query';
import {
  GET,
  GET_CATEGORY_LIST,
  GET_CHANNEL_BUYS,
  GET_CHANNEL_MEMBER,
  GET_CLAIM_PROCESS,
  GET_CUSTOMER_GROUPS,
  GET_STORE_TYPE,
  GET_ORDER_STATUS,
  GET_PROMOTIONS_TYPE,
  GET_MOBILE_BRAND,
  GET_MODEL_BY_ID,
  GET_PRODUCT_LIST,
  GET_PRODUCT_TYPE,
  GET_SHIPPING_SELF,
  GET_WARRANTY_TYPES,
  GET_USER_MANAGE,
  GET_PRODUCT_GROUP_CODE,
} from '..';
import { serviceOptions } from '../../functions/serviceOption';

export const channelBuyQuery = () =>
  useQuery({
    queryKey: ['channelBuyQuery'],
    queryFn: async () => {
      const response = await GET(GET_CHANNEL_BUYS());
      return serviceOptions('CHANNEL_BUY', response.result.items);
    },
  });

export const warrantyTypesQuery = () =>
  useQuery({
    queryKey: ['warrantyTypesQuery'],
    queryFn: async () => {
      const response = await GET(GET_WARRANTY_TYPES());
      return serviceOptions('WARRANTY_TYPES', response.result.items);
    },
  });

export const processStatusQuery = () =>
  useQuery({
    queryKey: ['processStatusQuery'],
    queryFn: async () => {
      const response = await GET(GET_CLAIM_PROCESS());
      return serviceOptions('PROCESS_STATUS', response.result.items);
    },
  });

export const customerGroupsQuery = () =>
  useQuery({
    queryKey: ['customerGroupsQuery'],
    queryFn: async () => {
      const response = await GET(GET_CUSTOMER_GROUPS());
      return serviceOptions('CUSTOMER_GROUP', response.result.items);
    },
  });

export const storeTypeQuery = () =>
  useQuery({
    queryKey: ['storeTypeQuery'],
    queryFn: async () => {
      const response = await GET(GET_STORE_TYPE());
      return serviceOptions('STORE_TYPE', response.result.items);
    },
  });

export const promotionsTypeQuery = () =>
  useQuery({
    queryKey: ['promotionsTypeQuery'],
    queryFn: async () => {
      const response = await GET(GET_PROMOTIONS_TYPE());
      return serviceOptions('PROMOTIONS_TYPE', response.result);
    },
  });

export const channelMemberQuery = () =>
  useQuery({
    queryKey: ['channelMemberQuery'],
    queryFn: async () => {
      const response = await GET(GET_CHANNEL_MEMBER());
      return serviceOptions('CHENNEL_MEMBER', response.result.items);
    },
  });

export const orderStatusQuery = () =>
  useQuery({
    queryKey: ['orderStatusQuery'],
    queryFn: async () => {
      const response = await GET(GET_ORDER_STATUS());
      return serviceOptions('ORDER_STATUS', response.result.items);
    },
  });

export const userQuery = () =>
  useQuery({
    queryKey: ['userQuery'],
    queryFn: async () => {
      const response = await GET(GET_USER_MANAGE({}));
      return serviceOptions('USER', response.result.items);
    },
  });

export const mobileBrandQuery = () =>
  useQuery({
    queryKey: ['mobileBrandQuery'],
    queryFn: async () => {
      const response = await GET(GET_MOBILE_BRAND());
      return serviceOptions('MOBILE_BRAND', response.result.items);
    },
  });

export const productTypeQuery = () =>
  useQuery({
    queryKey: ['productTypeQuery'],
    queryFn: async () => {
      const response = await GET(GET_PRODUCT_TYPE());
      return serviceOptions('PRODUCT_TYPE', response.result.items);
    },
  });

export const shippingSelfQuery = () =>
  useQuery({
    queryKey: ['shippingSelfQuery'],
    queryFn: async () => {
      const response = await GET(GET_SHIPPING_SELF());
      return serviceOptions('SHIPPING_SELF', response.result.items);
    },
  });

export const categoryQuery = () =>
  useQuery({
    queryKey: ['categoryQuery'],
    queryFn: async () => {
      const response = await GET(GET_CATEGORY_LIST());
      return serviceOptions('CATEGORY_LIST', response.result.items);
    },
  });

export const productQuery = () =>
  useQuery({
    queryKey: ['productQuery'],
    queryFn: async () => {
      const response = await GET(GET_PRODUCT_LIST());
      return serviceOptions('PRODUCT_LIST', response.result.items);
    },
  });

export const brandQuery = () =>
  useQuery({
    queryKey: ['brandQuery'],
    queryFn: async () => {
      const response = await GET(GET_MOBILE_BRAND());
      return serviceOptions('BRAND_LIST', response.result.items);
    },
  });

export const modelQuery = (mobileBrandId) =>
  useQuery({
    queryKey: ['modelQuery', `${mobileBrandId}`],
    queryFn: async () => {
      const response = await GET(GET_MODEL_BY_ID(mobileBrandId || ''));
      return serviceOptions('MODEL_LIST', response.result.items);
    },
  });

export const mobileBrandCodeQuery = () =>
  useQuery({
    queryKey: ['mobileBrandCodeQuery'],
    queryFn: async () => {
      const response = await GET(GET_MOBILE_BRAND());
      return serviceOptions('MOBILE_BRAND_CODE', response.result.items);
    },
  });

export const customerGroupsUserQuery = () =>
  useQuery({
    queryKey: ['customerGroupsUserQuery'],
    queryFn: async () => {
      const response = await GET(GET_CUSTOMER_GROUPS());
      return serviceOptions('CUSTOMER_GROUP_USER', response.result.items);
    },
  });

export const categoryCodeQuery = () =>
  useQuery({
    queryKey: ['categoryCodeQuery'],
    queryFn: async () => {
      const response = await GET(GET_CATEGORY_LIST());
      return serviceOptions('CATEGORY_CODE', response.result.items);
    },
  });

export const productGroupCodeQuery = () =>
  useQuery({
    queryKey: ['productGroupCodeQuery'],
    queryFn: async () => {
      const response = await GET(GET_PRODUCT_GROUP_CODE());
      return serviceOptions('PRODUCT_CODE', response.result.items);
    },
  });
