import React from 'react';
import { CardTheme } from '../../card/index.jsx';
import TableComponent from '../../table/index.jsx';
import { useResponsive } from '../../../hooks/useResponsive.js';
import { CardListComponent } from '../../card-list/index.jsx';

export const ResponsiveTableLayout = ({
  style,
  columns,
  dataSource,
  rowSelection,
  extraContent,
  hasPagination,
  rowKey,
  components,
  ...rest
}) => {
  const { isMobile } = useResponsive();
  return isMobile ? (
    <>
      {extraContent}
      <CardListComponent columns={columns} dataSource={dataSource} {...rest} />
    </>
  ) : (
    <CardTheme
      style={{ marginTop: '2%', ...style }}
      content={
        <>
          {extraContent}
          <TableComponent
            rowKey={rowKey}
            columns={columns}
            dataSource={dataSource}
            hasPagination={hasPagination || false}
            rowSelection={rowSelection}
            components={components}
            {...rest}
          />
        </>
      }
    />
  );
};
