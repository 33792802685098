import { Row, Col, Checkbox, Tag } from "antd"
import { CardTheme } from "../../../components/card"
import { TextCustom, TextSmall } from "../../../components/text"

export const SummaryCard = ({cardItems}) => {
  return (
    <Row gutter={[8, 8]}>
      {cardItems.map((el, i) =>
        <Col key={`${el.label}_${i + 1}`} xs={{ span: 23, offset: 1, }} sm={{ span: 23, offset: 1, }} lg={{ span: 7, offset: 1, }}>
          <CardTheme style={{ boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px' }}>
            <Row>
              <Col xs={{ span: 12, }} sm={{ span: 12 }} lg={{ span: 12 }}>
                <TextSmall text={el.label} />
              </Col>
              <Col xs={{ span: 12 }} sm={{ span: 12 }} lg={{ span: 12 }} style={{ textAlignLast: 'right' }}>
                {el.icon}
              </Col>
            </Row>
            <Row>
              <Col xs={{ span: 24 }} lg={{ span: 24 }} style={{ display: 'flex', justifyContent: 'center' }}>
                <TextCustom text={el.value} size="30px" bold />
              </Col>
            </Row>
          </CardTheme>
        </Col>
      )}
    </Row>
  )
}

export const ChecklistCard = () => {
  return (
    <Row gutter={[8, 8]}>
        <Col xs={{ span: 23, offset: 1, }} sm={{ span: 23, offset: 1, }} lg={{ span: 23, offset: 1, }}>
          <CardTheme title="Task today" style={{ marginTop: 20, boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px' }}>
            <Row style={{ marginTop: 10 }}>
              <Col xs={{ span: 12, }} sm={{ span: 12 }} lg={{ span: 12 }}>
                <Checkbox>
                  Create add stock UI
                </Checkbox>
              </Col>
              <Col xs={{ span: 12 }} sm={{ span: 12 }} lg={{ span: 12 }} style={{ textAlignLast: 'right' }}>
                <Tag color="purple">stock web</Tag>
              </Col>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Col xs={{ span: 12, }} sm={{ span: 12 }} lg={{ span: 12 }}>
                <Checkbox>
                  fix bug table
                </Checkbox>
              </Col>
              <Col xs={{ span: 12 }} sm={{ span: 12 }} lg={{ span: 12 }} style={{ textAlignLast: 'right' }}>
                <Tag color="purple">stock web</Tag>
              </Col>
            </Row>
          </CardTheme>
        </Col>
      </Row>
  )
}