import {
  createColumnText,
  createColumnDate,
  widthOptions,
  createColumnNumber,
  formatNumber,
} from '@/components/table/function/createColumn';

export const columns = () => {
  return [
    createColumnNumber('ลำดับ', 'rowNumber', widthOptions(80)),
    createColumnText('ชื่อลูกค้า', 'fullName', widthOptions(150)),
    createColumnText('เบอร์ติดต่อ', 'telephone', widthOptions(120)),
    createColumnText('สถานที่', 'channelUsed', widthOptions(120)),
    createColumnNumber(
      'จำนวน',
      'sumQty',
      widthOptions(100, {
        render: (value) => formatNumber(value),
      }),
    ),
    createColumnDate('วันใช้บริการล่าสุด', 'lastUsedDate', widthOptions(170)),
  ];
};
