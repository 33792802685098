import { StyledContainer } from '../../components/container/styled';
import WrapperComponent from '../../components/hoc/Wrapper-component';
import { useFetchApi } from './hook/useFetchApi';
import { useFormState } from './hook/useFormState';
import { ChecklistCard, SummaryCard } from './view/card';
import { SummaryToDoGraph } from './view/graph';

const HomeScene = () => {

  const { config } = useFetchApi();
  const { cardItems } = useFormState();

  return (
    <StyledContainer>
      <SummaryCard cardItems={cardItems} />
      <ChecklistCard />
      <SummaryToDoGraph config={config} />
    </StyledContainer>
  );
};

export default WrapperComponent('HomeScene')(HomeScene);
