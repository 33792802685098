import React from 'react';
import { useForm } from 'react-hook-form';
import { LoginCard, ChangePasswordForm } from './view/changePassword';
import { ImageLogin } from './view/changePassword';
import { useChangePassword } from './hooks/useChangePassword';

const ChangePasswordScene = () => {
  const { control, setValue, getValues, handleSubmit } = useForm({});

  const changePasswordMutation = useChangePassword();

  const onSubmit = (data) => {
    changePasswordMutation.mutate(data);
  };

  return (
    <LoginCard>
      <ImageLogin />
      <ChangePasswordForm {...{ control, setValue, getValues, onSubmit, handleSubmit }} />
    </LoginCard>
  );
};
export default ChangePasswordScene;
