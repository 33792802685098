import React from 'react';
import { Footer } from 'antd/es/layout/layout';
import { VERSION } from '../../resources/constant';
import { color } from '../../resources';

const FooterLayout = () => {
  return <Footer style={{ textAlign: 'center', backgroundColor: color.blackTheme, color: color.orangeTheme }}>ZettaSoft @2024 version {VERSION}</Footer>;
};

export default FooterLayout;
