import React from 'react';
import { ModalTheme } from '../../../../components/modal/index.jsx';
import { FORM_ADD_USER } from '../forms/index.js';
import { useForm } from 'react-hook-form';
import { ButtonTheme, RenderForm } from '../../../../components/index.js';
import { ContainerButton } from '../../../../styles/global-style.js';
import { useInsertMember } from '../hooks/useInsertMember.js';
import {
  channelMemberQuery,
  promotionsTypeQuery,
} from '../../../../services/react-query/optionMasterQuery.js';
import { handleSubmitForm } from '../func/handleSubmitForm.js';

export const AddModal = ({ open, onToggle, onFetch }) => {
  const { control, setValue, getValues, handleSubmit } = useForm();

  const { mutate: insertMember } = useInsertMember({ onSuccessCallBack: onFetch });

  const { data: promotionsType } = promotionsTypeQuery();
  const { data: channelMember } = channelMemberQuery();

  const onSubmit = (values) => {
    handleSubmitForm(values, insertMember);
    onToggle({ defaultValues: '' });
  };

  return (
    <ModalTheme title=" เพิ่มคำสั่งซื้อ" open={open} onToggle={onToggle}>
      <RenderForm
        control={control}
        forms={FORM_ADD_USER({ promotionsType, channelMember })}
        setValue={setValue}
        getValues={getValues}
      />
      <ContainerButton right>
        <ButtonTheme useFor="SUBMIT" onClick={handleSubmit(onSubmit)} />
        <ButtonTheme useFor="CANCEL" onClick={onToggle} />
      </ContainerButton>
    </ModalTheme>
  );
};
