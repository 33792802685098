import { useMutation } from '@tanstack/react-query';
import { POST, POST_DESTROT_STORE } from '../../../../services';
import { openAlert } from '../../../../components/alert/useAlert';

export const useDeleteStore = ({ onSuccessCallBack }) =>
  useMutation({
    mutationFn: async ({ storeId, remark }) => {
      const response = await POST(POST_DESTROT_STORE(), {
        storeId,
        remark,
      });

      if (onSuccessCallBack) onSuccessCallBack(response);

      return response;
    },
    onSuccess: () => {
      openAlert({ model: 'message', type: 'success', message: 'ลบข้อมูลร้านค้าสำเร็จ' });
    },
  });
