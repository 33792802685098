import React from 'react';
import { columns } from './columns/index';
import { StyledContainer } from './styles';
import { TextLarge, CardTheme, ResponsivePageLayout } from '@/components/index';
import { expanded } from './forms/expan';
import { useGetPromotion } from './hooks/useGetPromotion';
import { useEffect } from 'preact/hooks';

export const Privileges = () => {
  const { dataSource, loading: isLoading, onSearchFull } = useGetPromotion();

  useEffect(() => {
    onSearchFull();
  }, []);

  return (
    <StyledContainer>
      <TextLarge text="สิทธิพิเศษ" />
      <CardTheme>
        <ResponsivePageLayout
          tableLayout={{
            columns: columns(),
            dataSource,
            loading: isLoading,
            hasPagination: true,
            rowKey: 'rowNumber',
            expandable: {
              expandedRowRender: (record) => expanded(record),
            },
          }}
        />
      </CardTheme>
    </StyledContainer>
  );
};
