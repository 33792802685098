import React, { useEffect } from 'react';
import { ButtonTheme, ModalTheme, RenderForm } from '../../../../components';
import { useForm } from 'react-hook-form';
import { ContainerButton } from '../../../../styles/global-style';
import { FORM_EDIT_MODEL, useFormState } from '../forms';
import { useEditModel } from '../hooks/useEditModel';
import { mobileBrandQuery } from '../../../../services/react-query/optionMasterQuery';
import { UpdateModel } from '../../main/AllApi';
import { allOptions } from '../../../../functions/optionAll';

export const EditModel = ({ open, onToggle, onFetch, initialValues }) => {
  const { defaultValues } = useFormState(initialValues);
  const { control, setValue, getValues, handleSubmit } = useForm({ defaultValues });
  const { mutate: onEditBrand } = useEditModel({
    onSuccessCallBack: onFetch,
  });

  const { data: mobileBrandList } = mobileBrandQuery();
  const { statusOptions } = allOptions();

  useEffect(() => {
    if (!initialValues) {
      return;
    }
    Object.keys(initialValues).forEach((key) => {
      const temp =
        key === 'mobileModelImageUrl' && initialValues[key]
          ? [
              {
                uid: '-1',
                name: 'image.png',
                status: 'done',
                url: initialValues[key],
              },
            ]
          : initialValues[key];

      setValue(key, temp);
    });
  }, [initialValues, setValue]);

  const onSubmit = (values) => {
    const formData = UpdateModel(values);

    onEditBrand(formData);
    onToggle({ defaultValues: '' });
  };

  return (
    <ModalTheme title="แก้ไขรุ่นมือถือ" open={open} onToggle={onToggle}>
      <RenderForm
        control={control}
        setValue={setValue}
        getValues={getValues}
        forms={FORM_EDIT_MODEL({ mobileBrandList, statusOptions })}
      />
      <ContainerButton right>
        <ButtonTheme useFor="SUBMIT" onClick={handleSubmit(onSubmit)} />
        <ButtonTheme useFor="CANCEL" onClick={onToggle} />
      </ContainerButton>
    </ModalTheme>
  );
};
