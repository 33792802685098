import { useMutation } from '@tanstack/react-query';
import { POST, POST_UPDATE_PRODUCT } from '../../../../services';
import { openAlert } from '../../../../components/alert/useAlert';
import { updateProduct } from '../main';

export const useUpdateProduct = () =>
  useMutation({
    mutationFn: async ({ ...values }) => {
      const payload = updateProduct(values);
      const response = await POST(POST_UPDATE_PRODUCT(), payload);

      return response.result;
    },
    onSuccess: () => {
      openAlert({
        model: 'message',
        type: 'success',
        message: 'แก้ไขข้อมูลสำเร็จ',
      });
    },
  });
