export const FORM_SEARCH = ({ userList }) => {
  return [
    {
      name: 'search',
      label: 'รหัส/ชื่อพนักงาน',
      type: 'DROPDOWN',
      properties: {
        options: userList || [],
      },
      rules: {
        required: true,
      },
      span: 8,
    },
  ];
};

export const searchDefaultValue = {
  search: '',
};
