import { useMemo } from 'preact/hooks';
import { serviceOptions } from '../../../../functions/serviceOption';
import { handleBreakdownOptions } from '../../../../functions/breakdownOptionsHandle';

export const useOptions = ({ watch }) => {
  const {
    addresses,
    warranties,
    province,
    district,
    provinceId,
    subDistrict,
    address,
    warrantyId,
  } = watch();

  const productOptions = useMemo(() => serviceOptions('CLAIM_PRODUCT', warranties), [warranties]);
  const addressOptions = useMemo(() => serviceOptions('ADDRESS', addresses), [addresses]);

  const breakdownOptions = useMemo(
    () => handleBreakdownOptions(warranties, warrantyId),
    [warrantyId, warranties],
  );

  const addressDataOptions = address;

  const provinceOptions = useMemo(() => serviceOptions('PROVINCE', province), [province]);
  const districtOptions = useMemo(
    () =>
      provinceId
        ? serviceOptions(
            'DISTRICT',
            district?.filter((el) => el?.provinceId === provinceId),
          )
        : [],
    [provinceId],
  );
  const subDistrictOptions = useMemo(
    () =>
      provinceId
        ? serviceOptions(
            'SUBDISTRICT',
            subDistrict?.filter((el) => el?.provinceId === provinceId),
          )
        : [],
    [provinceId, subDistrict],
  );

  return {
    productOptions,
    addressOptions,
    breakdownOptions,
    provinceOptions,
    districtOptions,
    subDistrictOptions,
    addressDataOptions,
  };
};
