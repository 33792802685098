import {
  createColumnButtonArr,
  createColumnText,
  widthOptions,
} from '@/components/table/function/createColumn';
import { ROUTES_PATH } from '../../../../resources/routes-name';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { ButtonTheme } from '../../../../components';
import { Popconfirm } from 'antd';

export const columns = ({ onSubmitDeleteManagement }) => {
  const history = useHistory();
  return [
    createColumnText('Item Code', 'itemCode', widthOptions(200)),
    createColumnText('รหัสหมวดหมู่', 'categoryCode', widthOptions(150)),
    createColumnText('ชื่อหมวดหมู่', 'categoryName', widthOptions(150)),
    createColumnText('รหัสแบรนด์', 'mobileBrandCode', widthOptions(150)),
    createColumnText('ชื่อแบรนด์', 'mobileBrandNameTh', widthOptions(150)),
    createColumnText('รหัสรุ่น', 'mobileModelCode', widthOptions(150)),
    createColumnText('ชื่อรุ่น', 'mobileModelNameTh', widthOptions(150)),
    createColumnText('รหัสสินค้า', 'productCode', widthOptions(150)),
    createColumnText('ชื่อสินค้า', 'productNameTh', widthOptions(200)),
    createColumnText('ระยะเวลาในการรับประกัน', 'warrantyPeriod', widthOptions(150)),
    createColumnButtonArr('จัดการ', 'rowNo', {
      others: {
        fixed: 'right',
        width: 250,
      },
      buttonArr: [
        {
          useFor: 'EDIT2',
          title: 'แก้ไข',
          onClick: ({ record }) => {
            history.push({
              pathname: ROUTES_PATH.ROUTE_EDIT_MANAGEMENT.PATH,
              state: { mapProductId: record.mapProductId },
            });
          },
        },
        {
          useFor: 'DELETE',
          title: 'ลบ',
          render: (text, record) => (
            <Popconfirm
              title="คุณแน่ใจว่าต้องการลบข้อมูลนี้หรือไม่?"
              onConfirm={() => onSubmitDeleteManagement(record)}
              okText="ใช่"
              cancelText="ไม่"
            >
              <ButtonTheme useFor="DELETE" title="ลบ" />
            </Popconfirm>
          ),
        },
      ],
    }),
  ];
};
