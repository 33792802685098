import {
  createColumnNumber,
  createColumnText,
  widthOptions,
  formatNumber,
} from '@/components/table/function/createColumn';

export const columns = () => {
  return [
    createColumnNumber('ลำดับ', 'rowNumber', widthOptions(80)),
    createColumnText('ชื่อคูปอง', 'title', widthOptions(150)),
    createColumnText('ประเภทคูปอง', 'couponType', widthOptions(120)),
    createColumnNumber(
      'จำนวนทั้งหมด',
      'sumQty',
      widthOptions(150, {
        render: (value) => formatNumber(value),
      }),
    ),
    createColumnNumber(
      'จำนวนที่ใช้ไป',
      'sumUsed',
      widthOptions(150, {
        render: (value) => formatNumber(value),
      }),
    ),
  ];
};
