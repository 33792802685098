import * as type from './types';

const initialState = {
  users: [],
  loading: false,
};

export default function authenReducer(state = initialState, action) {
  switch (action.type) {
    case type.AUTHEN_LOGIN_REQ:
      return {
        ...state,
        loading: true,
      };
    case type.AUTHEN_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.users,
      };
    case type.AUTHEN_LOGIN_FAIL:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    case type.AUTHEN_LOGOUT_REQ:
      return {
        ...state,
        loading: true,
      };
    case type.AUTHEN_LOGOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        users: [],
      };
    case type.AUTHEN_LOGOUT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    case type.AUTHEN_LOGIN_GOOGLE_REQ:
      return {
        ...state,
        loading: true,
      };
    case type.AUTHEN_LOGIN_GOOGLE_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.users,
      };
    case type.AUTHEN_LOGIN_GOOGLE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    case type.AUTHEN_GET_PROFILE_REQ:
      return {
        ...state,
        loading: true,
      };
    case type.AUTHEN_GET_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.users,
        menu: action.menu,
      };
    case type.AUTHEN_GET_PROFILE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    default:
      return state;
  }
}
