import React from 'react';
import { useState, useEffect } from 'preact/hooks';
import { useGetWarrantyView } from './hooks/useGetWarrantyView';
import { useForm } from 'react-hook-form';
import { StyledContainer } from '../registration-product/styles';
import { ButtonTheme, CardTheme, RenderForm, TextLarge } from '../../../components';
import { Col, Row, Spin } from 'antd';
import { FORM_PRODUCT_REGISTRATION_WARRANTY, FORM_USER_REGISTRATION_WARRANTY } from './forms';
import { navigateTo } from '../../../utils/navigation';
import { ContainerButton } from '../../../styles/global-style';
import { ROUTES_PATH } from '../../../resources/routes-name';

export const RegistrationView = () => {
  const { control, setValue, getValues, reset } = useForm();
  const [state, setState] = useState();

  const queryParams = new URLSearchParams(window.location.search);
  const warrantyCode = queryParams.get('code');

  useEffect(() => {
    if (warrantyCode) {
      setState({ warrantyCode });
    }
  }, [warrantyCode]);

  const { loading: isLoadingWarrantyView } = useGetWarrantyView({
    warrantyCode: state?.warrantyCode,
    reset,
  });

  const goBack = () => {
    navigateTo({
      pathname: ROUTES_PATH.ROUTE_REGISTRATION.PATH,
      state: getValues(),
    });
  };

  return (
    <Spin spinning={isLoadingWarrantyView}>
      <StyledContainer>
        <TextLarge text="ดูข้อมูลรับประกัน" />
        <Row gutter={16}>
          <Col span={14}>
            <CardTheme>
              <TextLarge text="ลูกค้าลงทะเบียนรับประกัน" />
              <RenderForm
                control={control}
                setValue={setValue}
                getValues={getValues}
                forms={FORM_USER_REGISTRATION_WARRANTY()}
              />
            </CardTheme>
          </Col>
          <Col span={10}>
            <CardTheme>
              <TextLarge text="สินค้าลงทะเบียนรับประกัน" />
              <RenderForm
                control={control}
                setValue={setValue}
                getValues={getValues}
                forms={FORM_PRODUCT_REGISTRATION_WARRANTY()}
              />
            </CardTheme>
          </Col>
        </Row>
        <ContainerButton left>
          <ButtonTheme useFor="BACK" title="ย้อนกลับ" onClick={goBack} />
        </ContainerButton>
      </StyledContainer>
    </Spin>
  );
};
