import { convertYearInputToPayload } from '../../../../functions/convert';

export const updateTracking = (value) => {
  return {
    claimId: value.claimId,
    shippingId: value.shippingId,
    trackingNumber: value.trackingNumber,
    shippingDate: convertYearInputToPayload(value.shippingDate),
  };
};
