import { useMutation } from '@tanstack/react-query';
import { POST, POST_CANCEL_WARRANTY } from '../../../../services';
import { openAlert } from '../../../../components/alert/useAlert';

export const useDeleteWarrantyQuery = ({ onSuccessCallBack }) =>
  useMutation({
    mutationFn: async ({ warrantyId, remark }) => {
      const response = await POST(POST_CANCEL_WARRANTY(), {
        warrantyId,
        remark,
      });

      if (onSuccessCallBack) onSuccessCallBack(response);

      return response;
    },
    onSuccess: () => {
      openAlert({ model: 'message', type: 'success', message: 'ลบข้อมูลรายการลงทะเบียนสำเร็จ' });
    },
  });
