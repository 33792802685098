import { useEffect } from 'react';

export const useSelectedProvince = (selectedProvinceId, province, setValue) => {
  useEffect(() => {
    if (!selectedProvinceId) {
      return;
    }
    const selectedProvince = province.find(
      (province) => province.provinceId === selectedProvinceId,
    );
    if (!selectedProvince) {
      return;
    }
    setValue('district', selectedProvince.district[0]?.districtId);
    setValue('subDistrict', selectedProvince.subDistrict[0]?.subDistrictId);
  }, [selectedProvinceId, province, setValue]);
};
