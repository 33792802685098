import React, { useState } from 'react';
import { TextLarge } from '../../../components';
import { ContainerButton } from '../../../styles/global-style';
import { ResponsivePageLayout } from '../../../components/page-layout/ResponsivePageLayout';
import { Icon } from '../../../resources';
import { useForm } from 'react-hook-form';
import { StyledContainer } from '../../scan-deliver/styles';
import { useGetMobile } from './hooks/useGetMobile';
import { columns } from './columns';
import { useModal } from '../../../components/modal/hooks/useModal';
import { FORM_SEARCH, searchDefaultValues } from './forms';
import { EditBrand } from './modals/EditBrand';
import { SpaceBetween } from '../../manage-data/user-info/styles';
import ButtonExcelEmbedded from '../../../components/report-excel/ButtonExcelEmbedded';

export const MobileBrandList = () => {
  const [modalEdit, setModalEdit] = useState(null);
  const { open: openEdit, onToggle: onToggleEdit } = useModal();

  const formSearchObj = useForm({ defaultValues: searchDefaultValues });
  const formSearch = FORM_SEARCH();

  const {
    dataSource,
    onFetch,
    loading: isLoadingMobileBrandList,
  } = useGetMobile({
    handleSubmit: formSearchObj.handleSubmit,
  });

  const onEdit = (record) => {
    setModalEdit(record);
    onToggleEdit({ defaultValues: record });
  };

  return (
    <div>
      <StyledContainer>
        <TextLarge>แบรนด์มือถือ</TextLarge>
        <ResponsivePageLayout
          searchLayout={{
            title: 'ค้นหาแบรนด์มือถือ',
            formSearch,
            useFormParam: formSearchObj,
            icon: <Icon.todo />,
            ignoreDateEmpty: true,
            onSearch: onFetch,
          }}
          tableLayout={{
            columns: columns({ onEdit }),
            dataSource,
            loading: isLoadingMobileBrandList,
            hasPagination: true,
            extraContent: (
              <SpaceBetween>
                <ContainerButton right>
                  <ButtonExcelEmbedded
                    title="Export"
                    header={columns({ onEdit })}
                    data={dataSource}
                    filename="รายงานแบรนด์มือถือ"
                  />
                </ContainerButton>
              </SpaceBetween>
            ),
          }}
        />
        {openEdit && (
          <EditBrand
            open={openEdit}
            onToggle={onToggleEdit}
            onFetch={onFetch}
            initialValues={modalEdit}
          />
        )}
      </StyledContainer>
    </div>
  );
};
