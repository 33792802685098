import React from 'react';
import { ModalTheme } from '../../../../components/modal/index.jsx';
import { FORM_REMARK } from '../forms/index.js';
import { useForm } from 'react-hook-form';
import { useCallback } from 'preact/hooks';
import { ButtonTheme, RenderForm, TextLarge, TextSmall } from '../../../../components/index.js';
import { ContainerButton } from '../../../../styles/global-style.js';
import { useSendRemark } from '../hooks/useSendRemark.js';

export const Remark = ({ open, onToggle, initialValues }) => {
  const { control, setValue, handleSubmit } = useForm();
  const { mutate: sendRemark, isLoading } = useSendRemark();

  const onSubmitSuccess = useCallback(
    (formData) => {
      const payload = {
        claimId: initialValues.claimId,
        remark: formData.remark,
      };
      sendRemark(payload, {
        onSuccess: () => {
          onToggle({ defaultValues: '' });
        },
      });
    },
    [sendRemark, initialValues.claimId, onToggle],
  );

  return (
    <ModalTheme title=" " open={open} onToggle={onToggle} initialValues={initialValues}>
      <div style={{ textAlign: 'center', width: '100%' }}>
        <TextLarge text="หมายเหตุ" />
      </div>
      <div>
        <TextSmall text={'ชื่อ : ' + initialValues.fullName} />
        <TextSmall text={'เลขที่เคลม : ' + initialValues.claimCode} />
      </div>
      <RenderForm control={control} forms={FORM_REMARK()} setValue={setValue} loading={isLoading} />
      <ContainerButton right>
        <ButtonTheme useFor="SUBMIT" onClick={handleSubmit(onSubmitSuccess)} />
        <ButtonTheme useFor="CANCEL" onClick={onToggle} />
      </ContainerButton>
    </ModalTheme>
  );
};
