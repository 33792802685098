import { fromPairs } from 'lodash';

export const InsertProduct = (value) => {
  return {
    productImage: value.productImage ? value.productImage.originFileObj : null,
    productCode: value.productCode,
    productType: value.productType,
    productNameTh: value.productNameTh,
    productNameEn: value.productNameEn,
  };
};

export const UpdateProduct = (values) => {
  const formData = new FormData();

  formData.append('productId', values.productId);
  formData.append('productCode', values.productCode);
  formData.append('productType', values.productType);
  formData.append('productNameTh', values.productNameTh);
  formData.append('productNameEn', values.productNameEn);

  if (values.productImageUrl && values.productImageUrl.length > 0) {
    const file = values.productImageUrl[0];
    if (file.originFileObj) {
      formData.append('productImage', file.originFileObj);
    } else if (file.url) {
      formData.append('productImageUrl', file.url);
    }
  }

  return formData;
};

export const InsertCategory = (value) => {
  return {
    categoryCode: value.categoryCode,
    categoryName: value.categoryName,
    detail1: value.detail1,
    detail2: value.detail2,
    remark: value.remark,
    defaultWarrantyPeriod: parseInt(value.defaultWarrantyPeriod, 10) || 0,
    defaultClaimQty: parseInt(value.defaultClaimQty, 10) || 0,
  };
};

export const UpdateCategory = (value) => {
  return {
    categoryId: value.categoryId,
    categoryCode: value.categoryCode,
    categoryName: value.categoryName,
    detail1: value.detail1,
    detail2: value.detail2,
    remark: value.remark,
    defaultWarrantyPeriod: parseInt(value.defaultWarrantyPeriod, 10) || 0,
    defaultClaimQty: parseInt(value.defaultClaimQty, 10) || 0,
  };
};

export const InsertBrand = (value) => {
  return {
    mobileBrandImage: value.mobileBrandImage ? value.mobileBrandImage.originFileObj : null,
    mobileBrandCode: value.mobileBrandCode,
    mobileBrandNameTh: value.mobileBrandNameTh,
    mobileBrandNameEn: value.mobileBrandNameEn,
  };
};

export const UpdateBrand = (values) => {
  const formData = new FormData();

  formData.append('mobileBrandId', values.mobileBrandId);
  formData.append('mobileBrandCode', values.mobileBrandCode);
  formData.append('seqNo', values.seqNo);
  formData.append('mobileBrandNameTh', values.mobileBrandNameTh);
  formData.append('mobileBrandNameEn', values.mobileBrandNameEn);
  formData.append('mobileBrandStatus', values.mobileBrandStatus);

  if (values.mobileBrandImageUrl && values.mobileBrandImageUrl.length > 0) {
    const file = values.mobileBrandImageUrl[0];
    if (file.originFileObj) {
      formData.append('mobileBrandImage', file.originFileObj);
    } else if (file.url) {
      formData.append('mobileBrandImageUrl', file.url);
    }
  }

  return formData;
};

export const InsetModel = (values) => {
  return {
    mobileModelImage: values.mobileModelImage ? values.mobileModelImage.originFileObj : null,
    mobileBrandId: values.mobileBrandId,
    mobileModelCode: values.mobileModelCode,
    mobileModelNameTh: values.mobileModelNameTh,
    mobileModelNameEn: values.mobileModelNameEn,
  };
};

export const UpdateModel = (values) => {
  const formData = new FormData();

  formData.append('mobileModelId', values.mobileModelId);
  formData.append('mobileModelCode', values.mobileModelCode);
  formData.append('mobileModelNameTh', values.mobileModelNameTh);
  formData.append('mobileModelNameEn', values.mobileModelNameEn);
  formData.append('mobileModelStatus', values.mobileModelStatus);
  formData.append('remark', values.remark);
  formData.append('seqNo', values.seqNo);

  if (values?.mobileModelImageUrl && values?.mobileModelImageUrl?.length > 0) {
    const file = values?.mobileModelImageUrl[0];
    if (file?.originFileObj) {
      formData.append('mobileModelImage', file.originFileObj);
    } else if (file?.url) {
      formData.append('mobileModelImageUrl', file.url);
    }
  }

  return formData;
};
