import { useMutation } from '@tanstack/react-query';
import { changePassword } from './service';
import { POST, POST_CHANGE_PASSWORD } from '../../../../services';
import { openAlert } from '../../../../components/alert/useAlert';
import { navigateTo } from '../../../../utils/navigation';
import { ROUTE_LOGIN_MANUAL } from '../../../../resources/routes-name';

export const useChangePassword = () =>
  useMutation({
    mutationFn: async (...values) => {
      const payload = changePassword(values);
      const response = await POST(POST_CHANGE_PASSWORD(), payload);

      return response.result || '';
    },
    onSuccess: () => {
      openAlert({
        modal: 'message',
        type: 'success',
        message: 'เปลี่ยนรหัสผ่านสำเร็จ',
      });
      setTimeout(() => navigateTo({ pathname: ROUTE_LOGIN_MANUAL }), 1500);
    },
  });
