import { useState } from 'react';

export const useModal = () => {
  const [open, setOpen] = useState(false);
  const [defaultValuesMain, setDefaultValuesMain] = useState({});

  const onToggle = ({ defaultValues }) => {
    setDefaultValuesMain(defaultValues || {});
    setOpen(!open);
  };
  return {
    open,
    onToggle,
    defaultValues: defaultValuesMain,
  };
};
