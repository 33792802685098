import { useMutation } from '@tanstack/react-query';
import { POST } from '../../../../services';
import { openAlert } from '../../../../components/alert/useAlert';

const POST_USER_INFO = '/warranty/abm/api/v2/admin/customers/update/info';

export const useUpdateInfo = ({ onSuccessCallBack }) => {
  return useMutation({
    mutationFn: async ({ customerId, fullName, email, birthday, customerLevel }) => {
      const payload = {
        customerId,
        fullName,
        email,
        birthday,
        customerLevel,
      };
      const response = await POST(POST_USER_INFO, payload);
      if (onSuccessCallBack) onSuccessCallBack(response);

      return response.result;
    },
    onSuccess: () => {
      openAlert({
        model: 'message',
        type: 'success',
        message: 'แก้ไขข้อมูลสำเร็จ',
      });
    },
  });
};
