import { useState, useEffect } from 'preact/hooks';

export const useProvinceOptions = (selectedProvinceId, province, setValue) => {
  const [districtOptions, setDistrictOptions] = useState([]);
  const [subDistrictOptions, setSubDistrictOptions] = useState([]);

  useEffect(() => {
    if (!selectedProvinceId) {
      return;
    }

    const selectedProvince = province.find((p) => p.provinceId === selectedProvinceId);

    if (!selectedProvince || !selectedProvince.district) {
      return;
    }

    const districtOptions = selectedProvince.district.map((district) => ({
      value: district.districtId,
      label: district.districtNameTh,
    }));

    setDistrictOptions(districtOptions);
    setValue('districtId', districtOptions[0]?.value);

    const subDistrictOptions =
      selectedProvince.district[0]?.subDistrict.map((subDistrict) => ({
        value: subDistrict.subDistrictId,
        label: subDistrict.subDistrictNameTh,
      })) || [];

    setSubDistrictOptions(subDistrictOptions);
    setValue('subDistrictId', subDistrictOptions[0]?.value);
  }, [selectedProvinceId, province, setValue]);

  return { districtOptions, subDistrictOptions };
};
