import React, { useEffect } from 'react';
import {
  CardTheme,
  TextLarge,
  RenderForm,
  ButtonTheme,
  TimelineComponent,
} from '../../../components';
import { StyledContainer } from './styles';
import { useForm } from 'react-hook-form';
import { Row, Col, Modal, Spin } from 'antd';
import {
  FORM_CLAIM_DETAIL_PERSONAL,
  FORM_CLAIM_DETAIL_CLAIM,
  FORM_CLAIM_EDIT_DETAIL_PICTURE,
} from './forms';
import { ContainerButton } from '../../../styles/global-style';
import { navigateTo } from '@/utils/navigation/index.js';
import { ROUTES_PATH } from '../../../resources/routes-name';
import { useState } from 'preact/hooks';
import { useGetClaimDetail } from './hooks/useGetClaimDetail';
import { useModal } from '../../../components/modal/hooks/useModal';
import { ModalReject } from './modal/modalReject';
import { useApproveClaim } from './hooks/useApproveClaim';
import { ChangeProductModal } from './modal/changeProduct';
import { color } from '../../../resources';

export const ClaimDetail = () => {
  const { open: openChangeProduct, onToggle: onToggleChangeProduct } = useModal();
  const { control, setValue, getValues } = useForm();
  const [state, setState] = useState();
  const queryParams = new URLSearchParams(window.location.search);
  const claimCode = queryParams.get('code');

  useEffect(() => {
    if (claimCode) {
      setState({ claimCode });
    }
  }, [claimCode]);

  const [claimData, setClaimData] = useState({});
  const [timelineList, setTimelineList] = useState([]);
  const { open: openReject, onToggle: onToggleReject } = useModal();
  const { dataSource, loading: isLoadingClaimDetail } = useGetClaimDetail({
    claimCode: state?.claimCode,
    setValue,
  });
  const [modal, modalContext] = Modal.useModal();

  const { mutate: onSubmitApproveClaim, isPending: isPendingApproveClaim } = useApproveClaim({
    modal,
  });

  useEffect(() => {
    const updateClaimData = () => {
      try {
        if (dataSource?.claim) {
          setClaimData(dataSource?.claim);

          setValue('claimCode', dataSource?.claim?.claimCode);
          setValue('claimId', dataSource?.claim?.claimId);
        }

        if (dataSource?.timelineList) {
          const timeline = dataSource?.timelineList.map((timeline) => {
            return {
              text: timeline?.optionStatusNameTh,
              color:
                timeline?.optionStatusCode == dataSource?.claim?.claimStatus ? 'green' : 'black',
            };
          });
          setTimelineList(timeline);
        }
      } catch (error) {}
    };

    updateClaimData();
  }, [dataSource, setValue]);

  const onModalReject = (record) => {
    setClaimData(record);
    onToggleReject({ record });
  };

  const onSubmitApprove = () => {
    const claimId = getValues('claimId');
    if (claimId) {
      onSubmitApproveClaim({ claimIdList: [claimId] });
    }
  };

  const goBack = () => {
    navigateTo({
      pathname: ROUTES_PATH.ROUTE_CLAIM_PRODUCT.PATH,
      state: getValues(),
    });
  };

  useEffect(() => {
    if (dataSource?.claim?.remainingDays > 36000) {
      setValue('remainingDays', 'ตลอดชีวิต');
    }
    if (dataSource?.claim?.claimedDays) {
      setValue('claimedDays', dataSource?.claim?.claimedDays + '  วัน');
    }
  }, [dataSource]);

  return (
    <Spin spinning={isLoadingClaimDetail}>
      <StyledContainer>
        <TextLarge text="รายละเอียดรายการเคลมสินค้า" />
        <Row gutter={[24, 24]}>
          <Col span={10}>
            <CardTheme>
              <TextLarge text="ข้อมูลส่วนตัว" />
              <RenderForm
                control={control}
                setValue={setValue}
                getValues={getValues}
                forms={FORM_CLAIM_DETAIL_PERSONAL()}
              />
            </CardTheme>
          </Col>
          <Col span={10}>
            <CardTheme>
              <TextLarge text="ข้อมูลการแจ้งเคลม" />
              <RenderForm
                control={control}
                setValue={setValue}
                getValues={getValues}
                forms={FORM_CLAIM_DETAIL_CLAIM()}
              />
            </CardTheme>
          </Col>
          <Col span={4}>
            <TextLarge text="สถานะการเคลม" /> <br />
            <TimelineComponent timelineList={timelineList} />
          </Col>
        </Row>
        <br />
        <Row gutter={[24, 24]}>
          <Col span={10}>
            <CardTheme>
              <RenderForm
                control={control}
                setValue={setValue}
                getValues={getValues}
                forms={FORM_CLAIM_EDIT_DETAIL_PICTURE()}
              />
            </CardTheme>
          </Col>
        </Row>
        <Row justify="space-between" align="middle" style={{ marginTop: '16px' }}>
          <Col>
            <ContainerButton>
              <ButtonTheme useFor="BACK" title="ย้อนกลับ" onClick={goBack} />
            </ContainerButton>
          </Col>
          {claimData?.claimStatus !== 'TRANSPORTED' && (
            <Col>
              <ContainerButton>
                {isPendingApproveClaim ? (
                  <Spin spinning={isPendingApproveClaim}></Spin>
                ) : (
                  <ButtonTheme
                    useFor="CONFIRM"
                    title="อนุมัติ"
                    onClick={onSubmitApprove}
                    disabled={claimData?.claimStatus !== 'WAIT'}
                    style={{ marginRight: '8px' }}
                  />
                )}
                <ButtonTheme
                  useFor="REJECT"
                  title="ไม่อนุมัติ"
                  onClick={() => onModalReject(claimData)}
                  disabled={claimData?.processStatus === 'SHIPPED'}
                />
              </ContainerButton>
            </Col>
          )}
          <Col>
            <ContainerButton>
              <ButtonTheme
                useFor="CHANGE"
                title="เปลี่ยนสินค้า"
                onClick={onToggleChangeProduct}
                style={{ marginRight: '8px' }}
              />
              {openChangeProduct && (
                <ChangeProductModal
                  open={openChangeProduct}
                  onToggle={onToggleChangeProduct}
                  initialValues={dataSource}
                />
              )}
              {claimData?.claimStatus !== 'TRANSPORTED' && (
                <ButtonTheme
                  useFor="EDIT2"
                  title="แก้ไข"
                  onClick={() => {
                    navigateTo({
                      pathname: ROUTES_PATH.ROUTE_CLAIM_EDIT_DETAIL.PATH,
                      state: getValues(),
                    });
                  }}
                />
              )}
            </ContainerButton>
          </Col>
        </Row>
        {openReject && (
          <ModalReject open={openReject} onToggle={onToggleReject} initialValues={claimData} />
        )}
        {modalContext}
      </StyledContainer>
    </Spin>
  );
};
