import { useEffect, useState } from 'react';
import { GET, GET_CLAIM_DETAIL } from '../../../../services';
import { useMutation } from '@tanstack/react-query';

export const useGetClaimDetail = ({ claimCode, setValue }) => {
  const [data, setData] = useState();

  const { isPending, mutate } = useMutation({
    mutationFn: async () => {
      const response = await GET(GET_CLAIM_DETAIL(claimCode));
      return response.result;
    },
    onSuccess: (result) => {
      setData(result);

      if (result && result.claim) {
        Object.keys(result.claim).forEach((key) => {
          if (key !== 'claimImageUrl') {
            setValue(key, result.claim[key]);
          }
        });

        if (result.claim.claimImageUrl) {
          const fileList = Array.isArray(result.claim.claimImageUrl)
            ? result.claim.claimImageUrl
            : [{ url: result.claim.claimImageUrl, name: 'Uploaded Image' }];
          setValue('claimImageUrl', fileList);
        }
      }
    },
  });

  useEffect(() => {
    if (claimCode) {
      mutate();
    }
  }, [claimCode]);

  return { dataSource: data, loading: isPending };
};
