import React, { useEffect } from 'react';
import { ButtonTheme, ModalTheme, RenderForm } from '../../../../components';
import { useForm } from 'react-hook-form';
import { ContainerButton } from '../../../../styles/global-style';
import { FORM_EDIT_PRODUCT, useFormState } from '../forms';
import { useUpdateProduct } from '../hooks/useUpdateProduct';
import {
  productGroupCodeQuery,
  productTypeQuery,
} from '../../../../services/react-query/optionMasterQuery';
import { UpdateProduct } from '../../main/AllApi';

export const EditProduct = ({ open, onToggle, onFetch, initialValues }) => {
  const { defaultValues } = useFormState(initialValues);
  const { control, setValue, getValues, handleSubmit } = useForm({ defaultValues });
  const { data: productTypeList } = productTypeQuery();
  const { data: productGroupCodeList } = productGroupCodeQuery();
  const { mutate: onUpdateProduct } = useUpdateProduct({ onSuccessCallBack: onFetch });

  useEffect(() => {
    if (!initialValues) {
      return;
    }
    Object.keys(initialValues).forEach((key) => {
      const temp =
        key === 'productImageUrl' && initialValues[key]
          ? [
              {
                uid: '-1',
                name: 'image.png',
                status: 'done',
                url: initialValues[key],
              },
            ]
          : initialValues[key];

      setValue(key, temp);
    });
  }, [initialValues, setValue]);

  const onSubmit = (values) => {
    const formData = UpdateProduct(values);

    onUpdateProduct(formData);
    onToggle({ defaultValues: '' });
  };

  return (
    <ModalTheme title="แก้ไขสินค้า" open={open} onToggle={onToggle}>
      <RenderForm
        control={control}
        setValue={setValue}
        getValues={getValues}
        forms={FORM_EDIT_PRODUCT({ productTypeList, productGroupCodeList })}
      />
      <ContainerButton right>
        <ButtonTheme useFor="SUBMIT" onClick={handleSubmit(onSubmit)} />
        <ButtonTheme useFor="CANCEL" onClick={onToggle} />
      </ContainerButton>
    </ModalTheme>
  );
};
