import { useMutation } from '@tanstack/react-query';
import { POST } from '../../../../services';
import { openAlert } from '../../../../components/alert/useAlert';
import { createOrderlist } from '../../main/createManageData';

const POST_INSERT_MEMBER = '/warranty/abm/api/v2/admin/member-orders/insert';

export const useInsertMember = ({ onSuccessCallBack }) =>
  useMutation({
    mutationFn: async (values) => {
      const formData = new FormData();
      const payload = createOrderlist(values);

      Object.entries(payload).forEach(([key, value]) => {
        if (value !== null && value !== undefined) {
          formData.append(key, value);
        }
      });

      const response = await POST(POST_INSERT_MEMBER, formData);
      if (onSuccessCallBack) onSuccessCallBack(response);

      return response.result;
    },
    onSuccess: () => {
      openAlert({
        model: 'message',
        type: 'success',
        message: 'เพิ่มข้อมูลสำเร็จ',
      });
    },
  });
