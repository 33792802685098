import React from 'react';
import { ModalTheme } from '../../../../components/modal/index.jsx';
import { FORM_CHANGE_PRODUCT } from '../forms/index.js';
import { useForm } from 'react-hook-form';
import { ButtonTheme, RenderForm } from '../../../../components/index.js';
import { ContainerButton } from '../../../../styles/global-style.js';
import { useCallback, useEffect, useMemo, useState } from 'preact/hooks';
import { useOptions } from '../hooks/useOptions.js';
import { useWarrantyQuery } from '../hooks/useWarrantyQuery.js';
import { useUpdateProduct } from '../hooks/useUpdateProduct.js';

export const ChangeProductModal = ({ open, onToggle, initialValues }) => {
  const { control, setValue, getValues, handleSubmit, watch, reset } = useForm();
  const [data, setData] = useState([]);
  const { categoryOptions, mobileBrandOptions, mobileModelOptions, productOptions } = useOptions({
    data,
    watch,
  });
  const claim = initialValues?.claim;
  const { claimCode, claimId } = claim;

  const claimData = initialValues?.item;

  useEffect(() => {
    if (!claim) {
      return;
    }
    setValue('categoryId', claim?.categoryId);
    setValue('mobileBrandId', claim?.mobileBrandId);
    setValue('mobileModelId', claim?.mobileModelId);
    setValue('mapProductId', claim?.mapProductId);
    setValue('warrantyNumberCode', claim?.warrantyNumberCode);
  }, [claim]);

  const { mutate: onCallWarranty } = useWarrantyQuery({ reset, getValues });
  const { mutate: onUpdateProduct } = useUpdateProduct();

  useEffect(() => {
    if (claimData) {
      setData([claimData]);
    }
  }, [claimData]);

  const handleOnEnter = useCallback(
    async (value, item) => {
      switch (item?.name) {
        case 'warrantyNumberCode':
          onCallWarranty(value, {
            onSuccess: (data) => {
              setData(data?.items);
            },
          });
          reset({
            ...getValues(),
            categoryId: '',
            mobileBrandId: '',
            mobileModelId: '',
            productId: '',
            mapProductId: '',
          });
          break;

        default:
          break;
      }
    },
    [getValues, onCallWarranty, reset],
  );

  const onChange = (_, item) => {
    switch (item.name) {
      case 'categoryId':
        setValue('mobileBrandId', '');
        setValue('mobileModelId', '');
        setValue('mapProductId', '');
        break;
      case 'mobileBrandId':
        setValue('mobileModelId', '');
        setValue('mapProductId', '');
        break;
      case 'mobileModelId':
        setValue('mapProductId', '');
        break;
      case 'mapProductId':
        break;
    }
  };

  const onSubmit = (value) => {
    const payload = { ...value, claimCode, claimId };
    onUpdateProduct(payload);
    onToggle({ defaultValues: '' });
  };

  return (
    <ModalTheme title="เปลี่ยนสินค้า" open={open} onToggle={onToggle} width={700}>
      <RenderForm
        control={control}
        forms={FORM_CHANGE_PRODUCT({
          categoryOptions,
          mobileBrandOptions,
          mobileModelOptions,
          productOptions,
          onEnter: handleOnEnter,
        })}
        setValue={setValue}
        getValues={getValues}
        onChange={onChange}
      />
      <ContainerButton right>
        <ButtonTheme useFor="SUBMIT" onClick={handleSubmit(onSubmit)} />
        <ButtonTheme useFor="CANCEL" onClick={onToggle} />
      </ContainerButton>
    </ModalTheme>
  );
};
