import { useMutation } from '@tanstack/react-query';
import { GET, GET_CLAIMS_ZIPCODE } from '../../../../services';
import { openAlert } from '../../../../components/alert/useAlert';

export const useZipcode = ({ reset, getValues }) =>
  useMutation({
    mutationFn: async (value) => {
      try {
        const response = await GET(GET_CLAIMS_ZIPCODE(value));
        return response.result;
      } catch (error) {
        throw { ...error, disabledThrow: true };
      }
    },
    onSuccess: (result) => {
      openAlert({
        type: 'success',
        message: 'ค้นหารหัสไปรษณีย์สำเร็จ',
      });
      reset({
        ...getValues(),
        ...result,
      });
    },
    onError: (error) => {
      openAlert({
        type: 'error',
        message: error.message,
      });
    },
  });
