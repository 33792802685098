//TODO add to Template
import moment from 'moment';

export const compareSortText = (a, b, dataIndex) => {
  const keyA = a[dataIndex] || '';
  const keyB = b[dataIndex] || '';
  return keyA.localeCompare(keyB);
};

export const compareSortNumber = (a, b, dataIndex) => {
  const strA = `${a[dataIndex]}`;
  const strB = `${b[dataIndex]}`;
  const keyA = parseFloat(strA.replaceAll(',', '')) || 0;
  const keyB = parseFloat(strB.replaceAll(',', '')) || 0;
  return keyA - keyB;
};

export const compareSortDate = (a, b, dataIndex) => {
  const keyA = a[dataIndex] || '';
  const keyB = b[dataIndex] || '';
  return moment(keyA, 'DD-MM-YYYY').unix() - moment(keyB, 'DD-MM-YYYY').unix();
};
