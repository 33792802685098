import React from 'react';
import { useLocation } from 'react-router-dom';
import { ResponsivePageLayout } from '@/components/page-layout/ResponsivePageLayout';
import { columns } from './columns';
import { useGetCategoryFull } from './hooks/useGetCategoryFull';
import { useForm } from 'react-hook-form';
import { useModal } from '../../../components/modal/hooks/useModal';
import { ButtonTheme, CardTheme, TextLarge } from '../../../components';
import { ContainerButton } from '../../../styles/global-style';
import { AddManagement } from './modals/AddManagement';
import { useDeleteManagement } from './hooks/useDeleteManagement';

export const Management = () => {
  const { setValue, reset } = useForm();
  const { open: openAdd, onToggle: onToggleAdd } = useModal();

  const { state } = useLocation();
  const { dataSource: data } = useGetCategoryFull({
    categoryCode: state.categoryCode,
    reset,
    setValue,
  });

  const { mutate: onSubmitDeleteManagement } = useDeleteManagement();

  return (
    <CardTheme>
      <TextLarge text={'จัดกลุ่มสินค้า ' + data[0]?.categoryCode}></TextLarge>
      <ContainerButton right>
        <ButtonTheme useFor="WARRANTY" title="เพิ่มกลุ่มสินค้า" onClick={onToggleAdd} />
        {openAdd && <AddManagement open={openAdd} onToggle={onToggleAdd} />}
      </ContainerButton>
      <ResponsivePageLayout
        tableLayout={{
          columns: columns({ onSubmitDeleteManagement }),
          dataSource: data || [],
          hasPagination: true,
        }}
      />
    </CardTheme>
  );
};
