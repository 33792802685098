import React from 'react';
import {
  CardTheme,
  TextLarge,
  RenderForm,
  ButtonTheme,
  TimelineComponent,
} from '../../../components/index';
import { StyledContainer } from './styles';
import { useForm } from 'react-hook-form';
import { Row, Col, Spin } from 'antd';
import { useLocation } from 'react-router-dom';
import { FORM_CLAIM_DETAIL_PERSONAL, FORM_CLAIM_EDIT_DETAIL_PICTURE } from './forms';
import { FORM_CLAIM_DETAIL_CLAIM } from './forms';
import { useCallback, useEffect, useState } from 'preact/hooks';
import { ContainerButton } from '../../../styles/global-style';
import { useGetClaimEdit } from './hooks/useGetClaimEdit';
import { onEnter } from '../../../functions/onEnterClaim';
import { useProvinceOptions } from '../../../functions/useProvinceOptions';
import { useSelectedProvince } from '../../../functions/useSelectedProvince';
import { onUploadFile } from './hooks/onUploadFile';
import { handleSubmitForm } from './func/submitHandler';
import { serviceOptions } from '../../../functions/serviceOption';

export const ClaimEditDetail = () => {
  const { control, setValue, getValues, handleSubmit, watch, reset } = useForm();
  const { state } = useLocation();
  const [timelineList, setTimelineList] = useState([]);
  const [province, setProvince] = useState([]);
  const [breakdownList, setBreakdownList] = useState([]);
  const [provinceOptions, setProvinceOptions] = useState([]);
  const [districtOptions, setDistrictOptions] = useState([]);
  const [subDistrictOptions, setSubDistrictOptions] = useState([]);
  const [, setIsSubmitting] = useState(false);
  const { isPending: isPendingUpload, mutate: mutateUpload } = onUploadFile({
    onSuccessCallBack: (response) => {
      setIsSubmitting(false);
    },
  });

  const { dataSource: data, loading: isLoadingClaimEdit } = useGetClaimEdit({
    claimCode: state?.claimCode,
    setValue,
    reset,
    setProvince,
  });

  const onSubmit = (values) => handleSubmitForm(values, setIsSubmitting, mutateUpload);

  useEffect(() => {
    const updateClaimData = async () => {
      try {
        if (data?.claim) {
          setValue('claimId', data.claim.claimId);
          setValue('claimCode', data.claim.claimCode);

          const result = await onEnter(data?.claim?.zipCode, { name: 'zipCode' }, setValue);
          if (result?.provinceOptions) {
            setProvince(result.province);
            setProvinceOptions(result.provinceOptions);
            setDistrictOptions(result.districtOptions);
            setSubDistrictOptions(result.subDistrictOptions);

            setValue('provinceId', data.claim.provinceId);
            setValue('districtId', data.claim.districtId);
            setValue('subDistrictId', data.claim.subDistrictId);
          }
        }

        if (data?.timelineList) {
          const timeline = data.timelineList.map((timeline) => {
            return {
              text: timeline.optionStatusNameTh,
              color: timeline.optionStatusCode == data.claim.claimStatus ? 'green' : 'black',
            };
          });
          setTimelineList(timeline);
        }

        if (data?.breakdowns) {
          const breakdown = data?.breakdowns?.map((breakdown) => {
            return {
              label: breakdown?.breakdownNameTh,
              value: breakdown?.breakdownId,
            };
          });
          setBreakdownList(breakdown);
        }
      } catch (error) {}
    };
    updateClaimData();
  }, [data, setValue]);

  const selectedProvinceId = watch('proviceId');

  useProvinceOptions(selectedProvinceId, province, setValue);
  useSelectedProvince(selectedProvinceId, province, setValue);

  const handleOnEnter = useCallback(
    async (value, item) => {
      const result = await onEnter(value, item, setValue);
      if (result?.provinceOptions) {
        setProvince(result.province);
        setProvinceOptions(result.provinceOptions);
        setDistrictOptions(result.districtOptions);
        setSubDistrictOptions(result.subDistrictOptions);
      }
    },
    [getValues],
  );

  useEffect(() => {
    if (state) {
      Object.keys(state).forEach((key) => {
        setValue(key, state[key]);
      });
    }
  }, [state, setValue]);

  return (
    <Spin spinning={isLoadingClaimEdit}>
      <StyledContainer>
        <TextLarge text="รายละเอียดรายการเคลมสินค้า" />
        <Row gutter={[24, 24]}>
          <Col span={10}>
            <CardTheme>
              <TextLarge text="ข้อมูลส่วนตัว" />
              <RenderForm
                control={control}
                setValue={setValue}
                getValues={getValues}
                forms={FORM_CLAIM_DETAIL_PERSONAL({
                  onEnter: handleOnEnter,
                  provinceOptions,
                  districtOptions,
                  subDistrictOptions,
                })}
              />
            </CardTheme>
          </Col>
          <Col span={10}>
            <CardTheme>
              <TextLarge text="ข้อมูลการแจ้งเคลม" />
              <RenderForm
                control={control}
                setValue={setValue}
                getValues={getValues}
                forms={FORM_CLAIM_DETAIL_CLAIM({ breakdownList })}
              />
            </CardTheme>
          </Col>
          <Col span={4}>
            <TextLarge text="สถานะการเคลม" /> <br />
            <TimelineComponent timelineList={timelineList} />
          </Col>
        </Row>
        <br />
        <Row gutter={[24, 24]}>
          <Col span={10}>
            <CardTheme>
              <RenderForm
                control={control}
                setValue={setValue}
                getValues={getValues}
                forms={FORM_CLAIM_EDIT_DETAIL_PICTURE()}
              />
            </CardTheme>
          </Col>
        </Row>
        <ContainerButton center>
          {isPendingUpload ? (
            <Spin spinning={isPendingUpload}></Spin>
          ) : (
            <ButtonTheme useFor="SUBMIT" title="อนุมัติ" onClick={handleSubmit(onSubmit)} />
          )}
        </ContainerButton>
      </StyledContainer>
    </Spin>
  );
};
