import {
  createColumnText,
  createColumnDate,
  widthOptions,
  createColumnTag,
  createColumnNumber,
} from '../../../../components/table/function/createColumn';
import { ORDER_FROM } from '../../../../resources/status';

export const columns = () => {
  return [
    createColumnNumber('ลำดับ', 'rowNumber', widthOptions(100)),
    createColumnTag('ช่องทาง', { key: 'orderFrom', text: 'orderFrom' }, ORDER_FROM, {
      width: '120px',
    }),
    createColumnText('เลขคำสั่งซื้อ', 'invNo', widthOptions(130)),
    createColumnText('สินค้า', 'promotionHeadName', widthOptions(120)),
    createColumnText('ชื่อลูกค้า', 'fullName', widthOptions(150)),
    createColumnText('เบอร์ติดต่อ', 'telephone', widthOptions(140)),
    createColumnDate('วันที่ซื้อ', 'orderDate', widthOptions(150)),
  ];
};
