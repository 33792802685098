import dayjs from 'dayjs';
export const FORM_INFO_SCAN_DELIVER = ({
  onEnter,
  shippingSelfList,
  trackingInputRef,
  claimCodeInputRef,
}) => {
  const span = 24;
  return [
    {
      name: 'claimCode',
      label: 'เลขที่เคลม (กรอกเลขที่เคลมแล้วกด Enter)',
      type: 'INPUT',
      onKeyDown: onEnter,
      ref: claimCodeInputRef,
      span,
    },
    {
      name: 'trackingNumber',
      label: 'เลขที่พัสดุ',
      type: 'INPUT',
      ref: trackingInputRef,
      span,
    },
    {
      name: 'shippingId',
      label: 'ขนส่ง',
      type: 'DROPDOWN',
      placeholder: '',
      span,
      properties: {
        options: shippingSelfList || [],
      },
      rules: {
        required: true,
      },
    },
    {
      name: 'shippingDate',
      label: 'วันที่จัดส่ง',
      type: 'DATE_PICKER',
      span,
      defaultValue: dayjs(),
      disabled: true,
    },
    {
      name: 'claimId',
      type: 'HIDDEN',
    },
  ];
};

export const FORM_INFO_DOC = () => {
  const span = 24;
  return [
    {
      name: 'claimCode',
      label: 'เลขที่เคลม',
      type: 'TEXT',
      span,
    },
    {
      name: 'fullName',
      label: 'ชื่อ-นามสกุล',
      type: 'TEXT',
      span,
    },
    {
      name: 'telephone',
      label: 'หมายเลขโทรศัพท์',
      type: 'TEXT',
      span,
    },
    {
      name: 'addressFullText',
      label: 'ที่อยู่',
      type: 'TEXT',
      span,
    },
    {
      name: 'mobileBrandName',
      label: 'รุ่นสินค้า',
      type: 'TEXT',
      span,
    },
    {
      name: 'mobileModelName',
      label: 'รุ่นมือถือ',
      type: 'TEXT',
      span,
    },
    {
      name: 'remark',
      label: 'หมายเหตุ',
      type: 'TEXT',
      span,
    },
    {
      name: 'claimStatusName',
      label: 'สถานะ',
      type: 'TEXT',
      span,
    },
  ];
};
