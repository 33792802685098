import { useState } from 'preact/hooks';
import { GET } from '../../../../services';
import { useMutation } from '@tanstack/react-query';

const GET_REPORT_COUPON = () => {
  return `/warranty/abm/api/v2/admin/reports/coupons/used`;
};

export const useGetCoupon = () => {
  const [data, setData] = useState([]);

  const { isPending, mutate } = useMutation({
    mutationFn: async () => {
      const response = await GET(GET_REPORT_COUPON());
      return response.result.items || [];
    },
    onSuccess: (response) => {
      setData(response);
    },
  });

  return { dataSource: data, setData, onSearch: mutate, loading: isPending };
};
