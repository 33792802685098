import React from 'react';
import { columns } from './columns/index';
import { StyledContainer } from './styles';
import { TextLarge, CardTheme, ResponsivePageLayout } from '@/components/index';
import ButtonExcelEmbedded from '@/components/report-excel/ButtonExcelEmbedded';
import { ContainerButton } from '@/styles/global-style';
import { useGetCoupon } from './hooks/useGetCoupon';
import { SpaceBetween } from '../../manage-data/user-info/styles';
import { useEffect } from 'preact/hooks';

export const ReportCoupon = () => {
  const { dataSource, onSearch, loading: isPendingCoupon } = useGetCoupon();

  useEffect(() => {
    onSearch();
  }, []);

  return (
    <StyledContainer>
      <TextLarge text="รายงานคูปองทั้งหมด" />
      <CardTheme>
        <ResponsivePageLayout
          tableLayout={{
            columns: columns(),
            dataSource: dataSource || [],
            hasPagination: true,
            loading: isPendingCoupon,
            extraContent: (
              <SpaceBetween>
                <ContainerButton right>
                  <ButtonExcelEmbedded
                    title="Export"
                    header={columns()}
                    data={dataSource}
                    filename="รายการรายงานทั้งหมด"
                  />
                </ContainerButton>
              </SpaceBetween>
            ),
          }}
        />
      </CardTheme>
    </StyledContainer>
  );
};
