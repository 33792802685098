import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { GET, GET_USER_PRIVILEGE } from '@/services';
import { openAlert } from '@/components/alert/useAlert';

export const useGetUser = ({ handleSubmit, setSelectedRowKeys }) => {
  const [data, setData] = useState([]);
  const [userId, setUserId] = useState(null);

  const { isPending, mutate: onSearch } = useMutation({
    mutationFn: async (values) => {
      try {
        const response = await GET(GET_USER_PRIVILEGE(values.search));
        return response.result?.item || [];
      } catch (error) {
        throw { ...error, disabledThrow: true };
      }
    },
    onSuccess: (item) => {
      const activeRows = item.privilegeAbmData
        .filter((row) => row.status === 'ACTIVE')
        .map((row) => row.id);

      setSelectedRowKeys(activeRows);
      setData(item.privilegeAbmData || []);
      setUserId(item.userId);
    },
    onError: () => {
      openAlert({
        model: 'message',
        type: 'error',
        message: 'กรุณาเลือกข้อมูลให้ถูกต้อง',
      });
    },
  });

  const onFetch = () =>
    handleSubmit((values) => {
      return onSearch(values);
    })();

  return { dataSource: data, userId, setData, onSearch, loading: isPending, onFetch };
};
