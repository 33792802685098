import React from 'react';
import { CardTheme, TextLarge, RenderForm, ButtonTheme } from '@/components/index';
import { StyledContainer } from './styles';
import { useForm } from 'react-hook-form';
import { FORM_INFO_SCAN_DELIVER, FORM_INFO_DOC } from './forms';
import { Row, Col, Spin } from 'antd';
import { shippingSelfQuery } from '../../services/react-query/optionMasterQuery';
import { useCallback, useRef } from 'preact/hooks';
import { useClaimCodeQuery } from './hooks/useClaimCodeQuery';
import { useUpdateTracking } from './hooks/useUpdateTracking';
import { ContainerButton } from '@/styles/global-style';

export const ScanDeliver = () => {
  const { control, setValue, getValues, handleSubmit, reset } = useForm();
  const { data: shippingSelfList } = shippingSelfQuery();

  const { mutate: onCallClaimCode, isPending: isPendingClaimCode } = useClaimCodeQuery({
    reset,
    getValues,
  });
  const { mutate: onUpdateTracking, isPending: isPendingUpdateTracking } = useUpdateTracking();

  const trackingInputRef = useRef(null);
  const claimCodeInputRef = useRef(null);

  const handleOnEnter = useCallback(
    async (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        const value = getValues('claimCode');
        if (value) {
          onCallClaimCode(value, {
            onSuccess: () => {
              trackingInputRef.current?.focus();
            },
            onError: () => {
              reset({
                claimCode: '',
              });
            },
          });
        }
      }
    },
    [onCallClaimCode, getValues],
  );

  const onSubmit = (value) => {
    onUpdateTracking(value);
    reset({
      claimCode: '',
      trackingNumber: '',
      shippingId: getValues('shippingId'),
    });
    claimCodeInputRef.current?.focus();
  };

  return (
    <Spin spinning={isPendingClaimCode}>
      <StyledContainer>
        <TextLarge text="ลงทะเบียนสินค้า" />
        <Row gutter={16}>
          <Col span={10}>
            <CardTheme>
              <TextLarge text="ข้อมูลการยิงงาน" />
              <RenderForm
                control={control}
                setValue={setValue}
                getValues={getValues}
                forms={FORM_INFO_SCAN_DELIVER({
                  onEnter: handleOnEnter,
                  trackingInputRef,
                  shippingSelfList,
                  claimCodeInputRef,
                })}
              />
              <ContainerButton center>
                <Spin spinning={isPendingUpdateTracking}>
                  <ButtonTheme useFor="SUBMIT" title="ลงทะเบียน" onClick={handleSubmit(onSubmit)} />
                </Spin>
              </ContainerButton>
            </CardTheme>
          </Col>
          <Col span={14}>
            <CardTheme>
              <TextLarge text="ข้อมูลเอกสาร" />
              <RenderForm
                control={control}
                setValue={setValue}
                getValues={getValues}
                forms={FORM_INFO_DOC()}
              />
            </CardTheme>
          </Col>
        </Row>
      </StyledContainer>
    </Spin>
  );
};
