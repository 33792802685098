import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { navigationRef } from './utils/navigation';
import GlobalStyle from './styles/global-style';
import * as ROUTES from './resources/routes-name';
import HandleScene from './screens/handle';
import { Fragment } from 'react';
import { App as AppAntD } from 'antd';
import LoginScene from './screens/main/login';
import ChangePasswordScene from './screens/main/change-password';
import { Provider } from 'react-redux';
import store from './redux/store.js';
import Alert from './components/alert/alert';
import Loading from './components/loading/loading.jsx';
import LayoutPage from './components/layoutPage';
import { QueryClientComponentProvider } from './components/react-query/QueryClientComponentProvider';
import { AntDModalContextProvider } from './components/modal/context/useAntdModalContext';
import { ReactQueryDevToolsComponent } from './components/react-query/ReactQueryDevToolsComponent';
import { env } from './utils/env/config';

const isProd = env.node_env !== 'development';

if (isProd) {
  console.log = () => null;
  console.error = () => null;
}

const AppContainer = () => {
  return (
    <AntDModalContextProvider>
      <QueryClientComponentProvider>
        <ReactQueryDevToolsComponent isProd={isProd} />
        <Provider store={store}>
          <Loading>
            <Alert />
            <Router ref={navigationRef}>
              <div />
              <Switch>
                <Route exact path={ROUTES.ROUTE_HANDLE} component={HandleScene} />
                <Route exact path={ROUTES.ROUTE_LOGIN_MANUAL} component={LoginScene} />
                <Route exact path={ROUTES.ROUTE_CHANGE_PASSWORD} component={ChangePasswordScene} />
                <Route exact component={LayoutPage} />
              </Switch>
            </Router>
          </Loading>
        </Provider>
      </QueryClientComponentProvider>
    </AntDModalContextProvider>
  );
};

const App = () => {
  return (
    <Fragment>
      <AppAntD>
        <AppContainer />
        <GlobalStyle />
      </AppAntD>
    </Fragment>
  );
};

export default App;
