export const FORM_SEARCH = () => {
  return [
    {
      name: 'findData',
      type: 'INPUT',
      placeholder: 'ค้นหาข้อมูลลูกค้า',
      span: 8,
    },
  ];
};
