import React, { Fragment } from 'preact';
import { useController } from 'react-hook-form';
import { Required, RowComponent } from '../../../styles/global-style';
import { TextXSMall, TextInputLabel } from '../../text';
import { useEffect, useState } from 'preact/hooks';
import { Switch } from 'antd';
import { renderTypeError } from '..';

const SwitchComponent = ({ control, item, handleChange, setValue, getValues }) => {
  const { rules, name, defaultValue, label, disabled, propperties, switchColor, ...propsInput } =
    item;

  const { fieldState } = useController({
    control,
    name,
    rules,
    defaultValue,
  });
  const { error } = fieldState;

  const [isChecked, setIsChecked] = useState(item.defaultValue || false);

  useEffect(() => {
    setValue(name, item.defaultValue || false);
  }, []);

  const handleSwitchChange = (checked) => {
    setIsChecked(checked);
    handleChange(checked, item);
  };

  const onColor = switchColor?.onColor || 'green';
  const offColor = switchColor?.offColor || 'red';

  return (
    <Fragment>
      <RowComponent>
        {label && <TextInputLabel text={label} />}
        {rules && rules.required && <Required>*</Required>}
      </RowComponent>
      <Switch
        id={name}
        disabled={disabled}
        {...propperties}
        defaultChecked={item.defaultValue || false}
        onChange={handleSwitchChange}
        style={{
          backgroundColor: isChecked ? onColor : offColor,
        }}
        {...propsInput}
      />
      {error && <TextXSMall text={renderTypeError(item, error)} color="red" />}
    </Fragment>
  );
};

export const MemoizedSwitch = SwitchComponent; //memo(, compareRender);
