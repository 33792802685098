import { columns } from './columns/index';
import { SpaceBetween, StyledContainer } from './styles';
import { FORM_SEARCH, searchDefaultValue } from './forms';
import { TextLarge, CardTheme } from '../../../components/index';
import { Icon } from '../../../resources';
import { useState } from 'preact/hooks';
import { useModal } from '../../../components/modal/hooks/useModal';
import { ViewModal } from './modals/ViewModal';
import ButtonExcelEmbedded from '../../../components/report-excel/ButtonExcelEmbedded';
import { useGetUserInfo } from './hooks/useGetUserInfo';
import { ResponsivePageLayout } from '../../../components/page-layout/ResponsivePageLayout';
import { useForm } from 'react-hook-form';
import { ModalMessage } from './modals/ModalMessage';
import { ContainerButton } from '../../../styles/global-style';
import { RowSearchLayout } from '../../../components/page-layout/views/RowSearchLayout';
import { InfoUser } from './views/InfoUser';

export const UserInfo = () => {
  const [modalData, setModalData] = useState(null);
  const { open: openEdit, onToggle: onToggleEdit } = useModal();
  const { open: openMessage, onToggle: onToggleMessage } = useModal();

  const formSearchObj = useForm({ defaultValues: searchDefaultValue });
  const {
    dataSource,
    onFetch,
    onSearchFull,
    loading: isLoading,
  } = useGetUserInfo({
    handleSubmit: formSearchObj.handleSubmit,
  });

  const onModalMessage = (record) => {
    setModalData(record);
    onToggleMessage({ record });
  };

  const onEdit = (record) => {
    setModalData(record);
    onToggleEdit({ defaultValues: record });
  };

  return (
    <StyledContainer>
      <TextLarge text="ข้อมูลลูกค้า" />
      <CardTheme>
        <RowSearchLayout
          props={{
            title: 'ค้นหาข้อมูลลูกค้า',
            formSearch: FORM_SEARCH(),
            useFormParam: formSearchObj,
            icon: <Icon.todo />,
            onSearch: formSearchObj.handleSubmit(onSearchFull),
            ignoreDateEmpty: true,
          }}
        >
          <InfoUser />
        </RowSearchLayout>
        <ResponsivePageLayout
          tableLayout={{
            columns: columns(onModalMessage, onEdit),
            dataSource,
            hasPagination: true,
            loading: isLoading,
            extraContent: (
              <SpaceBetween>
                <ContainerButton right>
                  <ButtonExcelEmbedded
                    title="Export Excel"
                    header={columns(onModalMessage, onEdit)}
                    data={dataSource}
                    filename="รายงานข้อมูลลูกค้า"
                  />
                </ContainerButton>
              </SpaceBetween>
            ),
          }}
        />
      </CardTheme>
      {openMessage && (
        <ModalMessage
          open={openMessage}
          onToggle={onToggleMessage}
          initialValues={modalData}
          onFetch={onFetch}
        />
      )}
      {openEdit && (
        <ViewModal
          open={openEdit}
          onToggle={onToggleEdit}
          initialValues={modalData}
          onFetch={onFetch}
        />
      )}
    </StyledContainer>
  );
};
