import React, { useState, createContext, useContext } from 'react';
import { compareSortText, compareSortNumber, compareSortDate } from './columnSorter';
import { searchFilter } from '../searchfilter/SearchFilter';
import { ButtonTheme, ButtonToolTip } from '../../buttons';
import { ContainerButton } from '../../../styles/global-style';
import { Card, Image, Menu, Popconfirm, Popover, Tag } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { CustomImage } from '../../../functions/Image';
import { color } from '../../../resources';

const defaultOption = {
  skipExport: false,
  others: {},
};

export const createColumnRowNumber = (title, optionsParam = {}) => {
  const options = { ...defaultOption, ...optionsParam };
  return {
    title,
    align: options.align || 'right',
    skipExport: options.skipExport,
    render: (_text, _record, index) => index + 1,
    ...options.others,
  };
};

export const createColumnText = (title, dataIndex, optionsParam = {}) => {
  const options = { ...defaultOption, ...optionsParam };
  const sorter = options.sorter ? options.sorter : (a, b) => compareSortText(a, b, dataIndex);
  const additionRender = options.noSearch ? {} : searchFilter(dataIndex);
  return {
    title,
    dataIndex,
    sorter: options.noSort ? null : sorter,
    align: options.align || 'left',
    skipExport: options.skipExport,
    ...additionRender,
    ...options.others,
  };
};

export const formatNumber = (value) => {
  if (!value) return '';
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const createColumnNumber = (title, dataIndex, optionsParam = {}) => {
  const options = { ...defaultOption, ...optionsParam };
  const sorter = options.sorter ? options.sorter : (a, b) => compareSortNumber(a, b, dataIndex);
  const additionRender = options.noSearch ? {} : searchFilter(dataIndex);

  const render = options.others?.render || ((value) => formatNumber(value));

  return {
    title,
    dataIndex,
    sorter: options.noSort ? null : sorter,
    align: options.align || 'right',
    skipExport: options.skipExport,
    render,
    ...additionRender,
    ...options.others,
  };
};

export const createColumnDate = (title, dataIndex, optionsParam = {}) => {
  const options = { ...defaultOption, ...optionsParam };
  const sorter = options.sorter ? options.sorter : (a, b) => compareSortDate(a, b, dataIndex);
  const additionRender = options.noSearch ? {} : searchFilter(dataIndex);
  return {
    title,
    dataIndex,
    sorter: options.noSort ? null : sorter,
    align: options.align || 'center',
    skipExport: options.skipExport,
    ...additionRender,
    ...options.others,
  };
};

export const createColumnButton = (title, dataIndex, options = { others: {}, buttonArr: [] }) => {
  return {
    title,
    dataIndex,
    align: options.align || 'center',
    skipExport: true,
    render: (text, record) => {
      return (
        <ContainerButton center>
          {options.buttonArr.map((e, i) => (
            <ButtonTheme
              key={`action-${text}-${i}`}
              size="small"
              {...e}
              onClick={(event) => {
                e.onClick({ text, record, event });
              }}
            />
          ))}
        </ContainerButton>
      );
    },
    ...options.others,
  };
};

export const createColumnButtonArr = (
  title,
  dataIndex,
  options = { others: {}, buttonArr: [] },
) => {
  return {
    title,
    dataIndex,
    align: options.align || 'center',
    skipExport: true,
    render: (text, record) => (
      <ContainerButton center>
        {options.buttonArr.map((e, i) =>
          e.render ? (
            e.render(text, record)
          ) : (
            <ButtonToolTip
              key={`action-${text}-${i}`}
              toolTip={e.title}
              {...e}
              onClick={(event) => {
                event.stopPropagation();
                e.onClick({ record, event });
              }}
            >
              {renderButtonType(e)}
            </ButtonToolTip>
          ),
        )}
      </ContainerButton>
    ),
    ...options.others,
  };
};

export const widthOptions = (width, other) => ({
  others: {
    width,
    ...other,
  },
});

export const createInputField = (name, label, placeholder = '', span = 24) => ({
  name,
  label,
  type: 'INPUT',
  placeholder,
  span,
  rules: {
    validate: (v) => {
      if (!v) {
        return `กรุณากรอก${label}`;
      }
      if (!/^[A-Za-z0-9]*$/.test(v)) {
        return `กรุณากรอก${label}ด้วยภาษาอังกฤษหรือตัวเลขเท่านั้น`;
      }
      return true;
    },
  },
});

export const createColumnCenter = (title, dataIndex, optionsParam = {}) => {
  const options = { ...defaultOption, ...optionsParam };
  return {
    title,
    dataIndex,
    align: 'center',
    skipExport: options.skipExport,
    ...options.others,
  };
};

export const createColumnDelete = (title, dataIndex, options = {}, buttonProps = {}, onClick) => {
  return {
    title,
    dataIndex,
    align: options.align || 'center',
    skipExport: true,
    render: (_text, _record, index) => (
      <ButtonTheme
        useFor="REMOVE"
        onClick={() => {
          onClick(_record);
        }}
        {...buttonProps}
      />
    ),
    ...options.others,
  };
};

const PopoverContext = createContext({
  visible: false,
  setVisible: () => {},
});

export const createColumnPopover = ({
  title,
  dataIndex,
  contentOptions = {},
  options = {},
  iconProps = {},
  onClick = () => {},
}) => {
  const PopoverContent = React.memo(({ _record }) => {
    const { setVisible } = useContext(PopoverContext);

    return (
      <Menu>
        {contentOptions.menuItems?.map((item, index) => (
          <Menu.Item
            key={index}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {item.confirm ? (
              <Popconfirm
                title={item.confirm.title}
                onConfirm={() => {
                  item.confirm.onConfirm(_record);
                  setVisible(false);
                }}
                okText="ใช่"
                cancelText="ไม่"
              >
                <span>
                  <item.icon style={{ color: item.color }} />
                  {item.text}
                </span>
              </Popconfirm>
            ) : (
              <span>
                <item.icon style={{ color: item.color }} />
                {item.text}
              </span>
            )}
          </Menu.Item>
        ))}
      </Menu>
    );
  });

  const PopoverWrapper = React.memo(({ record }) => {
    const [visible, setVisible] = useState(false);

    return (
      <PopoverContext.Provider value={{ visible, setVisible }}>
        <ContainerButton center>
          <Popover
            content={<PopoverContent _record={record} />}
            title={title}
            trigger="click"
            placement="bottomRight"
            open={visible}
            onOpenChange={(newVisible) => {
              setVisible(newVisible);
            }}
          >
            <MoreOutlined
              style={{ fontSize: '20px', cursor: 'pointer' }}
              onClick={(e) => {
                e.stopPropagation();
                onClick(record);
              }}
              {...iconProps}
            />
          </Popover>
        </ContainerButton>
      </PopoverContext.Provider>
    );
  });

  return {
    title,
    dataIndex,
    align: options.align || 'center',
    skipExport: true,
    render: (_text, record) => <PopoverWrapper record={record} />,
    ...options.others,
  };
};

export const createImageColumn = (label, dataIndex, optionsParam = {}) => {
  const defaultOptions = { width: 120, align: 'center', preview: true, ...optionsParam };

  return {
    title: label,
    dataIndex,
    align: defaultOptions.align,
    width: defaultOptions.width,
    skipExport: defaultOptions.skipExport || false,
    render: (imageUrl) => (
      <Image
        src={imageUrl}
        alt={label}
        width={80}
        style={{ borderRadius: 8, objectFit: 'cover' }}
        preview={defaultOptions.preview}
      />
    ),
    ...defaultOptions.others,
  };
};

export const createSimpleColumnText = (title, dataIndex, optionsParam = {}) => {
  const options = { ...defaultOption, ...optionsParam };
  return {
    title,
    dataIndex,
    align: options.align || 'left',
    skipExport: options.skipExport,
    render: options.render,
    width: options.width,
  };
};

export const createColumnTag = (title, dataIndexObj, filterList = [], options = {}) => {
  const sorter = options.sorter
    ? options.sorter
    : (a, b) => compareSortText(a, b, dataIndexObj?.text);

  const filters = filterList
    .map((el) => ({
      text: el?.text || el?.label || el?.title,
      value: el?.value || el?.key,
    }))
    .filter((el) => el.value !== 'DEFAULT');

  return {
    title,
    dataIndex: dataIndexObj?.text,
    align: options.align || 'center',
    width: 120,
    skipExport: options.skipExport,
    sorter: options.noSort ? null : sorter,
    filters,
    onFilter: options?.onFilter
      ? options.onFilter
      : (value, record) => record[dataIndexObj?.key] === value,
    render: (_, record) => {
      const curIndex = filterList.findIndex(
        (el) => el?.key === record[dataIndexObj?.key] || el?.value === record[dataIndexObj?.key],
      );
      const current = filterList[curIndex];
      return (
        <div
          style={{
            color: current?.textColor || current?.color || current?.bgColor || color.clear,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '4px',
          }}
        >
          {current?.icon && (
            <span style={{ color: current?.iconColor || current?.color || current?.bgColor }}>
              {current.icon}
            </span>
          )}
          {record[dataIndexObj?.text] || current?.label}
        </div>
      );
    },
    ...options,
  };
};
