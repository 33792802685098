import React from 'react';
import { useForm } from 'react-hook-form';
import { useCallback } from 'preact/hooks';
import { FORM_REJECT } from '../forms/index.js';
import { useDeleteClaim } from '../hooks/useDeleteClaim.js';
import { Modal, Spin } from 'antd';
import { ButtonTheme, ModalTheme, RenderForm, TextLarge, TextSmall } from '@/components/index.js';
import { navigateTo } from '@/utils/navigation/index.js';
import { ROUTES_PATH } from '@/resources/routes-name.js';
import { openAlert } from '@/components/alert/useAlert.js';
import { ContainerButton } from '@/styles/global-style.js';

export const ModalReject = ({ open, onToggle, initialValues }) => {
  const { control, setValue, handleSubmit } = useForm();
  const [modal, modalContext] = Modal.useModal();
  const { mutate: handleDelete, isPending: isPendingDelete } = useDeleteClaim({ modal });

  const onSubmitSuccess = useCallback(
    (formData) => {
      const payload = {
        claimId: initialValues.claimId,
        rejectReason: formData.rejectReason,
      };

      handleDelete(payload, {
        onSuccess: () => {
          onToggle({ defaultValues: '' });
          openAlert({ type: 'success', message: 'ไม่อนุมัติการเคลมสินค้าสำเร็จ' });
          setTimeout(() => {
            navigateTo({ pathname: ROUTES_PATH.ROUTE_CLAIM_PRODUCT.PATH });
          }, 3000);
        },
      });
    },
    [handleDelete, initialValues.claimId, onToggle],
  );

  return (
    <ModalTheme title=" " open={open} onToggle={onToggle} initialValues={initialValues}>
      <div style={{ textAlign: 'center', width: '100%' }}>
        <TextLarge text="เหตุผลที่ไม่อนุมัติ" />
      </div>
      <div style={{ textAlign: 'center', width: '100%' }}>
        <TextSmall text={initialValues.claimCode + ' ' + ' ' + initialValues.fullName} />
      </div>
      <RenderForm control={control} forms={FORM_REJECT()} setValue={setValue} />
      <ContainerButton right>
        {isPendingDelete ? (
          <Spin spinning={isPendingDelete}></Spin>
        ) : (
          <ButtonTheme useFor="SUBMIT" onClick={handleSubmit(onSubmitSuccess)} />
        )}
        <ButtonTheme useFor="CANCEL" onClick={onToggle} />
      </ContainerButton>
      {modalContext}
    </ModalTheme>
  );
};
