export const updateProduct = (values) => {
  return {
    warrantyNumberCode: values.warrantyNumberCode,
    claimId: values.claimId,
    claimCode: values.claimCode,
    categoryId: values.categoryId,
    mobileBrandId: values.mobileBrandId,
    mobileModelId: values.mobileModelId,
    mapProductId: values.mapProductId,
    remark: values.remark,
  };
};
