import {
  createColumnText,
  createColumnDate,
  widthOptions,
  createColumnNumber,
  createColumnTag,
} from '@/components/table/function/createColumn';
import { COUPON_STATUS } from '../../../../resources/status';

export const columns = () => {
  return [
    createColumnNumber('ลำดับ', 'rowNumber', widthOptions(100)),
    createColumnTag('ช่องทาง', { key: 'channelUsed', text: 'channelUsed' }, COUPON_STATUS, {
      width: '120px',
    }),
    createColumnText('ชื่อคูปอง', 'title', widthOptions(120)),
    createColumnText('ประเภทคูปอง', 'couponTypeName', widthOptions(120)),
    createColumnText('รหัสคูปอง', 'couponCode', widthOptions(120)),
    createColumnText('ชื่อลูกค้า', 'fullName', widthOptions(150)),
    createColumnText('เบอร์ติดต่อ', 'telephone', widthOptions(140)),
    createColumnDate('วันที่ซื้อ', 'usedDate', widthOptions(150)),
  ];
};
