import React from 'react';
import { ButtonTheme } from './../buttons/index';
import {
  ExcelExport,
  ExcelExportColumn,
  ExcelExportColumnGroup,
} from '@progress/kendo-react-excel-export';
import moment from 'moment';
import { Popover, Checkbox } from 'antd';
import { useState, useEffect } from 'preact/hooks';

/**
 * kendo-react-excel-export --> https://www.telerik.com/kendo-react-ui/components/excelexport/
 */

const ButtonExcelEmbedded = (props) => {
  /**
   * canChooseColumn --> ถ้าส่งมาเป็น true ตรงปุ่ม export จะมีให้เลือกคอลัมน์ที่ต้องการจะ export ก่อนโหลด
   */
  const { header, data, filename, canChooseColumn, title } = props;
  const refExport = React.createRef();
  const [chosenColumn, setChosenColumn] = useState([])
  const [checkAll, setCheckAll] = useState(true)

  useEffect(() => {
    const checkedValues = (header.filter(el => !el.skipExport)).map(v => v.dataIndex)
    setChosenColumn(checkedValues);
  }, [])

  const exportExcel = () => {
    if (refExport.current) {
      refExport.current.save();
    }
  };

  const onCheckAll = () => {
    const valueCheckAll = !checkAll
    setCheckAll(valueCheckAll)
    if (valueCheckAll) {
      const checkedValues = (header.filter(el => !el.skipExport)).map(v => v.dataIndex)
      setChosenColumn(checkedValues);
    } else {
      setChosenColumn([]);
    }
  }

  const onChooseColumn = (e) => {
    let cloneChecked = [...chosenColumn]
    const findIndex = cloneChecked.findIndex(value => value === e.target.value)
    if (findIndex !== -1) {
      cloneChecked.splice(findIndex, 1)
    } else {
      cloneChecked.push(e.target.value)
    }
    setChosenColumn(cloneChecked)
    setCheckAll(false)
  }

  const renderHeaderColumn = () => {
    return header && header.map(head => {
      if (!canChooseColumn || chosenColumn.includes(head.dataIndex)) {
        if (!head.skipExport && !head.subColumn) {
          return <ExcelExportColumn key={`head_${head.title}`} field={head.dataIndex} title={head.title} {...head.property} />
        }
        return <ExcelExportColumnGroup
          key={`head_${head.title}`}
          title={head.title}
          headerCellOptions={{
            textAlign: "center",
          }}
        >
          {
            head.subColumn && head.subColumn.map(sub =>
              <ExcelExportColumn key={`sub_${sub.title}`} field={sub.dataIndex} title={sub.title} {...sub.property} />
            )
          }
        </ExcelExportColumnGroup>
      }
    })
  }

  const chooseColumnContent = () => {
    return !canChooseColumn ?
      <ButtonTheme useFor="DOWNLOAD" title={title || "export excel ปกติ"} onClick={exportExcel} />
      :
      <Popover placement="bottom" title="เลือกข้อมูลที่ต้องการ"
        content={<div className='content-checkbox-group'>
          <Checkbox checked={checkAll} onChange={onCheckAll}>เลือกทั้งหมด</Checkbox><br />
          <Checkbox.Group value={chosenColumn} className='checkbox-group-vertical'>
            {
              header.map(el =>
                !el.skipExport && <><Checkbox value={el.dataIndex} onChange={onChooseColumn}>{el.title}</Checkbox><br /></>
              )
            }
          </Checkbox.Group>
        </div>}
      >
        <ButtonTheme useFor="DOWNLOAD" title={title || "export แบบเลือกคอลัมน์ได้"} onClick={exportExcel} />
      </Popover>
  }

  return (
    <>
      {chooseColumnContent()}
      <ExcelExport
        fileName={
          filename
            ? `${filename}-${moment().format('DD-MM-YYYY')}.xlsx`
            : `downloaded-data-${moment().format('DD-MM-YYYY')}.xlsx`
        }
        ref={refExport}
        data={data}
      >
        {renderHeaderColumn()}
      </ExcelExport>
    </>
  )
}

export default ButtonExcelEmbedded;
