import { useQuery } from '@tanstack/react-query';
import { GET, GET_EDIT_MANAGEMENT } from '../../../../services';

export const useGetManageData = (mapProductId) =>
  useQuery({
    queryKey: ['employeeListQuery', mapProductId],
    queryFn: async () => {
      if (!mapProductId) {
        return null;
      }
      const response = await GET(GET_EDIT_MANAGEMENT(mapProductId));
      return response.result.items;
    },
    enabled: !!mapProductId,
  });
