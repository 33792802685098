import { useMutation } from '@tanstack/react-query';
import { POST, POST_DESTROT_CATEGORY } from '../../../../services';
import { openAlert } from '../../../../components/alert/useAlert';

export const useDeleteCategoryList = ({ onSuccessCallBack }) =>
  useMutation({
    mutationFn: async ({ categoryId, remark }) => {
      const response = await POST(POST_DESTROT_CATEGORY(), {
        categoryId,
        remark,
      });

      if (onSuccessCallBack) onSuccessCallBack(response);

      return response;
    },
    onSuccess: () => {
      openAlert({ model: 'message', type: 'success', message: 'ลบข้อมูล Category สำเร็จ' });
    },
  });
