import { useMutation } from '@tanstack/react-query';
import { POST, POST_DESTROY_MANAGEMENT } from '../../../../services';
import { openAlert } from '../../../../components/alert/useAlert';

export const useDeleteManagement = () =>
  useMutation({
    mutationFn: async ({ mapProductId, remark }) => {
      const response = await POST(POST_DESTROY_MANAGEMENT(), {
        mapProductId,
        remark,
      });

      return response;
    },
    onSuccess: () => {
      openAlert({ model: 'message', type: 'success', message: 'ลบข้อมูล Management สำเร็จ' });
    },
  });
