import styled from 'styled-components';
import { color } from '../../../resources';

export const StyledContainer = styled.div`
  background-color: ${color.white};
  padding: 16px;
`;

export const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;
