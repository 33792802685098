import {
  createColumnText,
  createColumnButtonArr,
  widthOptions,
} from '@/components/table/function/createColumn';
import { ButtonTheme, ButtonToolTip } from '../../../../components';
import { createColumnTag } from '../../../../components/table/function/createColumn';
import { MOBILE_BRAND_STATUS } from '../../../../resources/status';

export const columns = ({ onEdit }) => {
  return [
    createColumnTag(
      'สถานะ',
      { key: 'mobileModelStatus', text: 'mobileModelStatus', icon: true },
      MOBILE_BRAND_STATUS,
    ),
    createColumnText('แบรนด์', 'mobileBrandNameTh', widthOptions(150)),
    createColumnText('รหัสรุ่นมือถือ', 'mobileModelCode', widthOptions(150)),
    createColumnText('ชื่อรุ่นมือถือ', 'mobileModelNameEn', widthOptions(150)),
    createColumnButtonArr('จัดการ', 'rowNo', {
      others: {
        fixed: 'right',
        width: 150,
      },
      buttonArr: [
        {
          useFor: 'EDIT3',
          render: (text, record) => (
            <ButtonToolTip toolTip="แก้ไข" placement="topLeft">
              <ButtonTheme useFor="EDIT3" onClick={() => onEdit(record)} />
            </ButtonToolTip>
          ),
        },
      ],
    }),
  ];
};
