import { useMutation } from '@tanstack/react-query';
import { useRef, useState } from 'preact/hooks';
import { getQueryStringIgnoreEmpty } from '../../../../functions';
import { convertDateInputToPayload } from '../../../../functions/convert';
import { searchDefaultValues } from '../forms';
import { GET } from '../../../../services';

const GET_CLAIM = (obj) => {
  const params = getQueryStringIgnoreEmpty(obj);
  return `/warranty/abm/api/v2/admin/claims/by-date?${params}`;
};

export const useClaimList = ({ setSelectedRowKeys, searchParamState }) => {
  const [data, setData] = useState([]);
  const currentValue = useRef(searchDefaultValues);
  const { startDate, tel, fullname, customerCode } = searchParamState;
  const [startDateFrom, startDateTo] = startDate || ['', ''];

  const { isPending, mutate } = useMutation({
    mutationFn: async (value) => {
      const params = {
        tel: tel || '',
        fullname: fullname || '',
        customerCode: customerCode || '',
        startDate: convertDateInputToPayload(startDateFrom),
        endDate: convertDateInputToPayload(startDateTo),
      };
      const response = await GET(GET_CLAIM(params));
      currentValue.current = value;
      return response.result.items;
    },
    onSuccess: (response) => {
      setSelectedRowKeys((prev) => {
        return prev?.filter((claimId) => response?.some((newRow) => newRow?.claimId === claimId));
      });
      setData(response);
    },
  });

  const onFetch = () => mutate(currentValue.current);

  return { data, setData, loading: isPending, mutate, onFetch };
};
