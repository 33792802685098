export const handleSubmitForm = (values, mutateUpload) => {
  const uploadData = {
    invImage: values.invImage ? values.invImage[0] : null,
    invNo: values.invNo,
    promotionId: values.promotionId,
    telephone: values.telephone,
    qty: values.qty,
    orderFrom: values.orderFrom,
    orderDate: values.orderDate,
    remark: values.remark,
  };

  mutateUpload(uploadData);
};

export const handleSubmitEditForm = (values, mutateUpload) => {
  const uploadData = {
    invImage: values.invImage ? values.invImage[0] : null,
    orderId: values.orderId,
    promotionId: values.promotionId,
    orderStatus: values.orderStatus,
    qty: values.qty,
    orderFrom: values.orderFrom,
    orderDate: values.orderDate,
    remark: values.remark,
  };

  mutateUpload(uploadData);
};
