import React, { useEffect } from 'react';
import { QueryClient, QueryClientProvider, QueryCache, MutationCache } from '@tanstack/react-query';
import { useAntDModalContext } from '../modal/context/useAntdModalContext';
import { navigationRef } from '../../utils/navigation';

export const QueryClientComponentProvider = ({ children }) => {
  const { modal } = useAntDModalContext();

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: Infinity,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      },
    },
    queryCache: new QueryCache({
      onError: (error) => {
        //* Handle Error catch in useQuery
        !error?.disabledThrow &&
          modal.error({
            title: 'เกิดข้อผิดพลาด',
            content: error.message,
          });
      },
    }),
    mutationCache: new MutationCache({
      onError: (error) => {
        //* Handle Error catch in useMutation
        !error?.disabledThrow &&
          modal.error({
            title: 'เกิดข้อผิดพลาด',
            content: error.message,
          });
      },
    }),
  });

  useEffect(() => {
    const unlisten = navigationRef.current.history.listen(() => {
      queryClient.clear();
    });
    return () => {
      unlisten();
    };
  }, [queryClient]);

  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};
