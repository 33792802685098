import React from 'react';
import { columns } from './columns/index';
import { StyledContainer } from './styles';
import { FORM_SEARCH, searchDefaultValue } from './forms';
import { TextLarge, CardTheme } from '../../../components/index';
import { Icon } from '../../../resources';
import ButtonExcelEmbedded from '../../../components/report-excel/ButtonExcelEmbedded';
import { ContainerButton } from '../../../styles/global-style';
import { useGetHistory } from './hooks/useGetHistory';
import { useForm } from 'react-hook-form';
import { ResponsivePageLayout } from '../../../components/page-layout/ResponsivePageLayout';
import { useGetFullHistory } from './hooks/useGetFullHistory';
import { SpaceBetween } from '../../manage-data/user-info/styles';
import { RowSearchLayout } from '../../../components/page-layout/views/RowSearchLayout';
import { useEffect, useState } from 'preact/hooks';

export const ReportUseCoupon = () => {
  const [searchType, setSearchType] = useState(null);
  const formSearchObj = useForm({ defaultValues: searchDefaultValue });
  const {
    dataSource: historyData,
    onSearch,
    loading: isPendingHistory,
  } = useGetHistory({
    handleSubmit: formSearchObj.handleSubmit,
  });
  const {
    dataSource: fullHistoryData,
    onSearchFull,
    loading: isPendingFullHistory,
  } = useGetFullHistory({
    handleSubmit: formSearchObj.handleSubmit,
  });

  useEffect(() => {
    onSearch(searchDefaultValue);
  }, []);

  const onSearchByField = (values) => {
    if (values.search) {
      setSearchType('search');
      onSearchFull(values);
    } else if (values.startDate) {
      setSearchType('date');
      onSearch(values);
    }
  };

  const formSearch = FORM_SEARCH({ onEnter: formSearchObj.handleSubmit(onSearchByField) });
  return (
    <StyledContainer>
      <TextLarge text="รายงานการใช้คูปอง" />
      <CardTheme>
        <RowSearchLayout
          props={{
            title: 'ค้นหารายงานการใช้คูปอง',
            formSearch,
            useFormParam: formSearchObj,
            icon: <Icon.todo />,
            onSearch: formSearchObj.handleSubmit(onSearchByField),
            ignoreDateEmpty: true,
          }}
        ></RowSearchLayout>
        <ResponsivePageLayout
          tableLayout={{
            columns: columns(),
            dataSource: searchType === 'search' ? fullHistoryData : historyData,
            hasPagination: true,
            loading: isPendingHistory || isPendingFullHistory,
            extraContent: (
              <SpaceBetween>
                <ContainerButton right>
                  <ButtonExcelEmbedded
                    title="Export"
                    header={columns()}
                    data={searchType === 'search' ? fullHistoryData : historyData}
                    filename="รายงานรายงานการใช้คูปอง"
                  />
                </ContainerButton>
              </SpaceBetween>
            ),
          }}
        />
      </CardTheme>
    </StyledContainer>
  );
};
