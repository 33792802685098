import { useMemo } from 'preact/hooks';
import { serviceOptions } from '../../../../functions/serviceOption';

export const useOptions = ({ watch }) => {
  const { mobilebrands, mobileBrandId, mobileModelId } = watch();

  const brandOptions = useMemo(() => serviceOptions('MOBILE_BRAND', mobilebrands), [mobilebrands]);

  const modelOptions = useMemo(
    () =>
      serviceOptions(
        'MOBILE_MODEL',
        brandOptions?.find((el) => el?.value === mobileBrandId)?.mobileModels,
      ),
    [mobileBrandId, brandOptions],
  );

  const productOptions = useMemo(
    () =>
      serviceOptions('PRODUCT', modelOptions?.find((el) => el?.value === mobileModelId)?.products),
    [mobileModelId, modelOptions],
  );

  return {
    mobileBrandOptions: brandOptions,
    mobileModelOptions: modelOptions,
    productOptions,
  };
};
