export const FORM_CLAIM_CREATE = ({
  onEnter,
  provinceOptions,
  districtOptions,
  subDistrictOptions,
  addressOptions,
  showOldAddress,
  watch,
}) => {
  const span = 12;

  const SHOW_RADIO = [
    {
      name: 'isCreateNewAddress',
      label: 'ที่อยู่เดิม',
      type: 'RADIO',
      span: 24,
      defaultValue: 'false',
      properties: {
        options: [
          { label: 'ที่อยู่เดิม', value: 'false' },
          { label: 'เพิ่มที่อยู่ใหม่', value: 'true' },
        ],
      },
      rules: {
        required: true,
      },
    },
  ];

  return [
    {
      name: 'telephone',
      label: 'หมายเลขโทรศัพท์',
      type: 'INPUT_PHONE',
      placeholder: 'กรุณากรอกหมายเลขโทรศัพท์',
      onEnter,
      span,
      maxLength: 10,
      rules: {
        required: true,
        pattern: /^[0-9]*$/,
      },
    },
    {
      name: 'fullName',
      label: 'ชื่อ-นามสกุล',
      type: 'INPUT',
      placeholder: 'กรุณากรอกชื่อ-นามสกุล',
      span,
      rules: {
        required: true,
      },
    },
    ...(showOldAddress ? SHOW_RADIO : []),
    ...(watch('isCreateNewAddress') !== 'true'
      ? [
          {
            name: 'customerAddressId',
            label: 'เลือกที่อยู่',
            type: 'DROPDOWN',
            placeholder: 'กรุณาเลือก',
            properties: {
              options: addressOptions || [],
            },
            rules: {
              required: true,
            },
            span: 24,
          },
        ]
      : []),
    {
      name: 'info',
      label: 'หากต้องการเพิ่มที่อยู่ใหม่กรอกรหัสไปรษณีย์แล้วกด Enter',
      type: 'TEXT',
      span: 24,
      style: {
        textDecoration: 'underline',
      },
    },
    {
      name: 'zipCode',
      label: 'รหัสไปรษณีย์',
      type: 'INPUT',
      placeholder: 'กรุณากรอกรหัสไปรษณีย์',
      span,
      onEnter,
      rules: {
        required: true,
      },
    },
    {
      name: 'provinceId',
      label: 'จังหวัด',
      type: 'DROPDOWN',
      placeholder: 'กรุณาเลือกจังหวัด',
      properties: {
        options: provinceOptions || [],
        getOptionLabel: (option) => option.label || option.provinceLabel,
        getOptionValue: (option) => option.value || option.provinceId,
      },
      span,
      rules: {
        required: true,
      },
    },
    {
      name: 'districtId',
      label: 'เขต/อำเภอ',
      type: 'DROPDOWN',
      placeholder: 'กรุณาเลือกเขต/อำเภอ',
      properties: {
        options: districtOptions || [],
        getOptionLabel: (option) => option.label || option.districtLabel,
        getOptionValue: (option) => option.value || option.districtId,
      },
      span,
      rules: {
        required: true,
      },
    },
    {
      name: 'subDistrictId',
      label: 'แขวง/ตำบล',
      type: 'DROPDOWN',
      placeholder: 'กรุณาเลือกแขวง/ตำบล',
      properties: {
        options: subDistrictOptions || [],
        getOptionLabel: (option) => option.label || option.subDistrictLabel,
        getOptionValue: (option) => option.value || option.subDistrictId,
      },
      span,
      rules: {
        required: true,
      },
    },
    {
      name: 'other',
      label: 'เพิ่มเติม',
      type: 'INPUT',
      placeholder: '',
      span: 24,
    },
  ];
};

export const FORM_CLAIM_DATA = ({ productOptions, breakdownOptions }) => {
  const span = 24;
  const accept = [`image/jpeg`, 'image/png', 'image/jpg', 'image/bmp', 'image/tiff'];

  return [
    {
      name: 'warrantyId',
      label: 'เลือกสินค้าที่ต้องการเคลม',
      type: 'DROPDOWN',
      properties: {
        options: productOptions || [],
      },
      span,
      style: {
        height: 'auto',
        maxHeight: '200px',
        width: '100%',
        overflowY: 'auto',
      },
      rules: {
        required: true,
      },
    },
    {
      name: 'breakdownId',
      label: 'อาการเสีย',
      type: 'DROPDOWN',
      properties: {
        options: breakdownOptions || [],
      },
      rules: {
        required: true,
      },
      span,
    },
    {
      name: 'detail1',
      label: 'รายละเอียด',
      type: 'INPUT',
      placeholder: '',
      span,
    },
    {
      name: 'claimImage',
      label: 'รูปภาพสินค้า (.png, .jpg, .jpeg, .bmp, .gif)',
      type: 'UPLOAD',
      listType: 'picture-card',
      accept: accept.join(','),
      span,
      rules: {
        required: true,
      },
      maxCount: 1,
    },
  ];
};
