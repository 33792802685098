import { useMutation } from '@tanstack/react-query';
import { POST, POST_CLAIM_PDF } from '@/services';

export const useGetPDF = () => {
  return useMutation({
    mutationFn: async (selectedRowKeys) => {
      const body = { claimIdList: selectedRowKeys };
      const response = await POST(POST_CLAIM_PDF(), body);
      return response;
    },
  });
};
